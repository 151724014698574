import SystemSettingStore from './../../shared/stores/systemSetting.store'
import LeaderboardsPageStore from '../../pages/Reports/store/LeaderboardsPage.store'
import moment from 'moment/moment'
import { useMemo } from 'react'

export const useBreakTheBarSetting = (agentScope, historical = false) => {
  const startDate = useMemo(() => {
    return (
      LeaderboardsPageStore.fetchParams.start ||
      moment().startOf('isoweek').format('YYYY-MM-DD')
    )
  }, [LeaderboardsPageStore.fetchParams.start])

  const threshold = useMemo(() => {
    const BtbThresholds = SystemSettingStore.get(
      'usertypes---break-the-bar-thresholds'
    )
    let thresholdHistory = []

    if (historical) {
      // console.log(startDate);

      // Get the week number of the startDate
      const startWeek = moment(startDate).isoWeek()
      // console.log('Start Week:', startWeek);

      // Initialize the variable for the last day of the target week
      let lastDayOfTargetWeek

      // Initialize the variable for the last year's same week (if needed)
      let lastYearSameWeekSunday

      // Check if the startDate is part of the current week
      if (moment(startDate).isoWeek() === moment().isoWeek()) {
        // If the startDate is in the current week, proceed as usual
        const currentWeek = moment(startDate).isoWeek()
        lastDayOfTargetWeek = moment(startDate)
          .isoWeek(currentWeek)
          .endOf('isoWeek')
          .format('YYYY-MM-DD')

        // console.log('Last Day of the Current Week (Sunday):', lastDayOfTargetWeek);

        // Get the Sunday of the same week last year
        lastYearSameWeekSunday = moment(lastDayOfTargetWeek)
          .subtract(1, 'year')
          .isoWeek(currentWeek)
          .startOf('isoWeek')
          .format('YYYY-MM-DD')

        // console.log('Last Year Same Week (Sunday):', lastYearSameWeekSunday);
      } else {
        // If the startDate is not in the current week, find the nearest threshold based on the startDate's week of the same year
        lastDayOfTargetWeek = moment(startDate)
          .isoWeek(startWeek)
          .endOf('isoWeek')
          .format('YYYY-MM-DD')

        // console.log('Last Day of the Target Week (Sunday):', lastDayOfTargetWeek);

        // No need for last year logic; we will find the closest threshold in the same year
        lastYearSameWeekSunday = lastDayOfTargetWeek // Set to the same Sunday of the target week
        // console.log('Target Week (Same Year):', lastYearSameWeekSunday);
      }

      // Continue with threshold history sorting and filtering
      if (BtbThresholds && Array.isArray(BtbThresholds[agentScope]?.history)) {
        thresholdHistory = BtbThresholds[agentScope].history
      }

      if (!thresholdHistory || !thresholdHistory.length) return 0

      thresholdHistory = thresholdHistory.sort((a, b) => {
        let diff = moment(a.start, 'YYYY-MM-DD').diff(
          moment(b.start, 'YYYY-MM-DD'),
          'days'
        )
        return diff > 0 ? -1 : diff < 0 ? 1 : 0
      })

      let initHist = thresholdHistory[0]
      thresholdHistory = thresholdHistory.filter((th) => {
        return (
          moment(startDate, 'YYYY-MM-DD').diff(
            moment(th.start, 'YYYY-MM-DD'),
            'days'
          ) >= 0
        )
      })

      // Find the closest date to lastYearSameWeekSunday (or target week in the same year)
      let closestThreshold = thresholdHistory.reduce((closest, current) => {
        const currentDate = moment(current.start, 'YYYY-MM-DD')
        const diffWithCurrent = Math.abs(
          currentDate.diff(moment(lastYearSameWeekSunday), 'days')
        )

        if (
          diffWithCurrent <
          Math.abs(
            moment(closest.start, 'YYYY-MM-DD').diff(
              moment(lastYearSameWeekSunday),
              'days'
            )
          )
        ) {
          return current // Update closest if the current one is closer
        }
        return closest
      }, thresholdHistory[0])

      // console.log('Closest Threshold:', closestThreshold);

      return parseInt(
        closestThreshold?.value !== null && !isNaN(closestThreshold?.value)
          ? closestThreshold.value
          : 0
      )
    }

    if (BtbThresholds && Array.isArray(BtbThresholds[agentScope]?.history))
      thresholdHistory = BtbThresholds[agentScope].history

    if (!thresholdHistory || !thresholdHistory.length) return 0

    thresholdHistory = thresholdHistory.sort((a, b) => {
      let diff = moment(a.start, 'YYYY-MM-DD').diff(
        moment(b.start, 'YYYY-MM-DD'),
        'days'
      )
      return diff > 0 ? -1 : diff < 0 ? 1 : 0
    })

    let initHist = thresholdHistory[0]
    thresholdHistory = thresholdHistory.filter((th) => {
      return moment(startDate, 'YYYY-MM-DD').diff(
        moment(th.start, 'YYYY-MM-DD'),
        'days'
      ) >= 0
        ? true
        : false
    })

    if (thresholdHistory.length === 0) thresholdHistory.push(initHist)

    return parseInt(
      thresholdHistory[0]?.value !== null && !isNaN(thresholdHistory[0]?.value)
        ? thresholdHistory[0].value
        : 0
    )
  }, [
    SystemSettingStore.Settings['usertypes---break-the-bar-thresholds'],
    startDate,
    agentScope,
    historical,
  ])

  return { threshold, startDate }
}

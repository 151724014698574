import React, { Component } from 'react'
import UserProfileService from './../../../shared/services/UserProfile.service'
import LoadingSpinner from './../../shared/LoadingSpinner.component'
import Telephone from '../../shared/Telephone/Telephone.component'
import {
  MDBBtn,
  MDBDataTable,
  MDBIcon,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
} from 'mdbreact'
import CarrierContactService from '../../../shared/services/CarrierContact.service'
import CarrierService from '../../../shared/services/Carrier.service'
import UsersCarrierContractService from './../../../shared/services/UsersCarrierContract.service'
import { toast } from 'react-toastify'

// const CARRIER_CONTACT_LINK = "https://teambanville-my.sharepoint.com/:b:/g/personal/madison_brokersupportteam_com/Eam4rzOkrmFDggqssspriV0Bc9-TLKE5SuNixiZTuJir2g?e=Rht3Y4";
// const CARRIER_CONTACT_LINK = "https://teambanville-my.sharepoint.com/:b:/g/personal/jill_brokersupportteam_com/Eb8TrWMS9fVIjQGxQ6T688YBM1WA4TemqrFpgRYjeFukgA?e=CMq31h";

import './CarrierContactsModal.component.scss'

class CarrierContactsModal extends Component {
  state = {
    loading: false,
    loadingContactInfo: false,
    agentInfoModal: false,
    carrierContactInfoList: [],
    carrierContactInfo: {
      columns: [
        {
          label: 'Carrier',
          field: 'carrier',
        },
        {
          label: 'Contact Name',
          field: 'contact_name',
        },
        {
          label: 'Contact Email',
          field: 'contact_email',
        },
        {
          label: 'Contact Phone',
          field: 'contact_phone',
        },
        {
          label: 'Contact Website',
          field: 'contact_website',
        },
        {
          label: 'Notes',
          field: 'contact_notes',
        },
      ],
      rows: [],
    },
  }
  _toggleContactInfoModal = () => {
    const { loading, agentInfoModal, loadingContactInfo } = this.state
    if (loading || loadingContactInfo) return

    if (!agentInfoModal) this._fetchCarrierContactsInfo()

    this.setState({ agentInfoModal: !this.state.agentInfoModal })
  }

  _fetchCarrierContactsInfo = async () => {
    this.setState({ loadingContactInfo: true })

    const searchUserCarriers = await UsersCarrierContractService.search({
        search: { user_Id: UserProfileService.getUserId() },
        pagination: false,
      }),
      carrierIds = (
        searchUserCarriers && Array.isArray(searchUserCarriers?.models)
          ? searchUserCarriers.models
          : []
      ).map((c) => parseInt(c.carrier_id))

    Promise.all([
      CarrierContactService.search({
        search: { carrier_id: carrierIds },
        pagination: false,
      }),
      CarrierService.search({ search: { id: carrierIds }, pagination: false }),
    ])
      .then((response) => {
        let Contacts = response.shift(),
          Carriers = response.shift()

        Contacts =
          Contacts && Array.isArray(Contacts?.models) ? Contacts.models : []
        Carriers =
          Carriers && Array.isArray(Carriers?.models) ? Carriers.models : []

        // modify & sort list by contact name THEN by carrier name at the end.
        // this will result in an outputted list sorted primarily by carrier name,
        // secondarily by contact name.
        const contactList = Contacts.sort((c1, c2) =>
          `${c1.name}`.localeCompare(`${c2.name}`, 'en')
        )
          .map((Contact) => {
            const Carrier = Carriers.filter(
              (c) => `${c.id}` === `${Contact.carrier_id}`
            ).shift()
            Contact.c_name = (Carrier && Carrier?.c_name) || ''
            Contact.c_website =
              (Carrier && Carrier?.c_website) || ''
                ? `${Carrier.c_website}`.trim().toLowerCase()
                : ''

            return Contact
          })
          .sort((c1, c2) => `${c1.c_name}`.localeCompare(`${c2.c_name}`, 'en'))

        this.setState(
          {
            carrierContactInfoList: contactList,
            loadingContactInfo: false,
          },
          () => {
            if (contactList && contactList.length)
              this._setCarrierContactsRows()
          }
        )
      })
      .catch((error) => {
        this.setState({ loadingContactInfo: false })
        toast.error("Could not fetch Carrier's Contact info", {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  _setCarrierContactsRows = () => {
    const { carrierContactInfoList } = this.state

    let rows = (
      Array.isArray(carrierContactInfoList) ? carrierContactInfoList : []
    ).map((row) => {
      const { c_name, name, email, phone, phone_ext, c_website, notes } = row

      return {
        carrier: c_name ? c_name : 'N/A',
        contact_name: name ? name : 'N/A',
        contact_email: email ? <a href={`mailto:${email}`}>{email}</a> : 'N/A',
        contact_phone: phone ? (
          <Telephone phoneNumber={phone} phoneExt={phone_ext} />
        ) : (
          'N/A'
        ),
        contact_website: c_website ? (
          <a href={c_website} target="_blank" rel="noopener noreferrer">
            {`${c_website}`.toLowerCase()}
          </a>
        ) : (
          'N/A'
        ),
        contact_notes: notes,
      }
    })

    this.setState({
      carrierContactInfo: { ...this.state.carrierContactInfo, rows: rows },
    })
  }

  render() {
    return (
      <div id="CarrierContactsModal">
        <MDBBtn
          className="btn-block mb-1"
          color="indigo"
          onClick={this._toggleContactInfoModal}
        >
          <MDBIcon icon="user-circle" className="mr-1" /> Carrier Contact Info
        </MDBBtn>

        <MDBModal
          size="lg"
          isOpen={this.state.agentInfoModal}
          toggle={this._toggleContactInfoModal}
        >
          <MDBModalHeader toggle={this._toggleContactInfoModal}>
            Carrier Contact Info
          </MDBModalHeader>
          <MDBModalBody
            className={this.state.loadingContactInfo ? 'is-loading' : ''}
          >
            {this.state.loadingContactInfo ? (
              <div className="loading-wrapper">
                <LoadingSpinner size="md" isActive={true} />
              </div>
            ) : (
              <>
                <div>
                  {
                    <MDBDataTable
                      paging={false}
                      striped
                      responsive
                      bordered
                      data={this.state.carrierContactInfo}
                      entries={50}
                      entriesOptions={[50, 100, 150]}
                    />
                  }
                </div>
                <MDBBtn
                  color="warning"
                  size={'sm'}
                  block
                  onClick={this._toggleContactInfoModal}
                >
                  Close
                </MDBBtn>
              </>
            )}
          </MDBModalBody>
        </MDBModal>
      </div>
    )
  }
}

export default CarrierContactsModal

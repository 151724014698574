import { makeAutoObservable, toJS } from 'mobx'
import UserFactory from './../../shared/factories/user.factory'
import { BehaviorSubject } from 'rxjs'
import moment from 'moment'
import {
  getIds,
  fetchCart,
  fetchCartItems,
  fetchContract,
  fetchDependencies,
  replaceOrPush,
  isAdobeContract,
  updateContract,
  track,
} from './functions'

const checkShouldCloseCart = (Cart, CartItems) => {
  if (Cart && CartItems) {
    if (Cart?.get('cart_status') === 'CLOSED') return false
    return (
      CartItems.length > 0 &&
      CartItems.filter(
        (CartItem) =>
          ['COMPLETED', 'ABANDONED'].indexOf(CartItem.get('item_status')) > -1
      ).length === CartItems.length
    )
  }
  return false
}

class ApiListener {
  _subjects = {}
  _subscriptions$ = {}

  _createKey = (subject) =>
    [
      `${subject}`.trim().toLowerCase(),
      Math.floor(Math.random() * 999999999),
    ].join('-')

  wait = async (subject) => {
    if (!this.hasSubject(subject)) this.createSubject(subject)

    if (!this._subscriptions$.hasOwnProperty(subject))
      this._subscriptions$[subject] = []

    return new Promise((resolve, reject) => {
      const _subject = subject,
        _subId = this._createKey(_subject)

      this._subscriptions$[_subId] = this._subjects[_subject].subscribe(
        (payload) => {
          if (payload) {
            this._subscriptions$[_subId].unsubscribe()
            delete this._subscriptions$[_subId]
            resolve(payload)
          }
        }
      )
    })
  }

  hasSubject = (subject) =>
    this._subjects.hasOwnProperty(subject) && !!this._subjects[subject]

  createSubject = (subject, value) =>
    !this.hasSubject(subject)
      ? (this._subjects[subject] = new BehaviorSubject(value || null))
      : false

  getSubject = (subject) =>
    this._subjects.hasOwnProperty(subject) ? this._subjects[subject] : undefined

  getValue = (subject) => this.getSubject(subject)?.getValue()

  resetSubject = (subject) =>
    this.getValue(subject) !== null ? this.updateSubject(subject, null) : 0

  updateSubject = (subject, payload) => {
    if (this.hasSubject(subject)) {
      this.getSubject(subject).next(payload)
      return 1
    }

    return 0
  }
}

const Listener = new ApiListener()

class AgentContractManagerStore {
  isLoadingDepend = false
  isLoadingUser = false
  isLoadingCart = false
  isEditContractAlertPage = false

  Specs = null
  ActiveView = null
  Certs = null

  setIds = (arr, ids, id) =>
    (ids = getIds(arr, id)).filter(
      (val, idx, self) => self.indexOf(val) === idx
    )

  /*
   * Agent Contract Manager Store - Accessors
   * ------------------------------------------------------------------------------------
   */

  /*
   *
   * User Instance
   *
   */
  userId = null
  _User = null
  get User() {
    return this._User
  }
  set User(value) {
    this._User = value || null
    Listener.updateSubject('User', value || null)
    this.userId = !isNaN(value?.get('id')) ? parseInt(value.get('id')) : null
  }

  /*
   *
   * UserCarrierContracts Instances
   *
   */
  contract_ids = []
  _Contracts = []
  _Contract = null
  get Contract() {
    return this._Contract
  }
  set Contract(value) {
    this._Contract = value
  }
  get Contracts() {
    return this._Contracts
  }
  set Contracts(value) {
    this.contracts_ids = getIds(
      (this._Contracts = (Array.isArray(value) ? value : []).filter(
        (i) => i && typeof i === 'object' && !isNaN(i?.get('id'))
      )),
      'id'
    )
  }

  /*
   *
   * User Contracting Cart
   *
   */
  cartId = null
  _Cart = null
  get Cart() {
    return this._Cart
  }
  set Cart(value) {
    this._Cart = value || null
    Listener.updateSubject('Cart', value || null)
    this.cartId = !isNaN(value?.get('id')) ? parseInt(value.get('id')) : null
  }

  /*
   *
   * User Contracting Cart Items
   *
   */
  cart_item_ids = []
  _CartItems = []
  get CartItems() {
    return this._CartItems
  }
  set CartItems(value) {
    this.setIds(
      (this._CartItems = (Array.isArray(value) ? value : []).filter(
        (i) => i && typeof i === 'object' && (!isNaN(i?.id()) || i?.isNew())
      )),
      this.cart_item_ids,
      'id'
    )

    this.setIds(
      (this._CartItemGroups = (this.CartItems || [])
        .map((CartItem) => CartItem.get('item_group'))
        .filter((val, idx, self) => self.indexOf(val) === idx)
        .forEach((itemGroup) =>
          this.CartItems.filter(
            (CartItem) => CartItem.get('item_group') === itemGroup
          )
        )),
      this.cart_item_groups,
      'item_group'
    )
  }

  /*
   *
   * User Contracting Cart Elective Items
   *
   */
  elective_cart_item_ids = []
  _ElectiveCartItems = []
  get ElectiveCartItems() {
    return this._ElectiveCartItems
  }
  set ElectiveCartItems(value) {
    this.setIds(
      (this._ElectiveCartItems = (Array.isArray(value) ? value : []).filter(
        (i) => i && typeof i === 'object' && (!isNaN(i?.id()) || i?.isNew())
      )),
      this.elective_cart_item_ids,
      'id'
    )
  }

  /*
   *
   * User Contracting Cart Metas
   *
   */
  cart_meta_ids = []
  _CartMetas = []
  get CartMetas() {
    return this._CartMetas
  }
  set CartMetas(value) {
    this.setIds(
      (this._CartMetas = (Array.isArray(value) ? value : []).filter(
        (i) => i && typeof i === 'object' && !isNaN(i?.get('id'))
      )),
      this.cart_meta_ids,
      'id'
    )
  }

  /*
   *
   * [Previewing] Cart Item Groups
   * Effectively a cart preview before checkout.
   */
  cart_item_groups = []
  _CartItemGroups = []
  get CartItemGroups() {
    return this._CartItemGroups
  }

  /*
   *
   * UserExternalCarrier Instances
   *
   */
  external_carrier_ids = []
  _ExternalCarriers = []
  get externalCarrierIds() {
    return toJS(this.external_carrier_ids) || []
  }
  get ExternalCarriers() {
    return this._ExternalCarriers
  }
  set ExternalCarriers(value) {
    // ensure passed value is an array.  wrap in array if obj passed.
    value = Array.isArray(value) ? value : [value]

    // ensure instance types.
    if (
      !!value.length &&
      value.length !==
        value.filter((UEC) => {
          try {
            console.log('[UEC].name: ', { name: UEC?.constructor?.name })
          } catch (ex) {}
          return UEC && typeof UEC === 'object'
        }).length
    ) {
      console.error(
        'Invalid instance or value passed to ExternalCarriers setter.',
        {
          value,
          tested: value.map((UEC) => UEC?.constructor?.name),
        }
      )
      throw new Error(
        'Invalid instance or value passed to ExternalCarriers setter.'
      )
    }

    // set external carriers & respective IDs.
    this.external_carrier_ids = getIds(
      (this._ExternalCarriers = value),
      'carrier_id'
    ).filter((val, idx, self) => self.indexOf(val) === idx)
  }

  /*
   *
   * Checking Contract Packets
   *
   */
  // Indicates the letter of the currently view contract packet.
  // or NULL if no packet is being viewed.
  packetId = null
  _Packet = null
  get Packet() {
    return this._Packet
  }
  set Packet(value) {
    this._Packet = value
  }

  packetContractTypes = {}
  packets = []
  _PacketItems = []
  get PacketItems() {
    return this._PacketItems
  }
  set PacketItems(value) {
    this.packets = getIds(
      (this._PacketItems = (Array.isArray(value) ? value : []).filter(
        (i) => i && i?.get('item_group')
      )),
      'item_group'
    ).filter((val, idx, self) => self.indexOf(val) === idx)
  }

  /*
   *
   * Carriers List
   *
   */
  carrier_ids = []
  _Carriers = []
  CarriersById = {}
  get Carriers() {
    return this._Carriers
  }
  set Carriers(value) {
    this.setIds(
      (this._Carriers = (Array.isArray(value) ? value : []).filter(
        (i) => i && typeof i === 'object' && !isNaN(i?.get('id'))
      )),
      this.carrier_ids,
      'id'
    )
    let CarriersById = {}
    this._Carriers.forEach(
      (Carrier) => (CarriersById[parseInt(Carrier.id())] = Carrier)
    )
    this.CarriersById = CarriersById
  }

  /* ------------------------------------------------------------------ */

  onCartLoaded = async (Cart, CartItems) => {
    const isActive = () => Cart && Cart?.get('cart_status') === 'ACTIVE'

    // If the CartItems argument is an array, test
    // it for the existance of CartItem instances.
    // If it's an empty array, the cart should be
    // regarded as OPEN (not ACTIVE).  This is
    // helps to catch cases where contracts/carriers
    // were removed during active contracting &
    // the cart did not close or revert to OPEN.
    if (isActive() && Array.isArray(CartItems) && CartItems.length === 0)
      Cart.set('cart_status', 'OPEN')

    // If the Cart is Active, fetch the Contract Packets.
    if (isActive()) {
      let { PacketItems, packetContractTypes } = await this.checkout({ Cart })
      this.PacketItems = PacketItems
      this.packetContractTypes = packetContractTypes
      return { PacketItems, packetContractTypes }
    }

    // The Cart is not Active.
    // Ensure the PacketItems & packetContractTypes contain safe values.
    if (!Array.isArray(this.PacketItems) || this.PacketItems.length !== 0)
      this.PacketItems = []

    if (
      typeof this.packetContractTypes !== 'object' ||
      Object.values(this.packetContractTypes).length > 0
    )
      this.packetContractTypes = {}

    return {
      PacketItems: this.PacketItems,
      packetContractTypes: this.packetContractTypes,
    }
  }

  loadUser = async (userId) => {
    // 1. was an id supplied?
    if (!userId) return this.User

    // 2. has the id changed?
    let hasChanged =
      !!userId !== !!this.userId || parseInt(userId) !== parseInt(this.userId)
    if (!hasChanged) {
      // 2a. user has not changed.
      // return local instance, if exists.
      if (this.User) return this.User

      // 2b. await user promise, if possible.
      if (this.isLoadingUser) return await Listener.wait('User')
    }

    // 3. user id has changed.
    // start new fetch.
    this.isLoadingUser = true

    // 3a. Instantiate BehaviorSubject instance,
    // if it doesnt already exist.

    // 3. Define a new Listener if one
    // doesnt exist.  Otherwise, clear the listener.
    if (!Listener.hasSubject('User')) Listener.createSubject('User', null)
    Listener.resetSubject('User')

    // 4. Search for user by ID.
    this.User = await UserFactory.findById(userId)

    // 5. Disable boolean loading flag.
    this.isLoadingUser = false
    return this.User
  }

  loadAgentCart = async (opts) => {
    opts = opts && typeof opts === 'object' ? opts : {}

    // 1. await cart promise, if exists.
    if (this.isLoadingCart) return await Listener.wait('Cart')

    // 2. Activate loading flag.
    this.isLoadingCart = true

    // 3. Define a new Listener if one
    // doesnt exist.  Otherwise, clear the listener.
    if (!Listener.hasSubject('Cart')) Listener.createSubject('Cart', null)
    Listener.resetSubject('Cart')

    // 4. Fetch an open or active cart.
    let Cart = await fetchCart(this.User, !!opts?.isOnboarding),
      CartMetas = [],
      CartItems = []

    // 5. Load Cart Items is a cart was found.
    let cartChildren = await Promise.all([
      Cart.meta(true).key([
        'cart---enable-ext-carriers',
        'cart-items---ext-carriers',
      ]),
      Cart.item(true).all(),
    ])
    CartMetas = Array.isArray((CartMetas = cartChildren.shift() || []))
      ? CartMetas
      : []
    CartItems = Array.isArray((CartItems = cartChildren.shift() || []))
      ? CartItems
      : []

    // 6. If the shopping cart checkout process
    // has previously been started, make an additional
    // API call to get the contracting packets & their progress.
    // CartItems
    await this.onCartLoaded(Cart, CartItems)

    // Set the Cart sub values.
    this.CartMetas = CartMetas
    this.CartItems = CartItems
    this.Cart = Cart
    this.isLoadingCart = false
    return this.Cart
  }

  loadDepend = async () => {
    // 1. if we're already loading contracting
    // dependencies, listen to API events so
    // we can resolve a promise when that request
    // completes.
    if (this.isLoadingDepend) return await Listener.wait('Depend')

    // 2. Activate loading flag.
    this.isLoadingDepend = true

    // 3. Define a new Listener if one
    // doesnt exist.  Otherwise, clear the listener.
    if (!Listener.hasSubject('Depend')) Listener.createSubject('Depend', null)
    Listener.resetSubject('Depend')

    // 4. Fetch contracting dependencies.
    let Depends = await fetchDependencies(this.User)

    // 5. Handle response dependencies data payload.
    Depends = Depends && typeof Depends === 'object' ? Depends : null

    // 5a. Set all dependency sets.
    if (Depends)
      Object.keys(Depends).forEach(
        (d) =>
          (this[d] =
            Depends[d] || (['Specs', 'ActiveView'].indexOf(d) > -1 ? null : []))
      )

    // 5b. Emit the an updated depencies event
    // & pass the modified dependency object as payload.
    // This will be past to any subscribers.
    if (Depends) Listener.updateSubject('Depend', Depends)

    this.isLoadingDepend = false
    return Depends
  }

  add = async (field, value) => {
    value = Array.isArray(value) ? value : [value]

    let addIds
    switch (`${field}`.trim().toLowerCase()) {
      case 'externalcarriers':
        addIds = value.map((v) =>
          parseInt(v && typeof v === 'object' ? v?.get('carrier_id') : v)
        )
        this.ExternalCarriers = this.ExternalCarriers.filter(
          (UEC) => !~addIds.indexOf(parseInt(UEC.get('carrier_id')))
        ).concat(value)
        break

      case 'cartitem':
        // get rid of anything that has the same id or carrier id as incoming.
        addIds = {
          ids: value
            .map((v) => parseInt(v && typeof v === 'object' ? v?.id() : v))
            .filter((n) => !isNaN(n))
            .filter((val, idx, self) => self.indexOf(val) === idx),
          carrier_ids: value
            .map((v) =>
              parseInt(v && typeof v === 'object' ? v?.get('carrier_id') : v)
            )
            .filter((n) => !isNaN(n))
            .filter((val, idx, self) => self.indexOf(val) === idx),
        }

        this.CartItems = this.CartItems.filter(async (CI) => {
          if (!~addIds.carrier_ids.indexOf(parseInt(CI.get('carrier_id'))))
            return true

          if (CI.isNew()) {
            return true
          }

          if (!~addIds.ids.indexOf(parseInt(CI.id()))) return true
          return false
        }).concat(value)
        break

      default:
        break
    }
  }

  remove = async (field, value) => {
    value = Array.isArray(value) ? value : [value]

    let removeIds
    switch (`${field}`.trim().toLowerCase()) {
      case 'externalcarriers':
        removeIds = value.map((v) =>
          parseInt(v && typeof v === 'object' ? v?.get('carrier_id') : v)
        )
        this.ExternalCarriers = this.ExternalCarriers.filter(
          (UEC) => !~removeIds.indexOf(parseInt(UEC.get('carrier_id')))
        )
        break

      case 'cartitem':
        removeIds = {
          ids: value
            .map((v) => parseInt(v && typeof v === 'object' ? v?.id() : v))
            .filter((n) => !isNaN(n))
            .filter((val, idx, self) => self.indexOf(val) === idx),
          carrier_ids: value
            .map((v) =>
              parseInt(v && typeof v === 'object' ? v?.get('carrier_id') : v)
            )
            .filter((n) => !isNaN(n))
            .filter((val, idx, self) => self.indexOf(val) === idx),
        }

        this.CartItems = this.CartItems.filter((CI) => {
          if (~removeIds.carrier_ids.indexOf(parseInt(CI.get('carrier_id'))))
            return false
          if (CI.isNew()) return true
          if (~removeIds.ids.indexOf(parseInt(CI.id()))) return false
          return true
        })
        break

      default:
        break
    }
  }

  set = async (field, value) => {
    switch (`${field}`.trim().toLowerCase()) {
      case 'cartmetas':
        replaceOrPush(this.CartMetas, value, 'id')
        this.cart_meta_ids = getIds(this.CartMetas, 'id')
        break

      case 'contracts':
        replaceOrPush(this.Contracts, value, 'id')
        this.contract_ids = getIds(this.Contracts, 'id')
        break

      default:
        break
    }
  }

  setUserId = async (userId, opts) => {
    await this.loadUser(userId)
    return await this.loadAgentCart(opts)
  }

  reconcileCart = async () => {
    /* 
		The Agent Contract Manager is designed to work with
		legacy records.  Through out multiple upgrades & changes
		to different contract-related schemas, there is a considerable
		opportunity for incomplete records to come through
		this ACM Store and/or ACM Component. 

		Reconciling the contracting cart will start by
		iterating through the users contracts (related to 
		the cart's items), using their disposition to 
		determine if the cart item's status is valid. 
		Updating values where necessary.  Finally, we'll
		make sure the cart status is valid with respect 
		to the cart item's statuses.
		*/

    const getCartItemAndContract = (CartItem) => ({
      CartItem,
      Contract: this.Contracts.filter(
        (Contract) =>
          parseInt(Contract.id()) === parseInt(CartItem.get('user_contract_id'))
      ).shift(),
    })

    const reconcileCartItemAndContract = ({ CartItem, Contract }) => {
      if (CartItem && Contract) {
        switch (Contract.get('disposition')) {
          // CartItem must be in 'DRAFT' mode.
          case 'unsent':
            if (CartItem.get('item_status') !== 'DRAFT')
              CartItem.set('item_status', 'DRAFT')
            break

          // CartItem must be in 'EXECUTED' mode.
          case 'pending':
            if (CartItem.get('item_status') !== 'EXECUTED')
              CartItem.set('item_status', 'EXECUTED')
            break

          // CartItem must be in 'COMPLETED' mode.
          case 'reviewing':
          case 'awaiting':
          case 'completed':
            if (CartItem.get('item_status') !== 'COMPLETED')
              CartItem.set('item_status', 'COMPLETED')
            break

          // CartItem must be in 'ABANDONED' mode.
          case 'expired':
            if (CartItem.get('item_status') !== 'ABANDONED')
              CartItem.set('item_status', 'ABANDONED')
            break

          default:
            break
        }
      }

      return Promise.resolve({ CartItem, Contract })
    }

    if (
      this.Cart?.isNew() === false &&
      parseInt(this.Cart.get('cart_onboarding')) !== 1
    ) {
      const cartStatus = this.Cart?.get('cart_status'),
        // checkShouldCloseCart = () =>
        //   this.Cart?.get('cart_status') === 'CLOSED'
        //     ? false
        //     : this.CartItems.length > 0 &&
        //       this.CartItems.filter(
        //         (CartItem) =>
        //           ['COMPLETED', 'ABANDONED'].indexOf(
        //             CartItem.get('item_status')
        //           ) > -1
        //       ).length === this.CartItems.length,
        checkShouldActivateCart = () =>
          this.Cart?.get('cart_status') !== 'ACTIVE' &&
          this.CartItems.filter(
            (CartItem) => CartItem.get('item_status') === 'EXECUTED'
          ).length > 0

      if (cartStatus === 'OPEN' || cartStatus === 'ACTIVE') {
        const Reconciled = await Promise.all(
          this.CartItems.map(getCartItemAndContract).map(
            reconcileCartItemAndContract
          )
        )
        // this.add('cartitem', Reconciled.map(R => R.CartItem));
        this.CartItems = Reconciled.map((R) => R.CartItem)

        if (checkShouldCloseCart(this.Cart, this.CartItems)) {
          console.log('Closing cart & creating new instance.')
          this.Cart.set('cart_status', 'CLOSED')
          await Promise.all(
            [this.Cart.save()].concat(
              this.CartItems.map((CartItem) => CartItem.save())
            )
          )
          const Cart = await fetchCart(this.User, false)
          await this.onCartLoaded(Cart)
          this.CartMetas = []
          this.CartItems = []
          this.Cart = Cart
        } else if (checkShouldActivateCart()) {
          if (this.Cart.get('cart_status') !== 'ACTIVE') {
            this.Cart.set('cart_status', 'ACTIVE')
            await this.onCartLoaded(this.Cart)
          }
        } else if (this.Cart.get('cart_status') !== 'OPEN') {
          this.Cart.set('cart_status', 'OPEN')
        }
      }
    }

    return true
  }

  checkout = async ({ Cart, Carriers }) => {
    const setToThis = !Cart

    if ((Cart = Cart ? Cart : this.Cart)) {
      Carriers = Carriers && Array.isArray(Carriers) ? Carriers : []

      let { PacketItems, packetContractTypes, error } = await Cart.checkout(
        Carriers
      )

      if (error) {
        throw error
      }

      const carrierIds = this.CartItems.filter((CartItem) =>
        CartItem.isNew()
      ).map((CartItem) => CartItem.get('carrier_id'))
      if (carrierIds.length > 0) {
        const CartItems = (await fetchCartItems(Cart)) || []

        if (CartItems && Array.isArray(CartItems) && CartItems.length) {
          this.CartItems = this.CartItems.map((CartItem) => {
            if (CartItem.isNew()) {
              const CI = CartItems.filter(
                (CI) =>
                  `${CI.get('carrier_id')}` === `${CartItem.get('carrier_id')}`
              ).shift()
              if (CI) return CI
            }
            return CartItem
          })
        }
      }

      track({
        event_type: 'contracting-cart.preview.success',
        payload: { carrier_ids: carrierIds },
      })

      if (setToThis) {
        this.PacketItems = PacketItems
        this.packetContractTypes = packetContractTypes
        return this
      } else {
        return { PacketItems, packetContractTypes }
      }
    }

    track({
      event_type: 'contracting-cart.preview.failure',
      event_descrip: 'Failed to load cart and/or cart items for preview.',
    })
    return false
  }

  viewPacket = async (packetId) => {
    this.Packet = packetId ? await this.Cart.getPacket(packetId) : null
    this.packetId = packetId ? packetId : null

    track({
      event_type: 'contracting-cart.view-packet',
      payload: { packet_id: packetId ? packetId : null },
    })
  }

  openPacket = async (link) => {
    const packetId = this.Packet && toJS(this.Packet)?.id,
      open = packetId ? await this.Cart.openPacket(packetId, link) : null

    if (open) {
      try {
        if (open?.link) {
          link = `${link}`.split('/')
          link.shift()
          window.open(
            open.link,
            link
              .join('')
              .replace(/[\W+]/g, '')
              .split('')
              .reverse()
              .join('')
              .substr(0, 128)
          )
          track({
            event_type: 'contracting-cart.open-packet.success',
            payload: { packet_id: packetId ? packetId : null, open },
          })
        } else {
          track({
            event_type: 'contracting-cart.open-packet.failure',
            event_descrip: 'Invalid open.link value.',
            payload: { packet_id: packetId ? packetId : null, open },
          })
        }
      } catch (ex) {
        console.error(`${ex}`)
      }
    }
  }

  deletePacket = async (packetId) => {
    const DelCartItems = [],
      DelContracts = []

    this.CartItems.forEach((CartItem) => {
      const isNew = typeof CartItem?.isNew === 'function' && !!CartItem.isNew(),
        contractId =
          !isNew && !isNaN(CartItem?.get('user_contract_id'))
            ? parseInt(CartItem?.get('user_contract_id'))
            : null,
        Contract =
          contractId > 0
            ? this.Contracts.filter(
                (Contract) => parseInt(Contract.id()) === contractId
              ).shift()
            : null

      if (CartItem.get('item_group') === packetId) {
        this.remove('CartItem', CartItem)
        DelCartItems.push(CartItem.delete())
        if (Contract) DelContracts.push(Contract)
      }
    })

    if (DelCartItems.length) await Promise.all(DelCartItems)

    if (DelContracts.length)
      await Promise.all(DelContracts.map((Contract) => Contract.delete()))

    this.packets = this.packets.filter((packet) => packet !== packetId)
    this.PacketItems = this.PacketItems.filter(
      (CartItem) => CartItem.get('item_group') !== packetId
    )
    return true
  }

  removeCheckout = async () => {
    await this.Cart.remove()
    return true
  }

  completePacket = async () => {
    const cartItemIds = this.PacketItems.filter(
        (CartItem) => CartItem.get('item_group') === this.packetId
      ).map((CartItem) => CartItem.id()),
      getContract = async (CartItem) => {
        const Contract = this.Contracts.filter(
          (Contract) =>
            parseInt(Contract.id()) ===
            parseInt(CartItem.get('user_contract_id'))
        ).shift()
        return Contract
          ? Contract
          : CartItem.get('user_contract_id')
          ? fetchContract(CartItem)
          : null
      },
      checkIsAdobe = (UCC) => UCC && isAdobeContract(UCC)

    const completeCartItem = async (CartItem) => {
      if (!CartItem || cartItemIds.indexOf(parseInt(CartItem.id())) < 0)
        return CartItem

      // If this is an adobe contract, you'll need to ensure the
      // contract dispo is not: unsent, pending or expired.
      if (!CartItem.isNew() && !CartItem.get('user_contract_id'))
        await CartItem.reload()

      const UCC = await getContract(CartItem),
        isAdobe = checkIsAdobe(UCC)

      if (isAdobe) {
        // Now check the contract disposition, skipping (unsent, pending or expired)
        if (
          !UCC?.get('disposition') ||
          ['unsent', 'pending', 'expired'].includes(UCC.get('disposition'))
        )
          // MUST WAIT FOR ADOBE TO SIGNIFY THE COMPLETION OF THE CONTRACT.
          // We'll return the CartItem here as there is nothing else to do, yet.
          return CartItem
      } else if (UCC) {
        // if the contract is NOT an adobe contract,
        // we can go ahead & set the pwk_signed_at date.
        if (!UCC.get('pwk_signed_at')) {
          await updateContract(UCC, {
            pwk_signed_at: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
            pwk_sent_to_carrier_at: moment()
              .utc()
              .format('YYYY-MM-DD HH:mm:ss'),
          })
        } else if (!UCC.get('pwk_sent_to_carrier_at'))
          await updateContract(UCC, {
            pwk_sent_to_carrier_at: moment()
              .utc()
              .format('YYYY-MM-DD HH:mm:ss'),
          })
      }

      // The `completePacket()` method (self) is most commonly
      // going to be invoked just after a user has updated a
      // contract.  Contracts can update their status remotely
      // (via webhook to our API).  That updates the user's carrier
      // contract record on the server.  We reload here to ensure
      // we've grabbed those latest changes.
      if (UCC) await UCC.reload()
      await CartItem.reload()

      // Save the change of status to completed.
      CartItem.set('item_status', 'COMPLETED')
      await CartItem.save()

      return CartItem
    }

    track({
      event_type: 'contracting-cart.complete-packet.requested',
      payload: { cart_item_ids: cartItemIds },
    })

    this.CartItems = await Promise.all(
      this.CartItems.map(async (CartItem) => await completeCartItem(CartItem))
    )

    if (checkShouldCloseCart(this.Cart, this.CartItems))
      await this.Cart.reload()
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export default new AgentContractManagerStore()

import React, { useEffect, useState, useRef } from 'react'
import { MDBIcon, MDBSpinner, MDBSwitch } from 'mdbreact'
import UserNoteService from '../../../shared/services/UserNote.service'
import UserProfileService from '../../../shared/services/UserProfile.service'
import { toast } from 'react-toastify'
import moment from 'moment'

import './AgentNotes.component.scss'

const AgentNotes = () => {
  const isAssumed = UserProfileService.isAssumed(),
    isInternal = UserProfileService.isA(
      ['agency-owner', 'system-admin', 'internal-admin', 'internal-staff'],
      true
    ),
    isUpline =
      `${UserProfileService.get('u_upline_id')}` ===
      `${UserProfileService.getUserId(true)}`,
    canAccessPrivate = () => {
      return isUpline || isInternal
    }

  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState('')
  const [isPrivate, setIsPrivate] = useState(false)
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState('all')
  const messageFieldRef = useRef(null)
  const prevMessagesLengthRef = useRef(0)

  useEffect(() => {
    getMessages()

    const intervalId = setInterval(() => {
      getMessages()
    }, 60000)

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    // Scroll to bottom only if new messages are added
    if (
      messages.length > prevMessagesLengthRef.current &&
      messageFieldRef.current
    ) {
      messageFieldRef.current.scrollTop = 0
    }
    // Update the previous messages length to current messages length
    prevMessagesLengthRef.current = messages.length
  }, [messages])

  const sendMessage = async () => {
    if (message.trim()) {
      const sending_message = message
      const data = {
        user_id: UserProfileService.getUserId(),
        author_id: UserProfileService.getUserId(true),
        message: sending_message.trim(),
        is_private: isPrivate ? 1 : 0,
      }

      setMessage('')
      try {
        setLoading(true)
        await UserNoteService.store(data)
        getMessages()
      } catch (error) {
        setMessage(sending_message)
        setLoading(false)
        toast.error('Failed to save a note!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    }
  }

  const deleteMessage = async (id) => {
    const currentMessages = messages
    const remainingMessages = currentMessages.filter(
      (message) => message.id !== id
    )
    setMessages(remainingMessages)
    setLoading(true)

    try {
      setLoading(true)
      await UserNoteService.update(id, {
        deleted_at: new Date().toISOString().slice(0, -5),
      })
      getMessages()
    } catch (error) {
      setMessages(currentMessages)
      setLoading(false)
      toast.error('Failed to delete a note!', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  const filterMessages = (msgs) => {
    if (canAccessPrivate()) {
      setMessages(msgs)
    } else if (!isAssumed) {
      setMessages(msgs.filter((msg) => !msg.is_private))
    } else {
      setMessages([])
    }
  }

  const handleFilterToggle = () => {
    switch (filter) {
      case 'all':
        setFilter('public')
        break
      case 'public':
        setFilter('private')
        break
      case 'private':
        setFilter('all')
        break
      default:
        setFilter('public')
        break
    }
  }

  const getMessages = async () => {
    try {
      const old_messages = await UserNoteService.search({
        pagination: false,
        search: { user_id: UserProfileService.getUserId() },
        order_by: { created_at: 'DESC' },
        expand: ['author'],
      })

      filterMessages(old_messages.models)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error('Failed to fetch notes!', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      if (message.trim()) sendMessage()
    }
  }

  return (
    <div id="AgentNotesComponent" className="m-3">
      <div id="message-field" ref={messageFieldRef}>
        {loading && (
          <div className="messages-spinner">
            <MDBSpinner />
          </div>
        )}
        {messages.length > 0 &&
          messages
            .filter((item) => {
              if (filter === 'private') return item.is_private
              if (filter === 'public') return !item.is_private
              return true // 'all' shows everything
            })
            .map((message, index) => {
              return (
                <div
                  key={index}
                  className={`message-container ${
                    message?.is_private && 'locked'
                  }`}
                >
                  <div className="message">{message?.message}</div>
                  <div className="message-author">
                    {message?.expands?.author?.u_fname +
                      ' ' +
                      message?.expands?.author?.u_lname}
                  </div>
                  <div className="message-date">
                    {moment(message?.created_at).format('MMM D, h:mm A')}
                  </div>
                  {UserProfileService.isA(
                    ['system-admin', 'internal-admin', 'internal-staff'],
                    true
                  ) && (
                    <div className="message-delete">
                      <MDBIcon
                        fas
                        icon="times"
                        size="xl"
                        onClick={async (evt) => deleteMessage(message?.id)}
                      ></MDBIcon>
                    </div>
                  )}
                  {message.is_private ? (
                    <div className="message-locked">
                      Private{' '}
                      <MDBIcon
                        fas
                        icon="lock"
                        size="xl"
                        onClick={(evt) => {}}
                      ></MDBIcon>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )
            })}
      </div>
      <div id="message-editor">
        <textarea
          placeholder="Enter Note"
          cols={3}
          value={message}
          onChange={(evt) => setMessage(evt.target.value)}
          onKeyDown={handleKeyDown}
        ></textarea>
        {canAccessPrivate() ? (
          <>
            <div
              className="pl-2 pr-1 text-center filter-toggle"
              onClick={(evt) => handleFilterToggle()}
            >
              <MDBIcon fas icon="eye" size="xl" />
              <p>Show {filter}</p>
            </div>
            <div className="pl-2 pr-1 text-center private-toggle">
              {isPrivate ? (
                <div onClick={(evt) => setIsPrivate(false)}>
                  <MDBIcon fas icon="lock" size="xl" />
                  Private
                </div>
              ) : (
                <div onClick={(evt) => setIsPrivate(true)}>
                  <MDBIcon fas icon="unlock" size="xl" />
                  Public
                </div>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="pl-2 pr-1">
          <MDBIcon
            far
            icon="arrow-alt-circle-right"
            size="xl"
            onClick={(evt) => sendMessage()}
          />
        </div>
      </div>
    </div>
  )
}

export default AgentNotes

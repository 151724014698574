import RestApi from './../../api/rest-api-client'
const srvcEndpoint = '/widget_reports'
export default {
  // search: async (req, opts) => RestApi.request('GET', srvcEndpoint, req, opts),
  search: async (req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, { endpoint: `/leader` }),
  searchLeader: async (req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, { endpoint: `/leader` }),
  searchAgent: async (req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, { endpoint: `/agent` }),
  searchCarrier: async (req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, { endpoint: `/carrier` }),
  exportReport: async (req, opts) =>
    RestApi.request('GET', srvcEndpoint, req, { endpoint: `/export` }),
}

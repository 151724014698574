import React, { useState, useEffect } from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBIcon,
  MDBAlert,
} from 'mdbreact'
import StateLicenseMap from './../StateLicenseMap/StateLicenseMap.component'
import UserProfileService from '../../../../shared/services/UserProfile.service'
import { UIInputCheckbox } from '../../../../components/forms/form-fields'
import { UserMetaFactory } from '../../../../shared/factories'
import './SubsiteNavigation.component.scss'

const DELAY_IN_SECONDS = 8,
  SUPPRESS_FOR_DAYS = 60

class blueBookStore {
  timer = null
  seconds = DELAY_IN_SECONDS
  isAuthed = false
  isOpen = false
  userId = null
  UserMeta = null
  hideModalInFuture = false

  constructor() {
    makeAutoObservable(this)
    this.isAuthed = parseInt(UserProfileService.getUserId()) > 0
  }

  toggle = async (isOpen) => {
    if (typeof isOpen === 'boolean') {
      if (!(this.isOpen = isOpen)) this.isOpen = false
    } else {
      this.isOpen = !this.isOpen
    }

    if (!this.isOpen) {
      if (this.timer) window.clearInterval(this.timer)
      this.timer = null
      this.seconds = DELAY_IN_SECONDS
    } else {
      if (this.timer) window.clearInterval(this.timer)
      this.timer = null

      await new Promise((resolve, reject) => {
        this.timer = setInterval(() => {
          this.seconds = this.seconds - 1
          if (!this.seconds) {
            window.clearInterval(this.timer)
            this.timer = null
            this.visitBlueBookUrl()
            return resolve()
          }
        }, 1000)
      })

      this.seconds = DELAY_IN_SECONDS
    }

    return Promise.resolve()
  }

  shouldSuppressModal = () => {
    const metaValue = this.UserMeta?.get('meta_value') || {}

    if (metaValue > new Date().getTime()) {
      // acknowledged time has not expired.
      // suppress modal
      return true
    }

    // User is not identified.
    // Suppress modal.
    if (!this.userId) return true

    // Do not suppress.
    return false
  }

  visitBlueBookUrl = async (blueBookUrl) => {
    if (this.hideModalInFuture) this.acknowledgeModal()

    this.toggle(false)

    window.open(blueBookUrl)
  }

  acknowledgeModal = async () => {
    this.UserMeta.set(
      'meta_value',
      new Date().getTime() + parseInt(86400 * 1000 * SUPPRESS_FOR_DAYS)
    )
    await this.UserMeta.save()
  }

  setUserId = async (userId) => {
    if (!userId) {
      this.UserMeta = null
      this.isAuthed = false
      return (this.userId = null)
    }

    if (parseInt(userId) === parseInt(this.userId)) return this.userId

    this.UserMeta = null
    this.userId = userId

    let UserMeta = (
      await UserMetaFactory.search({
        search: { user_id: userId, meta_key: 'subsite---ack-blue-book' },
        pagination: false,
      })
    ).shift()
    UserMeta = UserMeta
      ? UserMeta
      : await UserMetaFactory.create({
          user_id: userId,
          meta_key: 'subsite---ack-blue-book',
        })
    this.UserMeta = UserMeta
  }

  openBlueBookModal = async (blueBookUrl) => {
    // has the modal been suppressed?
    if (this.shouldSuppressModal()) {
      this.visitBlueBookUrl(blueBookUrl)
      return false
    }

    // open the modal.
    bbStore.toggle(true).finally(() => {
      this.visitBlueBookUrl(blueBookUrl)
    })

    return false
  }
}

const bbStore = new blueBookStore()

const getLinkUrl = (link) => {
  let url =
    link?.url &&
    link.url
      .replace(/^(https:?\/\/(www\.)?)/i, 'https://www.')
      .replace(/^(http:?\/\/(www\.)?)/i, 'http://www.')
  url = url && !/^((http|https):)|(\/\/)/i.test(url) ? `//${url}` : url
  return url ? url : ''
}

const SubsiteNavigation = ({ subsiteData, licenses }) => {
  const {
      seconds,
      isAuthed,
      setUserId,
      acknowledgeModal,
      visitBlueBookUrl,
      openBlueBookModal,
    } = bbStore,
    userId = parseInt(UserProfileService.getUserId()),
    firstName = subsiteData?.u_fname
      ? subsiteData.u_fname.charAt(0).toUpperCase() +
        subsiteData.u_fname.substr(1)
      : null

  useEffect(() => {
    setUserId(userId)
  }, [userId])

  const openMenuLink = (event, link) => {
    if (event) event.preventDefault()
    if (link?.url)
      window.open(
        link.url
          .replace(/^(https:?\/\/(www\.)?)/, 'https://www.')
          .replace(/^(http:?\/\/(www\.)?)/, 'http://www.')
      )
    return false
  }

  const LINKS = [
    {
      label: 'Web Conference' + (firstName ? ` with ${firstName}` : ''),
      icon: <MDBIcon icon="user-plus" />,
      link: subsiteData?.s_web_confrence_link || '#',
      onClick: subsiteData?.s_web_confrence_link
        ? (event) => {
            if (event) event.preventDefault()
            window.open(subsiteData.s_web_confrence_link)
            return false
          }
        : false,
    },
    {
      label: 'Book an Appointment',
      icon: '',
      link: subsiteData?.s_book_appt_link || '#',
      onClick: subsiteData?.s_book_appt_link
        ? (event) => {
            if (event) event.preventDefault()
            window.open(subsiteData.s_book_appt_link)
            return false
          }
        : false,
    },
    {
      label: 'Check Best RX Pricing!',
      icon: '',
      link:
        subsiteData?.rx_card_link || 'https://applink.glicrx.com/mkB5JKnTcjb',
      onClick: (event) => {
        if (event) event.preventDefault()
        if (subsiteData?.rx_card_link) {
          window.open(subsiteData.rx_card_link)
          return false
        }
        window.open(`https://applink.glicrx.com/mkB5JKnTcjb`)
        return false
      },
    },
    {
      label: 'Healthcare Blue Book',
      icon: '',
      link: '#', // subsiteData?.s_bb_url ||
      onClick:
        isAuthed && subsiteData?.s_bb_url
          ? (event) => openBlueBookModal(subsiteData.s_bb_url)
          : false,
    },
  ]

  return (
    <div id="SubsiteNavigationComponent">
      <ul>
        {Object.keys(LINKS)
          .filter((link) => LINKS[link].onClick)
          .map((link, idx) => {
            return (
              <li key={`agent-subsite-nav-link-sl-${idx}`}>
                <a href={LINKS[link].link} onClick={LINKS[link].onClick}>
                  {LINKS[link].icon ? LINKS[link].icon : ''}
                  {LINKS[link].label}
                </a>
              </li>
            )
          })}
        {(subsiteData?.menu_links || []).map((link, idx) => {
          return (
            <li key={`agent-subsite-nav-link-ml-${idx}`}>
              <a
                href={getLinkUrl(link)}
                target="_BLANK"
                rel="noopener noreferrer"
                onClick={(event) => openMenuLink(event, link)}
              >
                {link.displayname}
              </a>
            </li>
          )
        })}
        {subsiteData?.s_state_license_show ? (
          <li>
            <a href={`/${subsiteData?.u_login}/licenses`}>
              <label className="state-lic-link">
                <StateLicenseMap licenses={licenses} clickable={false} />
                View My State Licenses
              </label>
              <br />
              <small>Not available in all states, including New York.</small>
            </a>
          </li>
        ) : (
          <></>
        )}
      </ul>
      <div id="blueBookModal">
        <MDBModal isOpen={bbStore.isOpen} toggle={() => bbStore.toggle(false)}>
          <MDBModalHeader toggle={() => bbStore.toggle(false)}>
            Healthcare Blue Book
          </MDBModalHeader>
          <MDBModalBody>
            <MDBAlert color="warning">
              <strong>Attention: You are about to leave USABG.com</strong>
            </MDBAlert>
            <p>
              Please be aware.
              <br />
              Visiting the Healthcare Blue Book requires that you be logged into
              your PAL account.{' '}
            </p>
            <UIInputCheckbox
              label={'Hide this message for the next 60 days?'}
              name={`hide_modal`}
              id={`ack_blue_book`}
              onChange={(evt) => {
                bbStore.hideModalInFuture = !bbStore.hideModalInFuture
              }}
              checked={!!bbStore.hideModalInFuture}
            />
            <br />
            Redirecting to Healthcare Blue Book in {seconds} seconds ...&nbsp;
            <button onClick={() => visitBlueBookUrl(subsiteData?.s_bb_url)}>
              Go to HBB now&nbsp;
              <MDBIcon icon="arrow-right" />
            </button>
          </MDBModalBody>
        </MDBModal>
      </div>
    </div>
  )
}

export default observer(SubsiteNavigation)

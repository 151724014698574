import React, { useEffect, useState } from 'react'
import CarrierService from '../../../../../shared/services/Carrier.service'
import { MDBCol, MDBRow, MDBSwitch } from 'mdbreact'
import UserProfileService from '../../../../../shared/services/UserProfile.service'
import LoadingSpinner from '../../../../../components/shared/LoadingSpinner.component'
import { toast } from 'react-toastify'

import './CarrierManagement.component.scss'

const CarrierManagement = () => {
  const [carriers, setCarriers] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchActiveCarriers()
  }, [])

  const fetchActiveCarriers = async () => {
    try {
      let carriers = await CarrierService.search({
        pagination: false,
        search: { c_active: 1 },
        orderBy: { c_name: 'ASC' },
      })

      carriers = carriers?.models?.map((carrier) => {
        return {
          id: carrier.id,
          name: carrier.c_name,
          disabled_uplines: carrier.disabled_uplines || [],
          sibling_ids: carrier.sibling_ids || [],
          description: carrier.c_description,
        }
      })

      setCarriers(carriers)
      setIsLoading(false)
    } catch (error) {
      toast.error('Failed to fetch active carriers', {
        position: toast.POSITION.TOP_RIGHT,
      })
      setIsLoading(false)
    }
  }

  const toggleCarrier = async (carrier) => {
    let disabled = false
    const user_id = UserProfileService.getUserId()

    if (carrier.disabled_uplines.includes(user_id)) {
      carrier.disabled_uplines = carrier.disabled_uplines.filter(
        (item) => item !== user_id
      )
      disabled = false
    } else {
      carrier.disabled_uplines.push(user_id)
      disabled = true
    }

    try {
      await CarrierService.update(carrier.id, {
        disabled_uplines: JSON.stringify(carrier.disabled_uplines),
      })

      let updated_carriers = carriers
      updated_carriers = updated_carriers.map((item) =>
        item.id === carrier.id ? { ...item, ...carrier } : item
      )
      setCarriers(updated_carriers)

      toast.success(
        `${carrier.name} is ${disabled ? 'disabled' : 'enabled'}.`,
        { position: toast.POSITION.TOP_RIGHT }
      )
    } catch (error) {
      toast.error(
        `Failed to ${disabled ? 'disable' : 'enable'} ${carrier.name}`,
        { position: toast.POSITION.TOP_RIGHT }
      )
    }
  }

  return (
    <div id="CarrierManagementComponent">
      {isLoading ? (
        <div className="loading-spinner">
          <LoadingSpinner size="md" isActive={true} />
        </div>
      ) : (
        <MDBRow>
          {carriers.map((carrier) => {
            return (
              <MDBCol size="12" sm="6" className="carrier-toggle">
                <MDBSwitch
                  labelLeft={
                    <div className="carrier-toggle-label">
                      <div>{carrier.name}:</div>
                      {carrier.sibling_ids.length > 0 && (
                        <div className="carrier-toggle-description">
                          {carrier.description}
                        </div>
                      )}
                    </div>
                  }
                  labelRight={''}
                  name={carrier.name}
                  checked={
                    !carrier.disabled_uplines?.includes(
                      UserProfileService.getUserId()
                    )
                  }
                  onChange={(event) => toggleCarrier(carrier)}
                />
              </MDBCol>
            )
          })}
        </MDBRow>
      )}
    </div>
  )
}

export default CarrierManagement

import { Node } from '@tiptap/core'

const VideoExtension = Node.create({
  name: 'video',

  group: 'block',

  addAttributes() {
    return {
      src: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'iframe',
        getAttrs: (node) => ({
          src: node.getAttribute('src'),
        }),
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'iframe',
      {
        ...HTMLAttributes,
        frameborder: '0',
        allowfullscreen: 'true',
        style: 'max-width: 100%; height: 300px;',
      },
    ]
  },

  addCommands() {
    return {
      setVideo:
        (url) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: { src: url },
          })
        },
    }
  },
})

export default VideoExtension

import React from 'react'
import './Telephone.component.scss'
import {
  phoneToStorage,
  phoneToView,
} from '../../../shared/formatters/telephone.formatter'

const Telephone = ({ phoneNumber, phoneExt = null, className = '' }) => {
  return (
    <a
      href={`tel:${phoneToStorage(phoneNumber)}`}
      style={{ textDecoration: 'underline' }}
      className={`${className}`}
    >
      {phoneToView(phoneNumber)}
      {phoneExt ? ` ext: ${phoneExt}` : ''}
    </a>
  )
}

export default Telephone

import React from 'react'
import DashboardLayout from './../../components/Admin/DashboardLayout'
import UserProfileService from './../../shared/services/UserProfile.service'
import UserService from './../../shared/services/User.service'
import ClientForm from './components/ClientForm/ClientForm.component'
import PolicySubmitter from './components/PolicySubmitter/PolicySubmitter.component'
import AvPoliciesList from './components/AvPoliciesList/AvPoliciesList.component'
import RecentApplications from './components/RecentApplications/RecentApplications.component'
import AvService from './../../shared/services/Av.service'
import DateService from './../../shared/services/Date.service'
import SalesGuidelines from './components/SalesGuidelines/SalesGuidelines.component'
import SubmitSalesService from './shared/SubmitSales.service'
import {
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCollapse,
  MDBAlert,
} from 'mdbreact'
import SalesTrackerStore from './store'

import './SalesTracker.page.scss'

class SalesTrackerPage extends React.Component {
  _isAdmin = false
  _avUserId = null

  state = {
    loading: true,
    showGuidelines: false,
    showForm: false,
    canResetPoints: false,
    resettingPoints: false,
    resettingAcceptance: false,
    resetPoints: null,
    canShowGuidelines: false,
    avUserId: null,
    hasAccepted: null,
  }

  /*
   * Component Accessor Methods ----------------------
   *
   */

  /*
   * Component Public Methods ------------------------
   *
   */

  /*
   * Component Private Methods -----------------------
   *
   */

  _showGuidelines = () => this._setShowGuidelines('guidelines')

  _hasAccepted = (hasAccepted) => this.setState({ hasAccepted })

  _canResetPoints = (avId) => {
    let canReset = !!(avId > 0 && this._isAdmin)
    if (canReset !== this.state.canResetPoints)
      this.setState({ canResetPoints: avId ? avId : false })
  }

  _resetPoints = () => {
    if (this.state.canResetPoints) {
      this.setState({ resettingPoints: true })
      AvService.resetPoints(this.state.canResetPoints).then((res) => {
        SubmitSalesService.setActiveSale(this.state.canResetPoints)

        if (res.points) {
          this.setState({ resetPoints: res.points })
          setTimeout(
            () => this.setState({ resetPoints: null, resettingPoints: false }),
            5000
          )
        } else {
          this.setState({ resettingPoints: false })
        }
      })
    }
  }

  _resetAcceptance = async () => {
    if (this._isAdmin && this._avUserId) {
      this.setState({ resettingAcceptance: true })
      await SubmitSalesService.resetAcceptance(this._avUserId)
      this.setState({ resettingAcceptance: false, hasAccepted: null })
    }
  }

  _setShowGuidelines = (open) => {
    if (open === 'guidelines')
      return this.setState({
        loading: false,
        showGuidelines: true,
        showForm: false,
        canShowGuidelines: false,
      })

    if (open === 'form')
      return this.setState({
        loading: false,
        showGuidelines: false,
        showForm: true,
        canShowGuidelines: true,
      })
  }

  _renderAcceptanceNotification = () => {
    if (this._isAdmin && UserProfileService.isAssumed()) {
      if (this.state.hasAccepted)
        return (
          <MDBAlert color="info" className="text-center">
            <span style={{ fontWeight: 500 }}>
              The agent accepted the Sales Guidelines on{' '}
              {DateService.dateOnlyToString(
                this.state.hasAccepted,
                'MM/DD/YYYY'
              )}
              .
            </span>
          </MDBAlert>
        )
      if (!this.state.hasAccepted)
        return (
          <MDBAlert color="danger" className="text-center">
            <span style={{ fontWeight: 500 }}>
              The agent has not accepted the Sales Guidelines.
            </span>
          </MDBAlert>
        )
    }
  }

  _renderResetAcceptanceButton = () => {
    if (
      this._isAdmin &&
      this._avUserId &&
      this.state.hasAccepted &&
      UserProfileService.isAssumed()
    )
      return (
        <MDBBtn
          className="btn-block mb-3"
          disabled={this.state.resettingAcceptance}
          style={{ fontWeight: 500 }}
          color="indigo"
          onClick={() => this._resetAcceptance()}
        >
          {this.state.resettingAcceptance
            ? 'Resetting ...'
            : "Reset Agent's Sales Guideline Acceptance"}
        </MDBBtn>
      )
  }

  checkUserPermissions = async () => {
    let userDetails = UserProfileService.getUserDetails(),
      uplineId =
        userDetails &&
        typeof userDetails === 'object' &&
        userDetails.u_upline_id
          ? parseInt(userDetails.u_upline_id, 10)
          : false,
      uplineDetails = uplineId
        ? await UserService.getUserDetails(uplineId)
        : null,
      sellingAgentList = [
        {
          text: [userDetails.u_fname, userDetails.u_lname].join(' ').trim(),
          value: `${userDetails.id}`,
        },
      ],
      sellingAgentId = UserProfileService.getUserId(),
      avSellingAgent = null

    if (
      UserProfileService.isA([
        'csr-agent',
        'division-admin',
        'region-admin',
        'district-admin',
      ])
    )
      sellingAgentList.push({
        text: [uplineDetails.u_fname, uplineDetails.u_lname].join(' ').trim(),
        value: `${uplineDetails.id}`,
      })

    if (
      Array.isArray(sellingAgentList) &&
      sellingAgentList.filter((s) => s).length
    ) {
      if (
        SalesTrackerStore.AOR.sellingAgentId &&
        !isNaN(SalesTrackerStore.AOR.sellingAgentId)
      ) {
        if (
          sellingAgentList.filter(
            (item) =>
              `${item.value}` === `${SalesTrackerStore.AOR.sellingAgentId}`
          ).length > 0
        )
          sellingAgentId = SalesTrackerStore.AOR.sellingAgentId
      }

      sellingAgentId = sellingAgentId
        ? sellingAgentId
        : sellingAgentList[0].value
      if (sellingAgentId)
        avSellingAgent = await SalesTrackerStore.getAvSellingAgent(
          sellingAgentId
        )
    }

    SalesTrackerStore.AOR = { sellingAgentList, sellingAgentId, avSellingAgent }
  }

  initPolicy = async (avId) => {
    SalesTrackerStore.setClientType('individual')
    SalesTrackerStore.fetchById(avId).finally(() => this.checkUserPermissions())
  }

  /*
   * Component Event Methods -------------------------
   *
   */
  componentDidMount() {
    this._isAdmin = UserProfileService.isA(
      ['system-admin', 'agency-owner', 'internal-admin'],
      true
    )
    this._avUserId = UserProfileService.getUserId()

    const {
      match: { params },
    } = this.props

    this.initPolicy(params && params?.id)

    Promise.all([
      SalesTrackerStore.fetchCarriers(),
      SalesTrackerStore.fetchPointOverrides(),
      SalesTrackerStore.fetchCoverages(),
      SalesTrackerStore.fetchLeadSources(),
    ])
  }

  componentDidUpdate() {
    const {
      match: { params },
    } = this.props

    if (!params?.id) this.initPolicy(null)
    Promise.all([SalesTrackerStore.fetchPointOverrides()])
  }

  render() {
    return (
      <React.Fragment>
        <DashboardLayout>
          <main id="SalesTrackerPage" className="mainSection pb-5">
            <MDBContainer fluid className="mt-5">
              <h2>Submit Sales</h2>
              <hr />
              <MDBCollapse isOpen={this.state.showGuidelines}>
                <MDBRow>
                  <MDBCol size="12">
                    <SalesGuidelines
                      open={this._setShowGuidelines}
                      hasAccepted={this._hasAccepted}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCollapse>
              <MDBCollapse isOpen={this.state.showForm}>
                <MDBRow>
                  <MDBCol size="12" sm="7" lg="8">
                    <div className="content-wrapper">
                      <ClientForm />
                      <AvPoliciesList />
                      <br />
                      <PolicySubmitter
                        goTo={() =>
                          // window.location = '/submit-sales'
                          {
                            this.initPolicy(null)
                            this.props.history.push(`/submit-sales`)
                          }
                        }
                      />
                    </div>
                  </MDBCol>
                  <MDBCol size="12" sm="5" lg="4">
                    {this.state.canShowGuidelines ? (
                      <MDBBtn
                        className="btn-block mb-3"
                        style={{ fontWeight: 500 }}
                        color="indigo"
                        onClick={() => this._showGuidelines()}
                      >
                        Show Sales Guidelines
                      </MDBBtn>
                    ) : (
                      <></>
                    )}
                    {this._isAdmin && this.state.canResetPoints ? (
                      <MDBBtn
                        className="btn-block mb-3"
                        disabled={this.state.resettingPoints}
                        style={{ fontWeight: 500 }}
                        color="indigo"
                        onClick={() => this._resetPoints()}
                      >
                        {this.state.resettingPoints
                          ? this.state.resetPoints
                            ? `Reset to ${this.state.resetPoints} points.`
                            : 'Resetting ...'
                          : 'Reset Sales Points'}
                      </MDBBtn>
                    ) : (
                      <></>
                    )}
                    {this._renderAcceptanceNotification()}
                    {this._renderResetAcceptanceButton()}
                    <RecentApplications
                      goTo={(avId) => {
                        this.initPolicy(avId)
                        this.props.history.push(`/submit-sales/${avId}/edit`)
                      }}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCollapse>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </React.Fragment>
    )
  }
}

export default SalesTrackerPage

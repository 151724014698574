import React, { Component } from 'react'
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBModalHeader,
  MDBModalBody,
  MDBModal,
  MDBSwitch,
} from 'mdbreact'
import AdminApi from '../../api/admin-api/admin-api'
import { toast } from 'react-toastify'
import TextEditor from '../shared/TextEditor/TextEditor.component'
import UserProfileService from './../../shared/services/UserProfile.service'
import {
  phoneToStorage,
  phoneToView,
} from './../../shared/formatters/telephone.formatter'
import { email as emailPattern } from './../../shared/patterns/email.pattern'
import { phone as phonePattern } from './../../shared/patterns/telephone.pattern'

import './AdminContentEditPopup.scss'

class AdminContentEditPopupComponent extends Component {
  state = {
    loading: false,
    submitting: false,
    isOpen: false,
    isEnabled: false,
    page_content: '',
    page_name: '',
    page_link: '',
    saved: false,
    links: [],
    active: 1,
  }

  componentDidMount() {
    if (this.props.callback && typeof this.props.callback === 'function')
      this._onComplete = this.props.callback

    this._shouldEnable()
  }

  componentDidUpdate() {
    if (this.props?.forceOpen === true && !this.state.isOpen) this._open()
  }

  _onComplete = () => {}

  _shouldEnable = () => {
    let shouldEnable = UserProfileService.isA(
      ['internal-admin', 'internal-staff', 'system-admin'],
      true
    )
    if (shouldEnable) this.setState({ isEnabled: shouldEnable })
  }

  _open = () => {
    if (!document.body.classList.contains('content-editor-modal-open'))
      document.body.classList.add('content-editor-modal-open')
    this.setState({ isOpen: true })
  }

  _close = () => {
    document.body.classList.remove('content-editor-modal-open')
    this.setState({ isOpen: false })
  }

  _toggleModel = () => {
    if (this.state.loading) return

    if (this.state.isOpen) return this._close()

    if (this.props.pageId) this._fetchContent()
    this._open()
  }

  _stripPrefixLink = (link) => `${link}`.trim().replace(/^(mailto|tel):/, '')

  _formatView = (link) => (phonePattern.test(link) ? phoneToView(link) : link)

  _prefixLink = (link) => {
    link = this._stripPrefixLink(link)

    if (emailPattern.test(link)) return `mailto:${link}`

    if (phonePattern.test(link)) return `tel:${phoneToStorage(link)}`

    return link
  }

  _fetchContent = async () => {
    this.setState({ loading: true })

    AdminApi.getContentById(this.props.pageId)
      .then((result) => {
        result =
          result && result.data && result.data.data && result.data.data
            ? result.data.data
            : false
        let links =
            result.links &&
            (typeof result.links === 'string'
              ? JSON.parse(result.links)
              : result.links),
          updatedToolLinks = Array.isArray(links)
            ? links.map((item, i) => ({
                ...item,
                indexId: i,
                tl_link: this._formatView(this._stripPrefixLink(item.tl_link)),
              }))
            : links
        this.setState({
          loading: false,
          page_content: (result && result?.content) || '',
          page_name: (result && result?.page_name) || '',
          active: parseInt(result && result?.active) === 1 ? 1 : 0,
          links: updatedToolLinks || [],
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  _updateContent = async () => {
    if (this.state.loading || this.state.submitting) return

    if (!this.state.page_name || !this.state.page_name.trim())
      return toast.error('Page Title Required', {
        position: toast.POSITION.TOP_RIGHT,
      })

    const { links, active } = this.state
    this.setState({ submitting: true })

    const data = {
      page_name: this.state.page_name,
      content: this.state.page_content,
      author_id: UserProfileService.getUserId(),
      table_name: 'agency_content',
      id: this.props.pageId,
      links: JSON.stringify(
        Array.isArray(links)
          ? links.map((link) => ({
              ...link,
              tl_link: this._prefixLink(link.tl_link),
            }))
          : links
      ),
      active,
    }

    if (this.props.slugName) data['content_slug'] = this.props.slugName

    if (this.props.pageId) data['id'] = this.props.pageId

    let form_data = new FormData()
    for (let key in data) form_data.append(key, data[key])

    AdminApi.updatePageById(form_data)
      .then((result) => {
        this.setState({ submitting: false, saved: true })
        toast.success('Content updated successfully.', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this._close()
        this._onComplete(this.props.pageId)
      })
      .catch((error) => {
        this.setState({ submitting: false })
        toast.error('Could not update content.', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  _deleteContent = async () => {
    this.setState({ deleting: true })
    const data = { id: this.props.pageId, type: 'agency_content' }

    AdminApi.deleteOrderLeads(data)
      .then((result) => {
        this.setState({ deleting: false })
        this._close()
        toast.success('Content deleted successfully.', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this._close()
        this._onComplete(this.props.pageId)
      })
      .catch((error) => {
        this.setState({ deleting: false })
        toast.error(`${error.message}`, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  _onChange = (event) =>
    this.setState({ [event.target.name]: event.target.value })

  _getIconPosition = () => {
    let style = this.props.iconStyle
    style =
      style && typeof style === 'object' && Object.keys(style).length
        ? style
        : {}

    switch (this.props.iconPosition) {
      case 'top-right':
      case 'topRight':
        return { ...style, top: 0, right: 0, zIndex: 999 }
      case 'top-left':
      case 'topLeft':
        return { ...style, top: 0, left: 0, zIndex: 999 }
      case 'bottom-right':
      case 'bottomRight':
        return { ...style, bottom: 0, right: 0, zIndex: 999 }
      case 'bottom-left':
      case 'bottomLeft':
        return { ...style, bottom: 0, left: 0, zIndex: 999 }
      default:
        return { ...style, top: 0, right: 0, zIndex: 999 }
    }
  }

  _renderEditButton = () => {
    let color = `${this.props?.color}`.trim().toLowerCase()

    if (this.props.btnLabel) {
      return (
        <MDBBtn
          size="sm"
          onClick={this._toggleModel}
          style={this._getIconPosition()}
          className={this.props.btnClass ? this.props.btnClass : ''}
        >
          <span>{this.props.btnLabel}</span>&nbsp;
          {this.props.actionType && this.props.actionType === 'add' ? (
            <MDBIcon icon="plus" className={`cursor-pointer text--${color}`} />
          ) : (
            <MDBIcon icon="edit" className={`cursor-pointer text--${color}`} />
          )}
        </MDBBtn>
      )
    } else {
      return this.props.actionType && this.props.actionType === 'add' ? (
        <MDBIcon
          icon="plus"
          className={`cursor-pointer position-absolute text--${color}`}
          style={this._getIconPosition()}
          onClick={this._toggleModel}
        />
      ) : (
        <MDBIcon
          icon="edit"
          className={`cursor-pointer position-absolute text--${color}`}
          style={this._getIconPosition()}
          onClick={this._toggleModel}
        />
      )
    }
  }

  _handleRemoveLink = (i) => {
    let links = [...this.state.links]
    links.splice(i, 1)
    links.map((item, i) => {
      return (item.indexId = i)
    })
    this.setState({ links })
  }

  _handleAddNewLink = () => {
    let links = [...this.state.links]
    links.push({
      indexId: links.length,
      tl_type: 'Button',
      tl_title: '',
      tl_link: '',
    })
    this.setState({ links })
  }

  _onLinkItemTypeChange = (event, i) => {
    let links = [...this.state.links]
    links[i].tl_type = event.target.value
    this.setState({ links })
  }

  _onLinkItemTitleChange = (event, i) => {
    let links = [...this.state.links]
    links[i].tl_title = event.target.value
    this.setState({ links })
  }

  _onLinkItemURLChange = (event, i) => {
    let links = [...this.state.links]
    links[i].tl_link = event.target.value
    this.setState({ links })
  }

  render() {
    const { submitting, isEnabled, isOpen, loading, links, active } = this.state

    if (!isEnabled) return <></>

    return (
      <>
        {this._renderEditButton()}
        <MDBModal
          disableBackdrop={true}
          size="lg"
          isOpen={isOpen}
          toggle={this._toggleModel}
          className="text-left h6 AdminContentEditPopupComponent"
        >
          <MDBModalHeader toggle={this._toggleModel}>
            {this.props.actionType && this.props.actionType === 'add'
              ? 'Add Data'
              : 'Update Data'}
          </MDBModalHeader>
          <MDBModalBody>
            {loading ? (
              <div className="h2">Loading...</div>
            ) : (
              <>
                <form className="container-fluid">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="page_name">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="page_name"
                        id="page_name"
                        value={this.state.page_name}
                        onChange={this._onChange}
                      />
                    </div>
                  </div>

                  {this.props.type && this.props.type === 'URL' && (
                    <div className="form-row">
                      <div className="col-md-12">
                        <label className="grey-text h6" htmlFor="page_link">
                          Link
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="page_link"
                          id="page_link"
                          value={this.state.page_link}
                          onChange={this._onChange}
                        />
                      </div>
                    </div>
                  )}

                  {this.props.type && this.props.type === 'RTE' && (
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label>Content</label>
                        {
                          <TextEditor
                            id="admincontentedit"
                            value={this.state.page_content}
                            onChange={(html) =>
                              this.setState({ page_content: html })
                            }
                          />
                        }
                      </div>
                    </div>
                  )}

                  {this.props.isLink ? (
                    <>
                      <div className="form-row">
                        <div className="col-md-12">
                          <label className="grey-text h6">Links</label>
                        </div>
                      </div>
                      {links &&
                        links.length > 0 &&
                        links.map((item, i) => {
                          return (
                            <div className="form-row" key={i}>
                              <div className="form-group col-md-2">
                                <label
                                  className="grey-text"
                                  htmlFor={'link_type' + i}
                                >
                                  Link Type
                                </label>
                                <select
                                  className="form-control"
                                  id={'link_type' + i}
                                  value={item.tl_type}
                                  onChange={(event) => {
                                    this._onLinkItemTypeChange(event, i)
                                  }}
                                >
                                  <option value="Button">Button</option>
                                  <option value="Image">Image</option>
                                  <option value="Youtube">YouTube</option>
                                </select>
                              </div>
                              <div className="form-group col-md-3">
                                <label
                                  className="grey-text"
                                  htmlFor={'link_title' + i}
                                >
                                  Link Title
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={'link_title' + i}
                                  value={item.tl_title}
                                  onChange={(event) => {
                                    this._onLinkItemTitleChange(event, i)
                                  }}
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label
                                  className="grey-text"
                                  htmlFor={'links' + i}
                                >
                                  Link
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={'links' + i}
                                  value={item.tl_link}
                                  onChange={(event) => {
                                    this._onLinkItemURLChange(event, i)
                                  }}
                                />
                              </div>
                              <div className="form-group col-md-1 align-self-end">
                                <MDBBtn
                                  size={'sm'}
                                  tag="a"
                                  floating
                                  color="primary"
                                  title={'Remove Link'}
                                  className="m-0"
                                  onClick={() => {
                                    this._handleRemoveLink(i)
                                  }}
                                >
                                  <MDBIcon icon="minus" />
                                </MDBBtn>
                              </div>
                            </div>
                          )
                        })}
                      <div className="form-row">
                        <div className="form-group col-md-2">
                          <MDBBtn
                            size="sm"
                            tag="a"
                            floating
                            gradient="blue"
                            title="Add New Link"
                            onClick={this._handleAddNewLink}
                          >
                            <MDBIcon icon="plus" />
                          </MDBBtn>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-12 text-dark">
                          <label>Active:</label>
                          <MDBSwitch
                            checked={!!active}
                            onChange={() => {
                              this.setState({ active: active ? 0 : 1 })
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <MDBRow className="mt-4">
                    <MDBCol size="12" md="6">
                      <MDBBtn
                        className="content-editor-btn content-editor-update-btn btn-block"
                        color="unique"
                        type="button"
                        onClick={this._updateContent}
                      >
                        {submitting
                          ? 'Submitting...'
                          : `${
                              this.props.actionType &&
                              this.props.actionType === 'add'
                                ? 'Add'
                                : 'Update'
                            }`}
                      </MDBBtn>
                    </MDBCol>
                    {this.props.canDelete ? (
                      <MDBCol size="12" md="3">
                        <MDBBtn
                          className="content-editor-btn content-editor-delete-btn btn-block"
                          color="danger"
                          size="sm"
                          onClick={this._deleteContent}
                        >
                          Delete
                        </MDBBtn>
                      </MDBCol>
                    ) : (
                      <></>
                    )}
                    <MDBCol size="12" md={this.props.canDelete ? '3' : '6'}>
                      <MDBBtn
                        className="content-editor-btn content-editor-cancel-btn btn-block"
                        color="secondary"
                        size="sm"
                        onClick={this._toggleModel}
                      >
                        {this.state.saved ? 'Close' : 'Cancel'}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </form>
              </>
            )}
          </MDBModalBody>
        </MDBModal>
      </>
    )
  }
}

export default AdminContentEditPopupComponent

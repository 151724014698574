import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { MDBInput, MDBCol, MDBBtn, MDBIcon } from 'mdbreact'
import {
  UIDatePickerInput,
  UIInputButton,
} from './../../../../components/forms/form-fields'

import moment from 'moment'

import './AdminW9Form.scss'

const AdminW9Form = ({ referralPartnerId, Store }) => {
  const [w9, setW9] = useState({
      w9_year: null,
      received_at: null,
      referral_partner_id: referralPartnerId,
    }),
    [btnAttr, setBtnAttr] = useState({
      label: 'Save Referral Partner W9',
      disabled: false,
    }),
    isValid = (payload) =>
      !Object.values((payload ? payload : w9) || {})
        .concat([!!referralPartnerId])
        .includes(false)

  const updateW9 = async () => {
    setBtnAttr({ label: 'Loading...', disabled: true })

    const payload = { ...w9 },
      isNew = !payload?.id || isNaN(payload.id)

    payload.w9_year =
      !isNaN(payload.w9_year) &&
      payload.w9_year > 1990 &&
      payload.w9_year < 2100
        ? payload.w9_year
        : false
    payload.received_at =
      (
        (payload.received_at &&
          `${payload.received_at}`.match(/^\d{4}-\d{2}-\d{2}/)) ||
        []
      ).shift() || false
    payload.referral_partner_id =
      payload?.referral_partner_id && !isNaN(payload.referral_partner_id)
        ? payload.referral_partner_id
        : referralPartnerId
    if (!isNew) payload.id = w9.id

    if (isValid(payload)) {
      try {
        let result = await Store[isNew ? 'storeW9' : 'updateW9'](payload)
        setW9((prevW9) => ({ ...prevW9, ...result }))
        setBtnAttr({ label: 'Saved!', disabled: true })
        setW9({
          w9_year: '',
          received_at: '',
          referral_partner_id: referralPartnerId,
        })
      } catch (ex) {
        console.error(`Failed to save W9.  ${ex}`)
        setBtnAttr({ label: `${ex}` })
      }
    } else {
      if (!payload.referral_partner_id)
        setBtnAttr(
          (btnAttr.label = {
            label: 'Referral Partner Not Set!',
            disabled: true,
          })
        )
      else if (!payload.received_at)
        setBtnAttr(
          (btnAttr.label = { label: 'Invalid Date Received', disabled: true })
        )
      else if (!payload.w9_year)
        setBtnAttr(
          (btnAttr.label = { label: 'Invalid W9 Year', disabled: true })
        )
    }

    setTimeout(
      () => setBtnAttr({ label: 'Save Referral Partner W9', disabled: false }),
      2000
    )
  }

  const getDate = (date) => {
    if (!date) return new Date()

    if (typeof date === 'object') return date

    if (typeof date === 'string') date = date.split(/(T| )/).shift()

    if (date) {
      date = date.replace(/(-|\/)/g, ' ').split(' ')
      return new Date(date.shift(), date.shift() - 1, date.shift())
    }

    return new Date(date)
  }

  const ReceivedAt = (
    <UIDatePickerInput
      name="received_at"
      id="received_at"
      label="Date Received"
      required={true}
      selected={getDate(w9?.received_at)}
      onChange={(e) =>
        setW9((prevW9) => ({
          ...prevW9,
          received_at: e?.target?.value
            ? moment(e.target.value).format('YYYY-MM-DD')
            : '',
        }))
      }
    />
  )
  const setToToday = () =>
    setW9((prevW9) => ({
      ...prevW9,
      received_at: moment().format('YYYY-MM-DD'),
    }))

  return (
    <form
      id="AdminW9FormComponent"
      className={'content-wrapper ' + (Store.isLoading.w9 ? 'is-loading' : '')}
    >
      <div className="heading fw--500 fs--1rem">Manage Referral Partner W9</div>
      <MDBCol size="12">
        {!Store.w9s.length ? (
          <span className="alert alert-warning">
            Referral Partner does not have W9 on record.
          </span>
        ) : (
          <ul>
            <li>
              <span>W9 Year</span>
              <span>Edit</span>
            </li>
            {Store.w9s.map((w9) => (
              <li key={`referral-partner-w9-${w9.id}`}>
                <span>
                  <strong>{w9.w9_year}</strong>&nbsp;
                </span>
                <small>
                  (Rec'd:{' '}
                  {moment(w9.received_at.split('T').shift()).format(
                    'MM/DD/YYYY'
                  )}
                  )
                </small>
                <button type="button" onClick={() => setW9(toJS(w9))}>
                  <MDBIcon icon="edit" />
                </button>
              </li>
            ))}
          </ul>
        )}
      </MDBCol>
      <MDBCol size="12">
        <UIInputButton
          input={ReceivedAt}
          btnContent={<div className="fs--075rem fw--500">Set to Today</div>}
          onClick={setToToday}
          btnPosition={'right'}
        />
      </MDBCol>
      <MDBCol size="12">
        <MDBInput
          maxLength="4"
          label="W-9 Year *"
          value={(w9 && w9?.w9_year) || ''}
          name="w9_year"
          onChange={(e) => setW9({ ...w9, w9_year: e?.target?.value })}
        />
      </MDBCol>
      <MDBCol size="12">
        <MDBBtn
          disabled={btnAttr.disabled}
          onClick={() => updateW9()}
          color="success"
          block
        >
          {btnAttr.label}
        </MDBBtn>
      </MDBCol>
      <div className="loading-wrapper">
        <div>
          <i className="fa fa-spin fa-spinner"></i>
        </div>
        <div className="fw--500 fs--1125rem">
          Loading Referral Partner W9 Details
        </div>
      </div>
    </form>
  )
}
export default observer(AdminW9Form)

import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  checkValidity,
  getErrorMessage,
  getSuccessMessage,
  isDirty,
} from './../shared/validator.function'
import './UITypeAhead.component.scss'
import { MDBIcon } from 'mdbreact'

const UITypeAhead = ({
  options = [],
  placeholder = '',
  onSelect,
  debounceTime = 300,
  value = '',
  showDropdownLimit = 3,
  required = false,
  rules,
  showValidity,
  onValidityChange,
}) => {
  rules = rules && typeof rules === 'object' ? rules : {}
  if (required) rules.required = true

  const isValid =
    !rules.required ||
    (Array.isArray(options) ? options : []).filter(
      (o) => `${o.value}` === `${value}`
    ).length > 0
  const selected = (options || []).filter((n) => n?.checked).shift()
  const isSelected = !!selected
  const selectedLabel = isSelected && (selected.text || selected.value)
  const [query, setQuery] = useState('')
  const [filteredOptions, setFilteredOptions] = useState([])
  const [showOptions, setShowOptions] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [uiState, setUiState] = useState({
    isDirty: false,
    isPristine: true,
    isTouched: false,
    validity: { isValid, errors: isValid ? [] : ['required'] },
  })
  const debounceTimeout = useRef(null)

  const handleInputChange = useCallback(
    (e) => {
      const inputValue = e.target.value

      if (!inputValue && isSelected) {
        setQuery('')
        clear()
      }

      setUiState((prevState) =>
        !uiState.isDirty && isDirty(value, inputValue)
          ? { ...prevState, isDirty: true, isPristine: false }
          : { ...prevState, isDirty: false }
      )

      checkValidity(inputValue, rules).then((validity) => {
        setUiState((prevState) => ({ ...setUiState, validity }))
        ;(typeof onValidityChange === 'function'
          ? onValidityChange
          : (validity) => {})(validity)
      })

      setQuery(inputValue)

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }

      debounceTimeout.current = setTimeout(() => {
        if (inputValue.trim().length >= showDropdownLimit) {
          const filtered = options.filter((option) =>
            option?.text.toLowerCase().includes(inputValue.toLowerCase())
          )
          setFilteredOptions(filtered)
        } else {
          setFilteredOptions(options)
        }
      }, debounceTime)
    },
    [debounceTime, options]
  )

  const clear = () => {
    setQuery('')
    onSelect({ value: false })
  }

  const handleOptionClick = (option) => {
    setQuery(option?.text)
    setShowOptions(false)
    if (onSelect) onSelect(option)
    setIsFocused(false)
  }

  // If the selection has been removed, clear the entered terms.
  useEffect(() => {
    if (!selectedLabel) setQuery('')
  }, [selectedLabel])

  useEffect(() => {
    if (
      query &&
      query.trim().length >= showDropdownLimit &&
      filteredOptions.length > 0
    ) {
      setShowOptions(true)
    } else {
      setShowOptions(false)
    }
  }, [filteredOptions])

  useEffect(() => {
    // Update the query if the value prop changes
    if (value) {
      const filtered = options.find((option) => option.value === value)
      setQuery(filtered?.text)
    }

    checkValidity(value, rules).then((validity) => {
      setUiState((prevState) => ({ ...setUiState, validity }))
      ;(typeof onValidityChange === 'function'
        ? onValidityChange
        : (validity) => {})(validity)
    })
  }, [value])

  useEffect(() => {
    if (query && query.trim().length >= showDropdownLimit) {
      const filtered = options.filter((option) =>
        option?.text.toLowerCase().includes(query.toLowerCase())
      )
      setFilteredOptions(filtered)
    } else setFilteredOptions(options)
  }, [options])

  // Update (clear) the selection if the input is cleared out.
  useEffect(() => {
    if (!query && isSelected) setQuery(selected.text)
  }, [query, isSelected])

  return (
    <div
      id="UITypeAhead"
      className={[
        'ui-input',
        'md-form',
        isSelected ? 'is-selected' : '',
        uiState.validity.isValid ? 'is-valid' : 'is-invalid',
        uiState.isDirty ? 'is-dirty' : '',
        uiState.isPristine ? 'is-pristine' : '',
        uiState.isTouched ? 'is-touched' : '',
        showValidity ? 'show-validity' : '',
      ].join(' ')}
    >
      <input
        type="text"
        placeholder={placeholder + (required ? ' *' : '')}
        value={query}
        onChange={handleInputChange}
        onFocus={() => {
          if (query.trim().length >= showDropdownLimit) setShowOptions(true) // Show only if query length >= 3
          setIsFocused(true)
        }}
        onBlur={() => {}}
      />
      <MDBIcon
        className="clear-button"
        fas
        icon="times"
        onClick={(e) => clear()}
      />
      {showOptions && isFocused && (
        <ul>
          {filteredOptions?.map((option, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(option)}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#f0f0f0')}
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#fff')}
            >
              {option.text}
            </li>
          ))}
        </ul>
      )}
      <div className="ui-input-validation">
        <span className="success-msg">
          {showValidity ? getSuccessMessage() : ''}
        </span>
        <span className="error-msg">
          {showValidity ? getErrorMessage(uiState.validity.errors) : ''}
        </span>
      </div>
    </div>
  )
}

export default UITypeAhead

import { Node } from '@tiptap/core'

const IndentExtension = Node.create({
  name: 'indent',

  addGlobalAttributes() {
    return [
      {
        types: ['paragraph'], // Apply indentation to these node types
        attributes: {
          level: {
            default: 0,
            renderHTML: (attributes) => {
              if (!attributes.level || attributes.level === 0) {
                return {}
              }
              return {
                style: `margin-left: ${attributes.level}em`,
              }
            },
            parseHTML: (element) => {
              const marginLeft = element.style.marginLeft
              return marginLeft ? parseInt(marginLeft, 10) : 0
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      increaseIndent:
        () =>
        ({ commands, state }) => {
          const { level = 0 } = state.selection.$anchor.parent.attrs || {}
          return commands.updateAttributes(
            state.selection.$anchor.parent.type.name,
            {
              level: level + 1,
            }
          )
        },
      decreaseIndent:
        () =>
        ({ commands, state }) => {
          const { level = 0 } = state.selection.$anchor.parent.attrs || {}
          return commands.updateAttributes(
            state.selection.$anchor.parent.type.name,
            {
              level: Math.max(level - 1, 0),
            }
          )
        },
    }
  },
})

export default IndentExtension

import { observer } from 'mobx-react-lite'
import env from './../../../../environments/environment'
import React, { useState, useEffect } from 'react'
import {
  MDBCollapse,
  MDBFileInput,
  MDBBtn,
  MDBSelect,
  MDBInput,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBSwitch,
} from 'mdbreact'
import CarrierMgmtStore from './../../CarrierMgmt.store'
import { b64toBlob } from './../../../../shared/utilities/b64toBlob.function'
import CarrierService from './../../../../shared/services/Carrier.service'
import UserService from '../../../../shared/services/User.service'
import ConfirmDeleteModalComponent from '../../../../components/adminShared/ConfirmDeleteModal/ConfirmDeleteModal.component'

import './CarrierDetailsForm.scss'

const CarrierDetailsForm = ({ table }) => {
  const [activeModal, setActiveModal] = useState(false)
  const [uplineOptionsOnboarding, setUplineOptionsOnboarding] = useState([])
  const [uplineOptionsContracting, setUplineOptionsContracting] = useState([])

  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = async () => {
    const users = await UserService.search({
      pagination: false,
      order_by: { u_fname: 'ASC' },
    })

    setUplineOptionsOnboarding(
      users.models
        ?.filter((user) => ![111, 133, 223, 224].includes(user.usertype_id))
        .map((user) => ({
          text: user.u_fname + ' ' + user.u_lname,
          value: `${user.id}`,
          checked: !!(
            CarrierMgmtStore.carrier?.visibility?.onboarding?.upline_ids
              ?.length >= 0 &&
            CarrierMgmtStore.carrier?.visibility?.onboarding?.upline_ids?.includes(
              `${user.id}`
            )
          ),
        }))
    )

    setUplineOptionsContracting(
      users.models
        ?.filter((user) => ![111, 133, 223, 224].includes(user.usertype_id))
        .map((user) => ({
          text: user.u_fname + ' ' + user.u_lname,
          value: `${user.id}`,
          checked: !!(
            CarrierMgmtStore.carrier?.visibility?.contracting?.upline_ids
              ?.length >= 0 &&
            CarrierMgmtStore.carrier?.visibility?.contracting?.upline_ids.includes(
              `${user.id}`
            )
          ),
        }))
    )
  }

  const onChange = (event) => {
    if (
      `${CarrierMgmtStore.carrier[event.target.name]}` !==
      `${event.target.value}`
    )
      CarrierMgmtStore.carrier[event.target.name] = event.target.value
  }

  const activeCarrier = (active) => {
    setActiveModal(false)
    active
      ? (CarrierMgmtStore.carrier.c_active = 0)
      : (CarrierMgmtStore.carrier.c_active = 1)
  }

  const onSelectFile = (file, source) => {
    const carrier = CarrierMgmtStore.getCarrier()
    if (!carrier?.id) return

    const reader = new FileReader()

    reader.addEventListener('load', async () => {
      carrier[`${source}_src`] = { filename: file?.name, data: reader.result }

      // Extract content-type from complete base64 payload.
      let match = carrier[`${source}_src`].data.split(',')[0].match(/\w+\/\w+/)
      match = match && match.shift()

      // Extract raw base64 data w/out content-type.
      let base64 = carrier[`${source}_src`].data.split(',')[1]

      // Build payload to send file to the server.
      const formData = new FormData()
      formData.append(source, b64toBlob(base64, match))
      formData.append(`${source}_filename`, file.name)
      formData.append(`${source}_id`, carrier.id)

      try {
        await CarrierService.uploadLogo(CarrierMgmtStore.carrier?.id, formData)
        CarrierService.get(carrier.id).then(
          (res) => (CarrierMgmtStore.carrier.c_img = res && res?.c_img)
        )
      } catch (ex) {
        console.error('Failed to upload: ', ex)
      }
    })

    reader.readAsDataURL(file)
  }

  const onSubmit = (event) => {
    event.preventDefault()
    CarrierMgmtStore.saveCarrier()
  }

  const deleteLogoImg = async () => {
    await CarrierMgmtStore.saveCarrier({
      id: CarrierMgmtStore.carrier.id,
      c_img: '',
    })
    CarrierMgmtStore.carrier.c_img = null
  }

  const renderFileUploader = () => {
    let carrier = CarrierMgmtStore.getCarrier(),
      cImgPath = carrier && carrier?.c_img ? `${carrier?.c_img}`.trim() : false

    return (
      <div className="img-wrapper">
        <small>Carrier Logo</small>
        {cImgPath ? (
          <>
            <div onClick={deleteLogoImg} className="delete-btn">
              <MDBIcon icon="trash" className="remove" />
            </div>
            <div className="img">
              <img
                src={`https://firebasestorage.googleapis.com/v0/b/${
                  env.integrations.firebase.config.storageBucket
                }/o/${encodeURIComponent(cImgPath)}?alt=media`}
                alt="Carrier Logo"
              />
            </div>
          </>
        ) : (
          <></>
        )}
        <MDBFileInput
          disabled={!carrier?.id}
          name="c_img"
          accept="image/*"
          getValue={(value) => onSelectFile(value[0], 'c_img')}
        />
      </div>
    )
  }

  const renderLoadingWheel = () => {
    if (
      CarrierMgmtStore.getIsLoading('fetch.carrier') ||
      CarrierMgmtStore.getIsLoading('update.carrier')
    )
      return (
        <span>
          <i className="fa fa-spin fa-spinner" />
        </span>
      )
  }

  return (
    <MDBCard id="CarrierDetailsFormComponent">
      <div className="confirm-delete-button">
        {activeModal ? (
          <ConfirmDeleteModalComponent
            confirm={
              <>
                {CarrierMgmtStore.carrier?.c_name || ''}
                <br />
                {CarrierMgmtStore.carrier?.c_description || ''}
              </>
            }
            onCancel={() => activeCarrier(false)}
            onConfirm={() => activeCarrier(true)}
            isDeactivate={true}
          />
        ) : (
          <></>
        )}
      </div>
      <MDBCardHeader>
        Carrier Profile
        {renderLoadingWheel()}
      </MDBCardHeader>
      <MDBCardBody>
        <form noValidate onSubmit={onSubmit}>
          <MDBRow>
            <MDBCol size="12">
              <MDBInput
                label="Carrier Name"
                type="text"
                name="c_name"
                value={CarrierMgmtStore.carrier?.c_name || ''}
                onChange={onChange}
                autoComplete="off"
              />
            </MDBCol>
            <MDBCol size="12">
              <MDBInput
                label="Carrier Description"
                type="textarea"
                name="c_description"
                value={CarrierMgmtStore.carrier?.c_description || ''}
                onChange={onChange}
              />
            </MDBCol>
            <MDBCol size="4">
              <MDBSelect
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => ({
                  text: `${i}`,
                  value: `${i}`,
                  checked:
                    `${i}` === `${CarrierMgmtStore.carrier?.c_term_length}`,
                }))}
                label="Default Term Length"
                name="c_term_length"
                value={CarrierMgmtStore.carrier?.c_term_length}
                getValue={(e) => {
                  if (
                    typeof (e = e.shift()) !== 'undefined' &&
                    `${CarrierMgmtStore.carrier?.c_term_length}` !== `${e}`
                  )
                    CarrierMgmtStore.carrier.c_term_length = e
                }}
              />
            </MDBCol>
            <MDBCol size="4">
              <MDBSelect
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => ({
                  text: `${i}`,
                  value: `${i}`,
                  checked:
                    `${i}` === `${CarrierMgmtStore.carrier?.c_term_length_min}`,
                }))}
                label="Min Term Length"
                name="c_term_length_min"
                value={CarrierMgmtStore.carrier?.c_term_length_min}
                getValue={(e) => {
                  if (
                    typeof (e = e.shift()) !== 'undefined' &&
                    `${CarrierMgmtStore.carrier?.c_term_length_min}` !== `${e}`
                  )
                    CarrierMgmtStore.carrier.c_term_length_min = e
                }}
              />
            </MDBCol>
            <MDBCol size="4">
              <MDBSelect
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => ({
                  text: `${i}`,
                  value: `${i}`,
                  checked:
                    `${i}` === `${CarrierMgmtStore.carrier?.c_term_length_max}`,
                }))}
                label="Max Term Length"
                name="c_term_length_max"
                value={CarrierMgmtStore.carrier?.c_term_length_max}
                getValue={(e) => {
                  if (
                    typeof (e = e.shift()) !== 'undefined' &&
                    `${CarrierMgmtStore.carrier?.c_term_length_max}` !== `${e}`
                  )
                    CarrierMgmtStore.carrier.c_term_length_max = e
                }}
              />
            </MDBCol>
            <MDBCol size="12">
              <MDBInput
                label="Carrier Website"
                type="text"
                name="c_website"
                value={CarrierMgmtStore.carrier?.c_website || ''}
                onChange={onChange}
                autoComplete="off"
              />
            </MDBCol>
            <MDBCol
              size="12"
              className={
                CarrierMgmtStore.carrier?.fmo_id > 0 ? 'carrier-selected' : ''
              }
            >
              <MDBSelect
                options={CarrierMgmtStore.fmos.map((i) => ({
                  text: `${i.fmo_name}`,
                  value: `${i.id}`,
                  checked: `${i.id}` === `${CarrierMgmtStore.carrier?.fmo_id}`,
                }))}
                label="Select Carrier FMO"
                name="fmo_id"
                value={CarrierMgmtStore.carrier?.fmo_id}
                getValue={(e) => {
                  if (
                    typeof (e = e.shift()) !== 'undefined' &&
                    `${CarrierMgmtStore.carrier?.fmo_id}` !== `${e}`
                  )
                    CarrierMgmtStore.carrier.fmo_id = e
                }}
              />
              <div className="fmo-remove-btn">
                <button
                  onClick={() => (CarrierMgmtStore.carrier.fmo_id = null)}
                >
                  <MDBIcon icon="trash" className="remove red-text" />
                </button>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="12">
              <MDBCollapse isOpen={!!(CarrierMgmtStore.getCarrier()?.id > 0)}>
                {renderFileUploader()}
              </MDBCollapse>
            </MDBCol>
          </MDBRow>
          <MDBRow className="pb-1 border-bottom">
            <MDBCol size="12" className="pb-2">
              <small>Email attachments</small>
            </MDBCol>
            <MDBCol size="12" sm="6" lg="4" className="carrier-detail-toggle">
              <MDBSwitch
                labelLeft={'Send PDFs'}
                labelRight={''}
                name="can_email_attachment"
                checked={CarrierMgmtStore.carrier?.can_email_attachment}
                onChange={(event) =>
                  (CarrierMgmtStore.carrier.can_email_attachment =
                    CarrierMgmtStore.carrier?.can_email_attachment === 1
                      ? 0
                      : 1)
                }
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="pb-1 border-bottom">
            <MDBCol size="12" className="pb-2">
              <small>Carrier Visibility</small>
            </MDBCol>
            <MDBCol size="12" sm="6" lg="4" className="carrier-detail-toggle">
              <MDBSwitch
                labelLeft={<>Tracker:</>}
                labelRight={''}
                name="tracker"
                checked={CarrierMgmtStore.carrier?.visibility?.tracker?.enable}
                onChange={(event) =>
                  (CarrierMgmtStore.carrier.visibility.tracker.enable =
                    CarrierMgmtStore.carrier?.visibility?.tracker?.enable
                      ? false
                      : true)
                }
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="pt-3">
            <MDBCol size="12" sm="6" lg="4" className="carrier-detail-toggle">
              <MDBSwitch
                labelLeft={<>Onboarding:</>}
                labelRight={''}
                name="onboarding"
                checked={
                  CarrierMgmtStore.carrier?.visibility?.onboarding?.enable
                }
                onChange={(event) => {
                  CarrierMgmtStore.carrier.visibility.onboarding.enable =
                    CarrierMgmtStore.carrier?.visibility?.onboarding?.enable
                      ? false
                      : true
                }}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="pb-1 border-bottom">
            {CarrierMgmtStore.carrier?.visibility?.onboarding?.enable && (
              <>
                <MDBCol
                  size="12"
                  sm="6"
                  lg="4"
                  className="carrier-detail-toggle"
                >
                  <MDBSwitch
                    labelLeft={<>CSR:</>}
                    labelRight={''}
                    name="requires_aml"
                    checked={
                      CarrierMgmtStore.carrier?.visibility?.onboarding
                        ?.csr_agent
                    }
                    onChange={(event) =>
                      (CarrierMgmtStore.carrier.visibility.onboarding.csr_agent =
                        CarrierMgmtStore.carrier?.visibility?.onboarding
                          ?.csr_agent
                          ? false
                          : true)
                    }
                  />
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  lg="4"
                  className="carrier-detail-toggle"
                >
                  <MDBSwitch
                    labelLeft={<>Signature:</>}
                    labelRight={''}
                    name="requires_aml"
                    checked={
                      CarrierMgmtStore.carrier?.visibility?.onboarding
                        ?.sig_agent
                    }
                    onChange={(event) =>
                      (CarrierMgmtStore.carrier.visibility.onboarding.sig_agent =
                        CarrierMgmtStore.carrier?.visibility?.onboarding
                          ?.sig_agent
                          ? false
                          : true)
                    }
                  />
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  lg="4"
                  className="carrier-detail-toggle"
                >
                  <MDBSwitch
                    labelLeft={<>Affiliate:</>}
                    labelRight={''}
                    name="requires_aml"
                    checked={
                      CarrierMgmtStore.carrier?.visibility?.onboarding
                        ?.affiliate_agent
                    }
                    onChange={(event) =>
                      (CarrierMgmtStore.carrier.visibility.onboarding.affiliate_agent =
                        CarrierMgmtStore.carrier?.visibility?.onboarding
                          ?.affiliate_agent
                          ? false
                          : true)
                    }
                  />
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  lg="4"
                  className="carrier-detail-toggle"
                >
                  <MDBSwitch
                    labelLeft={<>Associate:</>}
                    labelRight={''}
                    name="requires_aml"
                    checked={
                      CarrierMgmtStore.carrier?.visibility?.onboarding
                        ?.associate_agent
                    }
                    onChange={(event) =>
                      (CarrierMgmtStore.carrier.visibility.onboarding.associate_agent =
                        CarrierMgmtStore.carrier?.visibility?.onboarding
                          ?.associate_agent
                          ? false
                          : true)
                    }
                  />
                </MDBCol>
              </>
            )}
            <MDBCol size="12">
              <MDBSelect
                search
                multiple
                selectAll
                options={uplineOptionsOnboarding}
                value={
                  CarrierMgmtStore.carrier?.visibility?.onboarding
                    ?.upline_ids || 'Choose Upline Leader'
                }
                getValue={(value) => {
                  CarrierMgmtStore.carrier.visibility.onboarding.upline_ids =
                    value.length > 0 ? value : []
                }}
                label="Disabled Uplines"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="pt-3">
            <MDBCol size="12" sm="6" lg="4" className="carrier-detail-toggle">
              <MDBSwitch
                labelLeft={<>Contracting:</>}
                labelRight={''}
                name="requires_aml"
                checked={
                  CarrierMgmtStore.carrier?.visibility?.contracting?.enable
                }
                onChange={(event) => {
                  CarrierMgmtStore.carrier.visibility.contracting.enable =
                    CarrierMgmtStore.carrier?.visibility?.contracting?.enable
                      ? false
                      : true
                }}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="pb-1 border-bottom">
            {CarrierMgmtStore.carrier?.visibility?.contracting?.enable && (
              <>
                <MDBCol
                  size="12"
                  sm="6"
                  lg="4"
                  className="carrier-detail-toggle"
                >
                  <MDBSwitch
                    labelLeft={<>CSR:</>}
                    labelRight={''}
                    name="requires_aml"
                    checked={
                      CarrierMgmtStore.carrier?.visibility?.contracting
                        ?.csr_agent
                    }
                    onChange={(event) =>
                      (CarrierMgmtStore.carrier.visibility.contracting.csr_agent =
                        CarrierMgmtStore.carrier?.visibility?.contracting
                          ?.csr_agent
                          ? false
                          : true)
                    }
                  />
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  lg="4"
                  className="carrier-detail-toggle"
                >
                  <MDBSwitch
                    labelLeft={<>Signature:</>}
                    labelRight={''}
                    name="requires_aml"
                    checked={
                      CarrierMgmtStore.carrier?.visibility?.contracting
                        ?.sig_agent
                    }
                    onChange={(event) =>
                      (CarrierMgmtStore.carrier.visibility.contracting.sig_agent =
                        CarrierMgmtStore.carrier?.visibility?.contracting
                          ?.sig_agent
                          ? false
                          : true)
                    }
                  />
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  lg="4"
                  className="carrier-detail-toggle"
                >
                  <MDBSwitch
                    labelLeft={<>Affiliate:</>}
                    labelRight={''}
                    name="requires_aml"
                    checked={
                      CarrierMgmtStore.carrier?.visibility?.contracting
                        ?.affiliate_agent
                    }
                    onChange={(event) =>
                      (CarrierMgmtStore.carrier.visibility.contracting.affiliate_agent =
                        CarrierMgmtStore.carrier?.visibility?.contracting
                          ?.affiliate_agent
                          ? false
                          : true)
                    }
                  />
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  lg="4"
                  className="carrier-detail-toggle"
                >
                  <MDBSwitch
                    labelLeft={<>Associate:</>}
                    labelRight={''}
                    name="requires_aml"
                    checked={
                      CarrierMgmtStore.carrier?.visibility?.contracting
                        ?.associate_agent
                    }
                    onChange={(event) =>
                      (CarrierMgmtStore.carrier.visibility.contracting.associate_agent =
                        CarrierMgmtStore.carrier?.visibility?.contracting
                          ?.associate_agent
                          ? false
                          : true)
                    }
                  />
                </MDBCol>
              </>
            )}
            <MDBCol size="12">
              <MDBSelect
                search
                multiple
                selectAll
                options={uplineOptionsContracting}
                value={
                  CarrierMgmtStore.carrier?.visibility?.contracting
                    ?.upline_ids || 'Choose Upline Leader'
                }
                getValue={(value) => {
                  CarrierMgmtStore.carrier.visibility.contracting.upline_ids =
                    value.length > 0 ? value : []
                }}
                label="Disabled Uplines"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="pt-3">
            <MDBCol size="12" sm="6" lg="4" className="carrier-detail-toggle">
              <MDBSwitch
                labelLeft={
                  <>
                    Requires
                    <br />
                    MedAdv?
                  </>
                }
                labelRight={''}
                name="requires_med_adv"
                checked={
                  `${CarrierMgmtStore.carrier?.requires_med_adv}` === '1'
                    ? true
                    : false
                }
                onChange={(event) =>
                  (CarrierMgmtStore.carrier.requires_med_adv =
                    `${CarrierMgmtStore.carrier?.requires_med_adv}` === '1'
                      ? 0
                      : 1)
                }
              />
            </MDBCol>
            <MDBCol size="12" sm="6" lg="4" className="carrier-detail-toggle">
              <MDBSwitch
                labelLeft={
                  <>
                    Requires
                    <br />
                    FFM?
                  </>
                }
                labelRight={''}
                name="requires_ffm"
                checked={
                  `${CarrierMgmtStore.carrier?.requires_ffm}` === '1'
                    ? true
                    : false
                }
                onChange={(event) =>
                  (CarrierMgmtStore.carrier.requires_ffm =
                    `${CarrierMgmtStore.carrier?.requires_ffm}` === '1' ? 0 : 1)
                }
              />
            </MDBCol>
            <MDBCol size="12" sm="6" lg="4" className="carrier-detail-toggle">
              <MDBSwitch
                labelLeft={
                  <>
                    Requires
                    <br />
                    AML?
                  </>
                }
                labelRight={''}
                name="requires_aml"
                checked={
                  `${CarrierMgmtStore.carrier?.requires_aml}` === '1'
                    ? true
                    : false
                }
                onChange={(event) =>
                  (CarrierMgmtStore.carrier.requires_aml =
                    `${CarrierMgmtStore.carrier?.requires_aml}` === '1' ? 0 : 1)
                }
              />
            </MDBCol>
            <MDBCol size="12" sm="6" lg="4" className="carrier-detail-toggle">
              <MDBSwitch
                labelLeft={
                  <>
                    Carrier
                    <br />
                    Is Active?
                  </>
                }
                labelRight={''}
                name="c_active"
                checked={
                  `${CarrierMgmtStore.carrier?.c_active}` === '1' ? true : false
                }
                onChange={(event) =>
                  `${CarrierMgmtStore.carrier?.c_active}` === '1'
                    ? setActiveModal(!activeModal)
                    : activeCarrier(false)
                }
              />
            </MDBCol>
            <MDBCol size="12" sm="6" lg="4" className="carrier-detail-toggle">
              <MDBSwitch
                labelLeft={
                  <>
                    Carrier
                    <br />
                    Is Automated?
                  </>
                }
                labelRight={''}
                name="is_automated"
                checked={
                  `${CarrierMgmtStore.carrier?.is_automated}` === '1'
                    ? true
                    : false
                }
                onChange={(event) =>
                  (CarrierMgmtStore.carrier.is_automated =
                    `${CarrierMgmtStore.carrier?.is_automated}` === '1' ? 0 : 1)
                }
              />
            </MDBCol>
          </MDBRow>
          {`${CarrierMgmtStore.carrier?.is_automated}` === '1' ? (
            <MDBRow>
              <MDBCol size="12">
                <MDBInput
                  label="Contracting Email Address"
                  type="text"
                  name="contract_email"
                  value={CarrierMgmtStore.carrier?.contract_email || ''}
                  onChange={onChange}
                  autoComplete="off"
                  required
                />
              </MDBCol>
            </MDBRow>
          ) : (
            <></>
          )}
          <MDBRow>
            <MDBCol size="12">
              <MDBBtn
                className="btn-block"
                type="submit"
                disabled={
                  !CarrierMgmtStore.carrier?.c_name ||
                  (!CarrierMgmtStore.carrier?.contract_email &&
                    `${CarrierMgmtStore.carrier?.is_automated}` === '1')
                }
              >
                {CarrierMgmtStore.getIsLoading('update.carrier-details') ? (
                  <span>
                    Saving...&nbsp;
                    <i className="fa fa-spin fa-spinner" />
                  </span>
                ) : (
                  <>
                    Save Carrier&nbsp;
                    <MDBIcon icon="save" />
                  </>
                )}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBCardBody>
    </MDBCard>
  )
}

export default observer(CarrierDetailsForm)

export const commissionLevels = {
  agent: 'USABG205',
  benefitsAdvisor: 'USABG10',
  benefitsCounselor: 'USABG20',
  district: 'USABG305',
  division: 'USABG505',
  employee: '00',
  fmo: 'USABG1000',
  referralPartner: 'RP100',
  region: 'USABG405',
  seniorBenefitsCounselor: 'USABG30',
}

export const carrierCommisionLevels = {
  USABG505: 'code_usabg505_id',
  USABG405: 'code_usabg405_id',
  USABG305: 'code_usabg305_id',
  USABG205: 'code_usabg205_id',
  USABG30: 'code_usabg30_id',
  USABG20: 'code_usabg20_id',
  USABG10: 'code_usabg10_id',
}

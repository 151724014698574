import React, { useState, useEffect } from 'react'
import { makeAutoObservable, toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import CarrierManagement from './CarrierManagement/CarrierManagement.component'
import AccessManagement from './AccessManagement/AccessManagement.component'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import UserService from './../../../../shared/services/User.service'

import './AgentRequirements.component.scss'

const REQUIREMENTS = {
  'contracts-by-usertype': {
    label: 'Contacts completed by usertype',
    description:
      'Contracting requirement that checks for the completeness of 1 or more carrier contracts, based on usertype, and only checking with CORE or PREFERRED carriers.<br />Affiliate Agent: 1<br />Associate Agent: 5<br />Signature Agent: 1',
  },
  'affiliate-requested-contracts': {
    label: 'Affiliate requested contracts',
    description:
      'Contracting requirement that confirms the agent has requested the minimum number of contract for the Affiliate usertype (1 Contract).',
  },
  'affiliate-completed-contracts': {
    label: 'Affiliate completed contracts',
    description:
      'Contracting requirement that confirms the agent has completed the minimum number of contract for the Affiliate usertype (1 Contract).',
  },
  'associate-requested-contracts': {
    label: 'Associate requested contracts',
    description:
      'Contracting requirement that confirms the agent has requested the minimum number of contract for the Associate usertype (5 Contract).',
  },
  'associate-completed-contracts': {
    label: 'Associate completed contracts',
    description:
      'Contracting requirement that confirms the agent has completed the minimum number of contract for the Associate usertype (5 Contract).',
  },
  'sig-agent-requested-contracts': {
    label: 'Signature Agent requested contracts',
    description:
      'Contracting requirement that confirms the agent has requested the minimum number of contract for the Signature Agent usertype (8 Contract).',
  },
  'sig-agent-completed-contracts': {
    label: 'Signature Agent completed contracts',
    description:
      'Contracting requirement that confirms the agent has completed the minimum number of contract for the Signature Agent usertype (1 Contract).',
  },
  'sig-agent-training': {
    label: 'Signature agent training',
    description:
      'Training requirement to ensure that the agent has watched and submitted training codes for all signature agent training videos at onboarding.',
  },
  'sig-agent-kpis': {
    label: 'Signature agent KPIs',
    description:
      'KPI & performance requirement that ensures Signature Agents complete their KPI expections and goals, for the current year.',
  },
  'sig-agent-terms': {
    label: 'Signature agent terms',
    description: 'Terms acceptance requirements for Signature Agent terms.',
  },
  'sales-guidelines': {
    label: 'Sales guidelines acceptance',
    description:
      'Performance requirement that indicates if the agent has successfully reviewed & accepted the sales tracker sales guidelines.',
  },
  'leader-terms': {
    label: 'Leader Terms',
    description: 'Terms acceptance requirements for leaders.',
  },
  'leader-kpis': {
    label: 'Leader KPIs',
    description:
      'KPI & performance requirement that ensures Leaders complete their KPI expections and goal cards, for the current year.',
  },
  'leader-trainings': {
    label: 'Leader trainings',
    description:
      'Training requirement to ensure that the leader has watched and submitted training codes for all required leader training videos.',
  },
  'sig-agent-group': {
    label: 'Signature Agent requirements',
    description:
      'Group requirement for Signature Agents: Completed contract, onboarding training, kpis & terms.',
  },
  'leader-group': {
    label: 'Leader requirements',
    description:
      'Group requirement for Leaders: leader training, kpis & terms.',
  },
  'has-w9': {
    label: 'Agent W9 on record?',
    description:
      'Indicates if the agent has a W9 on record for use with wallets & contracting.',
  },
  'has-direct-deposit': {
    label: 'Agent direct deposit on record?',
    description:
      'Indicates if the agent has a direct deposit form on record for use with wallets & contracting.',
  },
  'pip-commission-level': {
    label: 'Commission PIP agent requirement',
    description:
      'Requirement that indicates if the agent has reached their required PIP level for their corresponding commission level, activating Rymax & Cash Withdrawl options.',
  },
  'pip-team-commission-level': {
    label: 'Commission PIP team requirement',
    description:
      "Requirement that indicates if the angent's team has reached their required PIP level for their corresponding commission level, activating Rymax & Cash Withdrawl options.",
  },
}

class AgentRequirementsStore {
  userId = null
  requirements = []
  fetching = false

  constructor() {
    makeAutoObservable(this)
  }

  clearRequirements = () => {
    this.requirements = []
  }

  fetchRequirements = async (userId) => {
    if (userId) {
      this.fetching = true
      this.requirements = await UserService.hasMetRequirements(userId, {
        requirements: Object.keys(REQUIREMENTS),
        individual: true,
      })
      this.fetching = false
    } else {
      this.clearRequirements()
    }
  }
}

const Store = new AgentRequirementsStore()

const AgentRequirements = ({ userId }) => {
  const { fetchRequirements, fetching, requirements } = Store

  useEffect(() => {
    fetchRequirements(userId)
  }, [userId])

  return (
    <MDBContainer id="AgentRequirementsComponent">
      <MDBRow>
        <MDBCol size="12">
          <ul>
            {fetching ? (
              <li className="loader">
                <i className="fa fa-spin fa-spinner"></i>&nbsp;Loading
                Requirements...
              </li>
            ) : (
              requirements.map((requirement, idx) => {
                const key = Object.keys(requirement || {}).shift(),
                  val = Object.values(requirement || {}).shift()
                return (
                  <li key={`${idx}-${key}`}>
                    <div>
                      <div>
                        {REQUIREMENTS[key].label} <span>({key})</span>
                      </div>
                      <div>
                        <div>
                          <div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: REQUIREMENTS[key].description,
                              }}
                            />
                          </div>
                        </div>
                        <div className={val ? 'pass' : 'fail'}>
                          {val ? 'Yes' : 'No'}
                        </div>
                      </div>
                    </div>
                  </li>
                )
              })
            )}
          </ul>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(AgentRequirements)

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from 'mdbreact'
import LoadingSpinner from '../../shared/LoadingSpinner.component'
import Telephone from '../../shared/Telephone/Telephone.component'
import CarrierContactService from '../../../shared/services/CarrierContact.service'
import CarrierFmoService from '../../../shared/services/CarrierFmo.service'
import { toast } from 'react-toastify'

import './AgentContractGuideModal.component.scss'

const columns = [
  { label: 'Name', field: 'name' },
  { label: 'Email', field: 'email' },
  { label: 'Phone Number', field: 'phone' },
]

const AgentContractGuideModal = ({ 
  isOpen,
  toggleModal,
  carrier,
  contract,
}) => {
  const [carrierContacts, setCarrierContacts] = useState([]);
  const [carrierContractInstruction, setCarrierContractInstruction] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchCarrierContactInfo = async () => {
    return new Promise((resolve, reject) => {
      CarrierContactService.search({
        search: { carrier_id: carrier?.get('id') },
        pagination: false,
      })
        .then((carrierContactInfos) => resolve(carrierContactInfos && carrierContactInfos?.models))
        .catch((error) => {
          throw error
        })
    })
  }

  const fetchCarrierContractInstructionByFmo = async () => {
    return new Promise((resolve, reject) => {
      CarrierFmoService.get(carrier?.get('fmo_id'))
        .then((carrierContractInstructionFmo) => 
          resolve(carrierContractInstructionFmo && carrierContractInstructionFmo?.contracting_instructions)
        )
        .catch((error) => {
          throw error
        })
    })
  }

  useEffect(() => {
    setIsLoading(true);

    Promise.all([
      fetchCarrierContactInfo(),
      fetchCarrierContractInstructionByFmo(),
    ])
      .then((response) => {
        const [ contactInfos, contractInstruction ] = response;
        setCarrierContacts(contactInfos?.map((infos) => {
          return {
            name: infos?.name,
            email: infos?.email,
            phone: <Telephone phoneNumber={infos?.phone}/>
          }
        }) || []);
        setCarrierContractInstruction(contractInstruction || carrier?.get('contracting_instructions'));
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error('Failed to get carrier contacts and instructions', { position: toast.POSITION.TOP_RIGHT });
        setIsLoading(false);
      })
  
  }, [carrier, contract])

  return (
    <MDBModal
      id="AgentContractGuideModalComponent"
      isOpen={!!isOpen}
      toggle={toggleModal}
      className="contract-guide-modal"
    >
      <MDBModalHeader toggle={toggleModal}>
        {carrier?.get('c_name')} Contract Guide
      </MDBModalHeader>
      <MDBModalBody>
        <>
          {
            isLoading ? (
              <LoadingSpinner
                size="md"
                isActive={isLoading}
                label={'Getting Carrier Contacts and Instructions'}
                className='loading-spinner'
              />
            ) : (
              <MDBContainer>
                <MDBRow>
                  <MDBCol className='contacts-title' size='12'>Carrier Contacts</MDBCol>
                </MDBRow>

                <MDBRow>
                  <MDBCol size='12' className='contacts-table-container'>
                    <MDBTable
                      striped
                      bordered
                      small
                      className='text-capitalize text-truncate'
                    >
                      <MDBTableHead
                        columns={columns}
                      ></MDBTableHead>
                      <MDBTableBody
                        rows={carrierContacts}
                      ></MDBTableBody>
                    </MDBTable>
                  </MDBCol>
                </MDBRow>
                
                <MDBRow>
                  <MDBCol size='12' className='contracting-instruction-title'>
                    Before you get started
                  </MDBCol>
                  {
                    carrierContractInstruction ? (
                      <MDBCol size='12' className='contracting-instruction'>
                        {carrierContractInstruction}
                      </MDBCol>
                    ) : (
                      <MDBCol size='12' className='contacts-not-found'>
                        No Instruction
                      </MDBCol>
                    )
                  }
                </MDBRow>

                <MDBRow>
                  <MDBCol size='12' className='contracting-instruction-title'>After you’ve completed contracts</MDBCol>
                  {
                    carrier?.get('post_contracting_instructions') ? (
                      <MDBCol size='12' className='contracting-instruction'>
                        {carrier?.get('post_contracting_instructions')}
                      </MDBCol>
                    ) : (
                      <MDBCol size='12' className='contacts-not-found'>
                        No Instruction
                      </MDBCol>
                    )
                  }
                </MDBRow>
              </MDBContainer>
            )
          }
        </>
      </MDBModalBody>
    </MDBModal>
  )
}

export default observer(AgentContractGuideModal)

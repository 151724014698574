import React, { Component } from 'react'
import {
  MDBBtn,
  MDBContainer,
  MDBDataTable,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from 'mdbreact'
import DashboardLayout from '../components/Admin/DashboardLayout'
import AdminApi from '../api/admin-api/admin-api'
import { toast } from 'react-toastify'
import { phoneToView } from './../shared/formatters/telephone.formatter'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import Telephone from '../components/shared/Telephone/Telephone.component'

class AdminDashboardQuotesPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      toggleStateA: false,
      modal: false,
      viewdata: '',
      status: 'Open',
      quoteList: [],
      data: {
        columns: [
          {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Phone',
            field: 'phone',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Submitted',
            field: 'submitted',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'View',
            field: 'view',
            sort: 'disabled',
            width: 270,
          },
        ],
        rows: [],
      },
    }
  }

  componentDidMount() {
    this.fetchQuoteData()
  }

  handleToggleClickA = () => {
    this.setState({
      toggleStateA: !this.state.toggleStateA,
    })
  }

  toDateTime = (secs) => {
    let t = new Date(1970, 0, 1) // Epoch
    t.setSeconds(secs)
    return t
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal })
  }

  fetchQuoteData = async () => {
    const { status } = this.state
    this.setState({ loading: true })
    AdminApi.getQuoteRequest(status === 'Open')
      .then((result) => {
        this.setState({ quoteList: result.data.data || [] })
      })
      .then(() => {
        this.setState({ loading: false })
        this.setRows()
      })
      .catch((error) => {
        this.setState({ loading: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      })
  }

  setRows = () => {
    const { quoteList } = this.state
    if (quoteList && quoteList.length) {
      let rows = quoteList.map((row) => {
        const { email, created_at, firstname, mobile } = row

        const submittedDate = (
          <span
            searchvalue={
              created_at ? moment(created_at).format('YYYY/MM/DD') : ''
            }
          >
            {created_at ? moment(created_at).format('MM/DD/YYYY') : 'N/A'}
          </span>
        )
        const self = this

        function data() {
          self.toggle()
          self.setState({ viewdata: row })
        }

        const viewClick = (
          <div className={'d-flex align-items-center justify-content-center'}>
            <div
              className={'cursor-pointer d-inline-block'}
              role={'button'}
              onClick={data}
            >
              <i className={'fa fa-eye fa-2x blue-text p-1'}></i>
            </div>
          </div>
        )
        return {
          name: firstname ? `${firstname}` : 'N/A',
          email: email ? email : 'N/A',
          phone: mobile ? phoneToView(mobile) : 'N/A',
          submitted: submittedDate,
          view: viewClick,
        }
      })

      this.setState({
        data: { ...this.state.data, rows: rows },
      })
    }
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  onStatusChange = (e) => {
    e.persist()
    this.setState((prevState) => {
      let viewdata = Object.assign({}, prevState.viewdata)
      viewdata.status = e.target.value
      return { viewdata }
    })
  }

  onNotesChange = (e) => {
    e.persist()
    this.setState((prevState) => {
      let viewdata = Object.assign({}, prevState.viewdata)
      viewdata.notes = e.target.value
      return { viewdata }
    })
  }

  Update_quotes = async (event) => {
    event.preventDefault()
    this.setState({ updating: true })

    const { viewdata } = this.state
    var data = {
      notes: viewdata.notes,
      status: viewdata.status,
      id: viewdata.id,
      table_name: 'quote_requests',
    }

    let form_data = new FormData()

    for (let key in data) {
      form_data.append(key, data[key])
    }

    await AdminApi.updateQuoteRequest(form_data).then((response) => {
      if (response.status === 200) {
        this.setState({ updating: false })
        toast.success('Quotes Successfully Updated', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchQuoteData()
        this.toggle()
      } else {
        this.setState({ updating: false })
        toast.error(`Something went wrong!`, {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    })
  }

  DeleteQuote = async () => {
    this.setState({ deleting: true })
    const { viewdata } = this.state
    var data = {
      type: 'quote_requests',
      id: viewdata.id,
    }

    await AdminApi.deleteQuoteRequest(data).then((response) => {
      if (response.status === 200) {
        this.setState({ deleting: false })
        toast.success('Quotes Successfully Deleted', {
          position: toast.POSITION.TOP_RIGHT,
        })
        this.fetchQuoteData()
        this.toggle()
      } else {
        this.setState({ deleting: false })
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
    })
  }

  render() {
    toast.configure()
    const { modal, viewdata, data, status, loading, updating, deleting } =
      this.state

    return (
      <>
        <DashboardLayout>
          <main className="mainSection">
            <MDBContainer fluid className="mt-5">
              <h2>
                Quote Requests
                <div className="switch" style={{ float: 'right' }}>
                  <label style={{ fontSize: '16px' }}>
                    Closed Requests
                    <input
                      type="checkbox"
                      checked={status === 'Open'}
                      onChange={() => {
                        if (status === 'Open') {
                          this.setState({ status: 'Closed' }, () => {
                            this.fetchQuoteData()
                          })
                        } else {
                          this.setState({ status: 'Open' }, () => {
                            this.fetchQuoteData()
                          })
                        }
                      }}
                    />
                    <span className="lever"></span>
                    Open Requests
                  </label>
                </div>
              </h2>
              <hr />
              {loading ? (
                <h4 className={'p4'}>Loading...</h4>
              ) : (
                <div className="content-wrapper">
                  <MDBDataTable
                    sortRows={['submitted']}
                    striped
                    bordered
                    small
                    data={data}
                    className="text-capitalize"
                  />
                </div>
              )}
              <MDBModal isOpen={modal} toggle={this.toggle}>
                <MDBModalHeader toggle={this.toggle}>
                  Quote Request Info
                </MDBModalHeader>
                <MDBModalBody>
                  <form>
                    <div className="form-row">
                      <label>Status</label>
                      <select
                        className="form-control"
                        name="status"
                        value={this.state.viewdata.status}
                        onChange={(event) => this.onStatusChange(event)}
                      >
                        <option value={'Open'}>Open</option>
                        <option value={'Closed'}>Closed</option>
                      </select>
                    </div>
                    <div className="form-row">
                      <label>Notes</label>
                      <textarea
                        required
                        className="form-control"
                        name="notes"
                        value={
                          this.state.viewdata.notes
                            ? this.state.viewdata.notes
                            : ''
                        }
                        onChange={(event) => this.onNotesChange(event)}
                      />
                    </div>
                    <div className="form-row">
                      <MDBBtn color="default" onClick={this.Update_quotes}>
                        {updating ? 'Saving...' : 'Save'}
                      </MDBBtn>
                      <MDBBtn color="danger" onClick={this.DeleteQuote}>
                        {deleting ? 'Deleting...' : 'Delete Request'}
                      </MDBBtn>
                    </div>
                  </form>
                  Name : {viewdata.firstname}
                  <br /> Phone : <Telephone phoneNumber={viewdata.mobile} />
                  <br /> E-Mail :{viewdata.email}
                  <br /> Tobacco User: {viewdata.tobacco_user}
                  <br /> Age :{viewdata.age}
                  <br /> Level of protection : ${viewdata.protection_level}
                  <br /> Insurance Type : {viewdata.insurance_type}
                  {/*<br/> Birithdate : {viewdata.birthdate}
                                    <br/> Height: {viewdata.height}
                                    <br/> Married: {viewdata.marrital_status}
                                    <br/> Income Level:{viewdata.income_level}
                                    <br/> Gender: {viewdata.gender}
                                    <br/> Weight: {viewdata.weight}
                                    <br/> Looking for: {viewdata.looking_for}
                                    <br/> Need Coverage: {viewdata.coverage_required}
                                    <br/> Self-employed: {viewdata.self_employed}
                                    <br/> Children to be covered: {viewdata.children_coverage}
                                    <br/> Currently Pregnant: {viewdata.is_pregnant}
                                    <br/> Currently Covered: No
                                    <br/> Pre-existing Condition: NO*/}
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn color="secondary" onClick={this.toggle}>
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModal>
            </MDBContainer>
          </main>
        </DashboardLayout>
      </>
    )
  }
}

export default AdminDashboardQuotesPage

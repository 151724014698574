import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { toast } from 'react-toastify'
import SalesTrackerStore from './../../store'
import { MDBCollapse, MDBBtn, MDBCol, MDBAlert, MDBIcon } from 'mdbreact'
import { ClarityTag } from './../../../../core/tracking/Clarity.functions'
import SubmitSalesService from '../../shared/SubmitSales.service'
import ConfirmDeleteModalComponent from './../../../../components/adminShared/ConfirmDeleteModal/ConfirmDeleteModal.component'

import './PolicySubmitter.component.scss'

const PolicySubmitter = ({ goTo }) => {
  const [promptForDelete, setPromptForDelete] = useState(false)
  const canBeDeleted = () => SalesTrackerStore?.Policy?.isNew() === false
  const onSubmit = async () => {
    ClarityTag('sales-tracker', 'submission')
    let result = null
    try {
      result = await SalesTrackerStore.submit()
      SubmitSalesService.fetchRecentSales()
    } catch (ex) {}

    if (!result) {
      if (SalesTrackerStore.errMsgs?.length) {
        SalesTrackerStore.errMsgs.forEach((errMsg) =>
          toast.error(errMsg, { position: toast.POSITION.TOP_RIGHT })
        )
        SalesTrackerStore.errMsg = toJS(SalesTrackerStore.errMsgs).join(
          `<br />`
        )
      } else if (SalesTrackerStore.errMsg)
        return toast.error(SalesTrackerStore.errMsg, {
          position: toast.POSITION.TOP_RIGHT,
        })
      return
    } else if (result) {
      return toast.success('Great. Policy saved!', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  const onDeletePrompt = () => {}

  const isDisabled = () => {
    if (!SalesTrackerStore.clientType) return true

    if (
      SalesTrackerStore.clientType === 'group' &&
      !SalesTrackerStore.Policy.get('client_group_id')
    )
      return true

    return false
  }

  if (SalesTrackerStore.isOverDate) return

  return (
    <div id="PolicySubmitterComponent">
      <div className="row">
        <MDBCol size="12">
          <MDBCollapse isOpen={!!SalesTrackerStore.errMsgs?.length}>
            <MDBAlert color={'danger'}>
              <ul className="policy-errors-list">
                {SalesTrackerStore.errMsgs?.length &&
                  SalesTrackerStore.errMsgs.map((err, idx) => (
                    <li key={`sales-tracker-policy-err-${idx}`}>{err}</li>
                  ))}
              </ul>
            </MDBAlert>
          </MDBCollapse>
        </MDBCol>
      </div>
      <div className="form-row">
        <MDBCol size="12" lg={!SalesTrackerStore.Policy.isNew() ? '6' : '12'}>
          <MDBBtn
            disabled={
              SalesTrackerStore.isSubmitting ||
              SalesTrackerStore.isDeleting ||
              SalesTrackerStore.isFetching ||
              isDisabled()
            }
            color="indigo"
            type="button"
            className="btn-block mb-1"
            onClick={() => {
              onSubmit()
            }}
          >
            {SalesTrackerStore.isSubmitting ? 'Submitting...' : 'Submit Sale'}
          </MDBBtn>
        </MDBCol>
        <MDBCol size="12" lg="6">
          {!SalesTrackerStore.Policy.isNew() && (
            <MDBBtn
              disabled={
                SalesTrackerStore.isSubmitting ||
                SalesTrackerStore.isDeleting ||
                SalesTrackerStore.isFetching
              }
              color="info"
              type="button"
              className="btn-block mb-1"
              onClick={() => goTo()}
            >
              Reset Sale Form
            </MDBBtn>
          )}
        </MDBCol>
        {canBeDeleted() ? (
          <MDBCol size="12">
            <button
              onClick={() => setPromptForDelete(true)}
              className="delete-btn"
            >
              <MDBIcon icon="trash" />
              &nbsp;Delete policy(ies)
            </button>
          </MDBCol>
        ) : (
          <></>
        )}
      </div>
      {promptForDelete ? (
        <ConfirmDeleteModalComponent
          confirm={'Are you sure you want to delete ALL POLICIES?'}
          onConfirm={() => {
            SalesTrackerStore.deleteAllPolicies()
            setPromptForDelete(false)
          }}
          shouldEnable={true}
          onCancel={() => setPromptForDelete(false)}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default observer(PolicySubmitter)

import React, { useState } from 'react'
import CarrierManagement from './CarrierManagement/CarrierManagement.component'
import AccessManagement from './AccessManagement/AccessManagement.component'
import { MDBIcon } from 'mdbreact'

import './AgentOptions.component.scss' // Optional for styling

const AgentOptioItems = [
  {
    title: 'Carrier Options',
    content: <CarrierManagement />,
  },
  {
    title: 'Access Options',
    content: <AccessManagement />,
  },
]

const AgentOptions = () => {
  const [activeIndex, setActiveIndex] = useState(null)

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  return (
    <div id="AgentOptionsComponent" className="accordion">
      {AgentOptioItems.map((item, index) => (
        <div key={index} className="accordion-item">
          <div
            className={`accordion-header ${
              activeIndex === index ? 'active' : ''
            }`}
            onClick={() => handleToggle(index)}
          >
            <span>{item.title}</span>
            <span className="accordion-icon">
              {activeIndex === index ? (
                <MDBIcon fas icon="chevron-down" />
              ) : (
                <MDBIcon fas icon="chevron-up" />
              )}
            </span>
          </div>
          <div
            className={`accordion-body ${
              activeIndex === index ? 'expanded' : ''
            }`}
          >
            {item.content}
          </div>
        </div>
      ))}
    </div>
  )
}

export default AgentOptions

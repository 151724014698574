import React, { useEffect, useState } from 'react'
import { MDBCol, MDBRow, MDBSwitch } from 'mdbreact'
import UserProfileService from '../../../../../shared/services/UserProfile.service'
import UserService from '../../../../../shared/services/User.service'
import { toast } from 'react-toastify'

import './AccessManagement.component.scss'

const AccessManagement = () => {
  const [hasLeaderRequirements, setHasLeaderRequirements] = useState(
    parseInt(UserProfileService.get('has_leader_requirements'))
  )

  const toggleAccess = async (access_type) => {
    const prev_has_leader_requirement = parseInt(
      UserProfileService.get('has_leader_requirements')
    )

    UserProfileService.set(
      access_type,
      prev_has_leader_requirement === 1 ? 0 : 1
    )
    setHasLeaderRequirements(prev_has_leader_requirement === 1 ? 0 : 1)

    try {
      await UserService.update(UserProfileService.getUserId(), {
        has_leader_requirements: prev_has_leader_requirement === 1 ? 0 : 1,
      })
      toast.success(
        `${
          prev_has_leader_requirement === 1 ? 'Disabled' : 'Enabled'
        } leader requirements.`,
        { position: toast.POSITION.TOP_RIGHT }
      )
    } catch (error) {
      toast.error(
        `Failed to ${
          prev_has_leader_requirement === 1 ? 'disable' : 'enable'
        } leader requirements.`,
        { position: toast.POSITION.TOP_RIGHT }
      )
    }
  }

  const renderSwitch = () => {
    if (UserProfileService.isA('career-agent'))
      return (
        <div style={{ width: '120px' }}>
          <MDBSwitch
            labelLeft="No"
            labelRight="Yes"
            name={'has_leader_requirements'}
            checked={hasLeaderRequirements === 1}
            onChange={(event) => toggleAccess('has_leader_requirements')}
          />
        </div>
      )

    if (
      UserProfileService.isA([
        'division-leader',
        'region-leader',
        'district-leader',
      ])
    )
      return (
        <div>
          This agent's leader-type requires completion of leader requirements.
        </div>
      )

    return <div>Not applicable for this agent's usertype.</div>
  }

  return (
    <div id="AccessManagementComponent">
      <MDBRow>
        {(UserProfileService.isA('career-agent') || true) && (
          <>
            <MDBCol sm="6" md="8" lg="6">
              <div className="access-toggle-label">
                <div>Has Leader Requirements:</div>
              </div>
            </MDBCol>
            <MDBCol sm="6" md="4" lg="6" className="access-toggle">
              {renderSwitch()}
            </MDBCol>
          </>
        )}
      </MDBRow>
    </div>
  )
}

export default AccessManagement

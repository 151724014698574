import { makeAutoObservable, toJS } from 'mobx'
import UserAlertService from './../../../shared/services/UserAlert.service'
import StorageService from './../../../shared/services/Storage.service'
import UserService from './../../../shared/services/User.service'
import UserProfileService from './../../../shared/services/UserProfile.service'
import moment from 'moment'
import UserRequirementsModal from './../../modals/UserRequirementsModal'

const openRequiredModal = (User, requiredKeys, goTo) => {
  return UserRequirementsModal.open({ User, requiredKeys })
    .then(async (link) => (link && link?.link) || true)
    .catch((required) => {
      setSuppressedToLocal(User)
    })
}

// We want to prompt ALL leaders, except Affiliate Leaders
const isLeader = (User) =>
  User.isA('recruiter-group') &&
  !User.isA([
    'agency-owner',
    'system-admin',
    'affiliate-leader',
    'career-agent',
    'premier-agent',
  ])

const hasLeaderRequirementsForCareerAgents = (User) =>
  User.isA('career-agent') &&
  parseInt(UserProfileService.get('has_leader_requirements')) === 1

const fetchUserRequiredTrainings = async (User) => {
  try {
    return await User.hasCompletedTrainings()
  } catch (ex) {
    return {}
  }
}

const fetchUserRequiredTerms = async (User) => {
  try {
    return await User.hasCompletedTerms()
  } catch (ex) {
    return {}
  }
}

const fetchUserRequiredGoals = async (User) => {
  try {
    return await User.hasCompletedGoals()
  } catch (ex) {
    console.log('Failed to fetch goals ', ex)
    return {}
  }
}

const getRequirementFromRemote = async (User) => {
  let leader_trainings = false,
    leader_terms = false,
    leader_goals = false

  if (!(await fetchUserRequiredTrainings(User))?.leader?.current)
    leader_trainings = true

  if (!(await fetchUserRequiredTerms(User))?.leader) leader_terms = true

  if (!(await fetchUserRequiredGoals(User))?.leader) leader_goals = true

  return {
    leader_trainings,
    leader_terms,
    leader_goals,
  }
}

const getSuppressedFromLocal = (User) => {
  let data = {}
  try {
    data = JSON.parse(StorageService.get('user---required-alerts-suppressed'))
    data = !data || data === null ? {} : data
  } catch (ex) {}

  if (User) {
    if (data[User.id()]) return data[User.id()]
    return false
  }
  return false

  // if (data[UserProfileService.getUserId()])
  //   return data[UserProfileService.getUserId()];

  // return false;
}

const getRequirementFromLocal = (dateStr) => {
  let data = StorageService.get('user---required-alerts')
  if (isJson(data)) data = JSON.parse(data)

  if (
    data &&
    typeof data === 'object' &&
    data.hasOwnProperty(dateStr) &&
    data[dateStr] &&
    typeof data[dateStr] === 'object'
  )
    return data[dateStr]
}

const setSuppressedToLocal = (User) => {
  let data = getSuppressedFromLocal()
  if (!data) data = {}
  data[User.id()] = moment().format('YYYY-MM-DD')
  StorageService.set('user---required-alerts-suppressed', JSON.stringify(data))
}

const setRequirementToLocal = (dateStr, required) => {
  let stored = StorageService.get('user---required-alerts')
  stored = stored && isJson(stored) ? JSON.parse(stored) : {}
  stored[dateStr] = required
  StorageService.set('user---required-alerts', JSON.stringify(stored))
}

const isJson = (str) => {
  try {
    return typeof JSON.parse(str) === 'object'
  } catch (ex) {
    return false
  }
}

class UserAlertsStore {
  constructor() {
    makeAutoObservable(this)
  }

  _userId = null
  _isFetching = false
  User = null
  _alerts = []
  isReqModalOpen = false
  _todayStr = ''
  currPath = ''
  nextPath = ''
  _isReqModalChecking = false
  numberOfAlerts = 0

  setNumberOfAlerts(val) {
    this.numberOfAlerts = val
  }

  get = () => toJS(this._alerts)

  fetch = async (userId) => {
    try {
      let result = await UserAlertService.search({
        search: { user_id: userId },
        orderBy: { created_at: 'DESC' },
      })
      this._alerts = result && result?.models
    } catch (ex) {
      console.error('EX: ', ex)
    }

    return this.get()
  }

  count = (filter) => {
    switch (filter && `${filter}`.trim().toLowerCase()) {
      case 'unacknowledged':
        return this.get().filter((a) => !a.acknowledged_at).length
      default:
        return this.get().length
    }
  }

  open = async (required) => {
    this.isReqModalOpen = true
    this._isReqModalChecking = false
    const result = await openRequiredModal(
      this.User,
      Object.keys(required).filter((n) => required[n])
    )
    if (result && typeof result === 'string') this.nextPath = result
    this.isReqModalOpen = false
    return result
  }

  openIfPermitted = async (required) => {
    // if (UserProfileService.isAssumed()) return

    // // has the modal been supressed?
    // if (
    //   moment().diff(
    //     moment(getSuppressedFromLocal(this.User) || '2000-01-01'),
    //     'months'
    //   ) === 0
    // ) {
    //   this._isReqModalChecking = false
    //   return
    // }
    return await this.open(required)
  }

  checkRequirements = async (userId, currPath, force) => {
    // Do not proceed if Outstanding Requirements modal is open.
    // OR if the current session user is not logged into their
    // own account.
    // if (!force && UserProfileService.isAssumed()) return
    if (this.isReqModalOpen || this._isReqModalChecking) return
    // 1. Get user, confirm user is a leader.
    if (this._isFetching) return
    if (
      force ||
      !this.User ||
      (this.User && parseInt(this.User.id()) !== parseInt(userId))
    ) {
      this._isFetching = true
      this.User = await UserProfileService.instance()
      this._isFetching = false
    }

    // Add leader requirements filter for career agents
    if (
      this.User &&
      !force &&
      !(isLeader(this.User) || hasLeaderRequirementsForCareerAgents(this.User))
    ) {
      this._userId = this.User.id()
      return
    }

    // Prevent alert from opening for Affiliate & Associate leaders.
    if (UserProfileService.isA(['affiliate-leader', 'associate-leader'])) {
      this._isReqModalChecking = false
      return
    }

    // Start checking ....
    // Find out if we need to reload requirements.
    this._isReqModalChecking = true

    let changesExist = false
    // 2. has the user changed (ie: login to downline.)
    if (!this._userId || parseInt(this._userId) !== parseInt(this.User.id())) {
      StorageService.clear('user---required-alerts')
      this._userId = this.User.id()
      changesExist = true
    }

    // 3. has the date changed since we checked last?
    if (!this._todayStr || this._todayStr !== moment().format('YYYY-MM-DD')) {
      this._todayStr = moment().format('YYYY-MM-DD')
      changesExist = true
    }

    // 4. has the page changed since we last presented the modal to the user?
    if (this.currPath !== currPath && this.nextPath !== currPath) {
      this.currPath = currPath
      changesExist = true
    }

    // 5. If changes exist, fetch the latest
    // outstanding requirements.
    if (changesExist) {
      // 5a. Get latest requirement from local or remote storage.
      let required = getRequirementFromLocal(this._todayStr)
      if (!required) required = await getRequirementFromRemote(this.User)

      // 5b. Set to local storage for today.
      setRequirementToLocal(this._todayStr, required || null)
      // 5c. Act on requirements, if necc.
      if (required && Object.values(required).filter((n) => n).length) {
        return force
          ? await this.open(required)
          : await this.openIfPermitted(required)
      }
    }
    this._isReqModalChecking = false
    return
  }
}

export default new UserAlertsStore()

import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { getPhone, getEmail } from './../../formatters'
import { Link } from 'react-router-dom'
import { phoneToStorage } from './../../../../shared/formatters/telephone.formatter'
import Telephone from '../../../../components/shared/Telephone/Telephone.component'

import './AgentFooter.component.scss'

const AgentFooter = ({ subsiteData, goTo }) => {
  const onClick = (event, link) => {
    event.preventDefault()
    goTo(link)
    return false
  }

  return (
    <footer id="AgentFooterComponent">
      <MDBContainer>
        <MDBRow>
          <MDBCol size="12" md="4">
            <a href="index.html" className="brand brand-inverse">
              <h5>USABG</h5>
            </a>
            <p className="font---lato">
              Our friends and family are our clients, and our clients become our
              friends and family for life.
            </p>
          </MDBCol>
          <MDBCol size="12" md="4">
            <h5>Follow Us:</h5>
            <ul className="social-links">
              <li>
                <a
                  href={`https://www.facebook.com/${
                    subsiteData?.s_facebook_link || 'USABenefitsGroup'
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fa fa-facebook"
                >
                  {''}
                </a>
              </li>
              {subsiteData?.s_twitter_link ? (
                <li>
                  <a
                    href={`https://twitter.com/${subsiteData.s_twitter_link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fa fa-twitter"
                  >
                    {''}
                  </a>
                </li>
              ) : (
                <></>
              )}
              {subsiteData?.s_linkedin_link ? (
                <li>
                  <a
                    href={`https://www.linkedin.com/in/${subsiteData.s_linkedin_link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fa fa-linkedin"
                  >
                    {''}
                  </a>
                </li>
              ) : (
                <></>
              )}
              {subsiteData?.s_instagram_link ? (
                <li>
                  <a
                    href={`https://www.instagram.com/${subsiteData.s_instagram_link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fa fa-instagram"
                  >
                    {''}
                  </a>
                </li>
              ) : (
                <></>
              )}
            </ul>
            <h5>Quick Links:</h5>
            <ul className="quick-links">
              <li>
                <a
                  className="footer-link"
                  href={`/${subsiteData?.u_login}`}
                  onClick={(event) =>
                    onClick(event, `/${subsiteData?.u_login}`)
                  }
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  href={`/${subsiteData?.u_login}/contact`}
                  name="contact"
                  onClick={(event) =>
                    onClick(event, `/${subsiteData?.u_login}/contact`)
                  }
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  href={`/${subsiteData?.u_login}/products`}
                  name="products"
                  onClick={(event) =>
                    onClick(event, `/${subsiteData?.u_login}/products`)
                  }
                >
                  Products
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  href={`/login`}
                  name="login"
                  onClick={(event) => onClick(event, `/login`)}
                >
                  Login
                </a>
              </li>
            </ul>
          </MDBCol>
          <MDBCol size="12" md="4">
            <h5>Contact Us:</h5>
            {getPhone(subsiteData) ? (
              <div className="font---lato">
                <Telephone
                  phoneNumber={getPhone(subsiteData, phoneToStorage)}
                  className="link-white footer-link"
                />
              </div>
            ) : (
              <></>
            )}
            {getEmail(subsiteData) ? (
              <div className="font---lato">
                <a
                  href={`mailto:${getEmail(subsiteData)}`}
                  className="link-white footer-link"
                >
                  {getEmail(subsiteData)}
                </a>
              </div>
            ) : (
              <></>
            )}
            <div className="font---lato mt-2">
              {subsiteData.u_address1 && subsiteData.s_fulladdress
                ? `${subsiteData.u_address1}`
                : ''}
              {subsiteData.u_address2 && subsiteData.s_fulladdress
                ? (subsiteData.u_address1 ? ',  ' : '') +
                  `${subsiteData.u_address2}`
                : ''}
            </div>
            <div className="font---lato">
              {subsiteData.u_city ? `${subsiteData.u_city}, ` : ''}{' '}
              {subsiteData.u_state ? `${subsiteData.u_state}, ` : ''}{' '}
              {subsiteData.u_zip ? `${subsiteData.u_zip}` : ''}
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <ul className="colophon">
              <li>{new Date().getFullYear()}&nbsp;USA Benefits Group</li>
              <li>
                <Link to={'/privacy-policy'} className="link-white-v2">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </footer>
  )
}

export default AgentFooter

import React from 'react'
import {
  MDBAlert,
  MDBContainer,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdbreact'
import { UIDatePickerInput } from '../../../forms/form-fields'
import { toast } from 'react-toastify'
import MessageService from './../../../../shared/services/Message.service'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import moment from 'moment'
import TextEditor from '../../../shared/TextEditor/TextEditor.component'

import './MessageForm.scss'

const effecitveMinDate = new Date(2000, 0, 1),
  effectiveMaxDate = moment().add(3, 'years').toDate(),
  timezoneOffset = new Date().getTimezoneOffset()

class MessageForm extends React.Component {
  state = {
    msgId: null,
    loading: true,
    saving: false,
    deleting: false,
    msgTitle: '',
    msgContent: '',
    msgCategory: '',
    msgDeletedAt: '',
    msgStartDate: '',
    msgEndDate: '',
    errorsExist: false,
    categories: [],

    canUpdate: false,
    canDelete: false,
  }

  #errors = {}

  #goToList = () => {
    if (typeof this.props.onSubmit === 'function') this.props.onSubmit(false)
  }

  #onChange = (event) =>
    this.setState({ [event.target.name]: event.target.value })

  #validate = () => {
    this.#errors = {}

    if (!this.state.msgTitle || !`${this.state.msgTitle}`.trim())
      this.#errors.msgTitle = 'The message title is required.'

    if (!this.state.msgCategory || !`${this.state.msgCategory}`.trim())
      this.#errors.msgCategory = 'The message category is required.'

    if (!this.state.msgContent || !`${this.state.msgContent}`.trim())
      this.#errors.msgContent = 'The message content is required.'

    return !Object.keys(this.#errors).length
  }

  #onSubmit = () => {
    if (this.state.loading || this.state.saving) return

    if (!this.#validate()) {
      toast.error(
        'Oh no! Errors exist.  Please check your inputs before attempting to save the message.',
        { position: toast.POSITION.TOP_RIGHT }
      )
      return this.setState({ errorsExist: true })
    }

    this.setState({ saving: true })
    const data = this.state.msgId
      ? {
          title: this.state.msgTitle,
          content: this.state.msgContent,
          category: this.state.msgCategory,
          division_id: this.props?.division_id ? this.props.division_id : null,
          updated_by: UserProfileService.getUserId(true),
          start_date: this.state.msgStartDate
            ? new Date(this.state.msgStartDate).toISOString().split('T')[0]
            : null,
          end_date: this.state.msgEndDate
            ? new Date(this.state.msgEndDate).toISOString().split('T')[0]
            : null,
          table_name: 'messages',
        }
      : {
          title: this.state.msgTitle,
          content: this.state.msgContent,
          category: this.state.msgCategory,
          division_id: this.props?.division_id ? this.props.division_id : null,
          created_by: UserProfileService.getUserId(true),
          updated_by: UserProfileService.getUserId(true),
          start_date: this.state.msgStartDate
            ? new Date(this.state.msgStartDate).toISOString().split('T')[0]
            : null,
          end_date: this.state.msgEndDate
            ? new Date(this.state.msgEndDate).toISOString().split('T')[0]
            : null,
          table_name: 'messages',
        }

    try {
      this.state.msgId
        ? MessageService.updateMessage(this.state.msgId, data)
        : MessageService.createMessage(data)
      toast.success(
        'Message ' +
          (this.state.msgId ? 'updated' : 'created') +
          ' successfully',
        { position: toast.POSITION.TOP_RIGHT }
      )
      this.props.onSubmit(true)
    } catch (error) {
      toast.error(error, { position: toast.POSITION.TOP_RIGHT })
    }

    this.setState({ saving: false })
  }

  #renderErrorField = (field) => {
    if (field && this.#errors.hasOwnProperty(field) && this.state.errorsExist)
      return <span className="error-msg">{this.#errors[field]}</span>
    return <></>
  }

  #fetchMessageCategories = async () => {
    let categories, error

    try {
      categories = await MessageService.getMessageCategories(
        this.props?.division_id
      )
    } catch (ex) {
      error = ex
    }

    if (categories && categories.length) {
      if (this.props?.division_id) categories.push('Division')
      categories = categories.filter((v, idx, self) => self.indexOf(v) === idx)
      return this.setState(
        this.props.msgId
          ? { categories }
          : {
              categories,
              loading: false,
              msgCategory: categories.filter((n) => n).shift(),
            }
      )
    } else if (this.props?.division_id) {
      categories.push('Division')
      return this.setState(
        this.props.msgId
          ? { categories }
          : {
              categories,
              loading: false,
              msgCategory: categories.filter((n) => n).shift(),
            }
      )
    }

    if (error) toast.error(error, { position: toast.POSITION.TOP_RIGHT })
  }

  #fetchMessageById = async () => {
    let msg, error

    try {
      msg = await MessageService.getMessageById(this.props.msgId)
    } catch (ex) {
      error = ex
    }

    if (msg && msg.length) {
      msg = msg.shift()
      return this.setState({
        msgId: msg.id,
        msgContent: msg.content,
        msgCategory: msg.category,
        msgTitle: msg.title,
        msgDeletedAt: msg.deleted_at
          ? moment(msg.deleted_at).format('MM/DD/YYYY h:mm a')
          : null,
        msgStartDate: msg.start_date ? msg.start_date : null,
        msgEndDate: msg.end_date ? msg.end_date : null,
        loading: false,
      })
    }

    if (error) toast.error(error, { position: toast.POSITION.TOP_RIGHT })
  }

  #checkUserCanModifyMessages = async () => {
    let state = null

    try {
      let permitted = await Promise.all([
        UserProfileService.canUser('messages.dashboard.update'),
        UserProfileService.canUser('messages.dashboard.delete'),
      ])
      state = {
        canUpdate: permitted.shift(),
        canDelete: permitted.shift(),
      }
    } catch (ex) {
      console.error(
        'ERROR: Failed to check user permissions in MessageFormComponent.  ',
        ex
      )
    }

    if (state) this.setState(state)
  }

  #onDelete = async () => {
    if (this.state.msgId) {
      if (!this.state.deleting) this.setState({ deleting: true })

      await MessageService.updateMessage(this.state.msgId, {
        table_name: 'messages',
        deleted_by: UserProfileService.getUserId(true),
        deleted_at: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      })

      this.setState((prevState) => ({
        ...prevState,
        deleting: false,
        msgDeletedAt: moment().format('MM/DD/YYYY h:mm a'),
      }))
    }
  }

  componentDidMount() {
    this.#fetchMessageCategories()
    this.#checkUserCanModifyMessages()

    if (parseInt(this.props.msgId) > 0) this.#fetchMessageById(this.props.msgId)
  }

  render() {
    toast.configure()

    const {
        loading,
        saving,
        deleting,
        msgTitle,
        msgContent,
        msgCategory,
        msgDeletedAt,
      } = this.state,
      categories = this.state.categories.map((c) => {
        return (
          <option key={c} value={c}>
            {(' ' + c).replace(/ [\w]/g, (a) => a.toLocaleUpperCase()).trim()}
          </option>
        )
      })

    return (
      <MDBContainer id="MessageFormComponent" fluid>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem onClick={() => this.#goToList()}>
            Back to Message List
          </MDBBreadcrumbItem>
        </MDBBreadcrumb>
        {loading ? (
          <h4 className="p4">Loading...</h4>
        ) : (
          <MDBRow>
            <MDBCol size="12" md="6">
              <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="grey-text" htmlFor="d_name">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="msgTitle"
                      id="msgTitle"
                      value={msgTitle}
                      readOnly={!!this.state.msgDeletedAt}
                      onChange={this.#onChange}
                    />
                    {this.#renderErrorField('msgTitle')}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="grey-text" htmlFor="d_name">
                      Category
                    </label>
                    <select
                      className="browser-default custom-select form-control"
                      name="msgCategory"
                      id="msgCategory"
                      value={msgCategory}
                      readOnly={!!this.state.msgDeletedAt}
                      onChange={this.#onChange}
                    >
                      {categories}
                    </select>
                    {this.#renderErrorField('msgCategory')}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <UIDatePickerInput
                      label="Start Date"
                      name="msg_start_date"
                      id="dp_msg_start_date"
                      showYearDropdown
                      yearDropdownItemNumber={2}
                      scrollableYearDropdown
                      dateFormat="MM/dd/yyyy"
                      selected={
                        this.state.msgStartDate
                          ? new Date(
                              new Date(this.state.msgStartDate).getTime() +
                                timezoneOffset * 60000
                            )
                          : null
                      }
                      onChange={(date) =>
                        this.setState({ msgStartDate: date.target.value })
                      }
                      readOnly={!!this.state.msgDeletedAt}
                      minDate={effecitveMinDate}
                      maxDate={effectiveMaxDate}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <UIDatePickerInput
                      label="End Date"
                      name="msg_end_date"
                      id="dp_msg_end_date"
                      showYearDropdown
                      yearDropdownItemNumber={2}
                      scrollableYearDropdown
                      dateFormat="MM/dd/yyyy"
                      selected={
                        this.state.msgEndDate
                          ? new Date(
                              new Date(this.state.msgEndDate).getTime() +
                                timezoneOffset * 60000
                            )
                          : null
                      }
                      onChange={(date) =>
                        this.setState({ msgEndDate: date.target.value })
                      }
                      readOnly={!!this.state.msgDeletedAt}
                      minDate={effecitveMinDate}
                      maxDate={effectiveMaxDate}
                    />
                    <small className="text-left helper-link grey-text">
                      Messages will be visible for 7 days by default, or until
                      the end date indicated.
                    </small>
                  </div>
                </div>
                <div className="form-row mt-2">
                  <div className="form-group col-md-12">
                    <label className="grey-text">Content</label>
                    {!this.state.msgDeletedAt ? (
                      <TextEditor
                        id="messageForm"
                        value={this.state.msgContent}
                        onChange={(html) => this.setState({ msgContent: html })}
                      />
                    ) : (
                      <>
                        <div
                          dangerouslySetInnerHTML={{ __html: msgContent }}
                        ></div>
                      </>
                    )}
                    {this.#renderErrorField('msgContent')}
                  </div>
                </div>

                <div className="form-row text-center mt-4">
                  {msgDeletedAt && (
                    <MDBAlert color="danger">
                      This message was deleted on {msgDeletedAt}.
                    </MDBAlert>
                  )}
                  {this.state.canUpdate && !msgDeletedAt && (
                    <MDBBtn
                      color="unique"
                      type="button"
                      disabled={loading || saving || deleting}
                      onClick={() => this.#onSubmit()}
                    >
                      {saving ? 'Saving...' : 'Save'}
                    </MDBBtn>
                  )}
                  {this.state.canUpdate && !msgDeletedAt && (
                    <MDBBtn
                      color="danger"
                      type="button"
                      disabled={loading || saving || deleting}
                      onClick={() => this.#onDelete()}
                    >
                      {deleting ? 'Deleting...' : 'Delete'}
                    </MDBBtn>
                  )}
                </div>
              </form>
            </MDBCol>
          </MDBRow>
        )}
      </MDBContainer>
    )
  }
}

export default MessageForm

import {
  getWeek,
  format,
  getDay,
  add,
  parse,
  setWeek,
  nextMonday,
  addDays,
  getISOWeek,
} from 'date-fns'

const isDateLeapYear = (reportDate) =>
  reportDate.getFullYear() % 100 === 0
    ? reportDate.getFullYear() % 400 === 0
    : reportDate.getFullYear() % 4 === 0

const isDateLeapDay = (reportDate) =>
  reportDate && isDateLeapYear(reportDate)
    ? !!(reportDate.getMonth() === 1 && reportDate.getDate() === 29)
    : false

const subtractDay = (reportDate) => {
  let rDate = new Date(reportDate)
  rDate.setDate(rDate.getDate() - 1)
  return rDate
}

const getFirstDayOfYear = (reportDate) =>
  parse('1', 'I', reportDate ? reportDate : new Date())

const doesYearStartOnMonday = (reportDate) =>
  getFirstDayOfYear(reportDate).getDay() === 1

const INITIAL_DATE = new Date()
INITIAL_DATE.setHours(0)
INITIAL_DATE.setMinutes(0)
INITIAL_DATE.setSeconds(0)

export default class ComparativeDateCalculator {
  currReportDate = INITIAL_DATE
  prevReportYear = new Date().getFullYear() - 1
  currExportReportDate = INITIAL_DATE
  prevExportReportYear = new Date().getFullYear() - 1
  diffPrevYear = 1
  exportDiffPrevYear = 1

  setCurrReportDate(reportDate) {
    let stateUpd = {},
      yearDiff = isNaN(this.diffPrevYear) ? 1 : this.diffPrevYear

    if (this.currReportDate && reportDate) {
      if (
        format(this.currReportDate, 'yyyy-MM-dd') !==
        format(reportDate, 'yyyy-MM-dd')
      ) {
        stateUpd.reportDate = reportDate
        stateUpd.prevYear = format(reportDate, 'yyyy') - yearDiff
      }
    } else if (reportDate) {
      stateUpd.reportDate = reportDate
      stateUpd.prevYear = format(reportDate, 'yyyy') - yearDiff
    }

    if (Object.keys(stateUpd).length) {
      stateUpd.reportDate.setHours(0)
      stateUpd.reportDate.setMinutes(0)
      stateUpd.reportDate.setSeconds(0)

      this.currReportDate = stateUpd.reportDate
      this.prevReportYear = stateUpd.prevYear

      return this.currReportDate && this.prevReportYear
    }

    return false
  }

  setExportCurrReportDate(exportReportDate) {
    let stateUpd = {},
      yearDiff = isNaN(this.exportDiffPrevYear) ? 1 : this.exportDiffPrevYear

    if (this.currExportReportDate && exportReportDate) {
      if (
        format(this.currExportReportDate, 'yyyy-MM-dd') !==
        format(exportReportDate, 'yyyy-MM-dd')
      ) {
        stateUpd.exportReportDate = exportReportDate
        stateUpd.exportPrevYear = format(exportReportDate, 'yyyy') - yearDiff
      }
    } else if (exportReportDate) {
      stateUpd.exportReportDate = exportReportDate
      stateUpd.exportPrevYear = format(exportReportDate, 'yyyy') - yearDiff
    }
    if (Object.keys(stateUpd).length) {
      stateUpd.exportReportDate.setHours(0)
      stateUpd.exportReportDate.setMinutes(0)
      stateUpd.exportReportDate.setSeconds(0)

      this.currExportReportDate = stateUpd.exportReportDate
      this.prevExportReportYear = stateUpd.exportPrevYear
      return this.currExportReportDate && this.prevExportReportYear
    }

    return false
  }

  setPrevReportYear(prevYear, isExport = false) {
    const diff = this.getCurrYear(isExport) - prevYear
    let diffPrevYear = isExport ? this.exportDiffPrevYear : this.diffPrevYear
    if (diff > 0 && parseInt(diff) !== diffPrevYear) {
      if (isExport) {
        this.exportDiffPrevYear = parseInt(diff)
        this.prevExportReportYear = parseInt(prevYear)
        return true
      }
      this.diffPrevYear = parseInt(diff)
      this.prevReportYear = parseInt(prevYear)
      return true
    }

    return false
  }

  getCurrDate(isExport = false) {
    return isExport ? this.currExportReportDate : this.currReportDate
  }

  getCurrYear() {
    return format(this.getCurrDate(), 'yyyy')
  }

  getCurrSearch(isExport = false) {
    return { report_date: format(this.getCurrDate(isExport), 'yyyy-MM-dd') }
  }

  getPrevYear(isExport = false) {
    return isExport ? this.prevExportReportYear : this.prevReportYear
  }

  getPrevYearDiff(isExport = false) {
    return isExport ? this.exportDiffPrevYear : this.diffPrevYear
  }

  // Function returns the start date of any week from week number & year
  getDateOfWeek(w, y) {
    let date = new Date(y, 0, 1 + (w - 1) * 7)
    date.setDate(date.getDate() + (1 - date.getDay())) // 0 - Sunday, 1 - Monday etc
    return date
  }

  getPrevSearch(isExport = false) {
    if (this.getPrevYear(isExport) <= 2021) {
      return this.getLegacyPrevSearch(isExport)
    }
    let currDate = this.getCurrDate(isExport),
      reportDate = isDateLeapDay(currDate) ? subtractDay(currDate) : currDate,
      dayIdx = getDay(reportDate),
      weekIdx =
        getWeek(reportDate, { weekStartsOn: 1 }) +
        (doesYearStartOnMonday(reportDate) ? 1 : 0),
      weekDate = setWeek(
        nextMonday(new Date(this.getPrevYear(isExport), 0, 4)),
        weekIdx,
        { weekStartsOn: 1 }
      )
    while (getDay(weekDate) !== dayIdx) weekDate = add(weekDate, { days: 1 })

    reportDate = [this.getPrevYear(isExport), format(reportDate, 'MM-dd')].join(
      '-'
    )

    return {
      report_date: reportDate,
      week: format(weekDate, 'yyyy-MM-dd'),
      month: reportDate,
      quarter: reportDate,
      year: reportDate,
    }
  }

  getLegacyPrevSearch(isExport = false) {
    let currDate = this.getCurrDate(isExport)
    let legacyeportdate = isDateLeapDay(currDate)
      ? subtractDay(currDate)
      : currDate
    legacyeportdate = [
      this.getPrevYear(isExport),
      format(legacyeportdate, 'MM-dd'),
    ].join('-')
    let prevYearStartDateWeek = this.getDateOfWeek(
      getISOWeek(currDate),
      this.getPrevYear(isExport)
    )
    let reportDate = addDays(prevYearStartDateWeek, getDay(currDate) - 1)
    let weekDate = reportDate
    reportDate = format(reportDate, 'yyyy-MM-dd')
    return {
      report_date: reportDate,
      week: format(weekDate, 'yyyy-MM-dd'),
      month: legacyeportdate,
      quarter: legacyeportdate,
      year: legacyeportdate,
    }
  }

  getStateObj() {
    return {
      reportDate: this.getCurrDate(),
      prevYear: this.getPrevYear(),
      diffPrevYear: this.getPrevYearDiff(),
      exportReportDate: this.getCurrDate(true),
      exportPrevYear: this.getPrevYear(true),
      exportDiffPrevYear: this.getPrevYearDiff(true),
      exportPreviousReportDate: this.getPrevSearch(true),
    }
  }
}

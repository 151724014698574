import React from 'react'
import { getHeadshot } from './../../../../shared/utilities/getHeadshot.function'
import signature from './../../../../assets/images/theme/signature.white.png'
import { getPhone } from './../../formatters'
import { phoneToStorage } from './../../../../shared/formatters/telephone.formatter'
import Telephone from '../../../../components/shared/Telephone/Telephone.component'

import './AgentIdentity.component.scss'

const AgentIdentity = ({ subsiteData }) => {
  return (
    <div id="AgentIdentityComponent">
      {subsiteData?.s_agent_picture && subsiteData?.u_picture ? (
        <div className="agent-headshot text-center">
          <img
            src={getHeadshot(subsiteData)}
            alt="Professional Head Shot"
            className="img-fluid mx-auto d-block"
          />
        </div>
      ) : (
        <></>
      )}
      <div className="agent-name font--lato fw--700">{`${
        (subsiteData?.u_nickname
          ? subsiteData?.u_nickname
          : subsiteData?.u_fname) || ''
      } ${subsiteData?.u_lname || ''}`}</div>
      <div className="agent-title font--lato fw--500">
        {subsiteData?.u_marketing_title || ''}
      </div>
      <div className="agent-phone font--lato fw--500">
        {getPhone(subsiteData) ? (
          <Telephone phoneNumber={getPhone(subsiteData, phoneToStorage)} />
        ) : (
          <></>
        )}
      </div>
      <div className="agent-signature">
        <img alt="Agent Logo" src={signature} className="img-fluid" />
      </div>
    </div>
  )
}

export default AgentIdentity

import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBCardHeader,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBBtn,
  MDBBtnGroup,
  MDBDataTable,
  MDBIcon,
} from 'mdbreact'
import {
  UIDatePickerInput,
  UIInput,
} from './../../../../components/forms/form-fields'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner.component'
import DateManager from '../../../../shared/utilities/DateManager.class'
import Papa from 'papaparse' // Import CSV library
import './ExportGrowthReportModal.scss'

const ExportGrowthReportModal = observer(
  ({
    isOpen,
    toggle,
    isLoading,
    fetching,
    exportmetric,
    setExportMetric,
    exportData,
    exportReportDate,
    onExportDateChange,
    canAdvanceExportDate,
    buildTableRecords,
    renderPrevYearDropDown,
  }) => {
    const exportToCSV = () => {
      if (!exportData || !exportData.rows || exportData.rows.length === 0) {
        alert('No data to export!')
        return
      }

      const formattedData = exportData.rows.map((row) => ({
        Agent: row.id || '',
        Usertype: row.usertype || '',
        'Report Timespan': row.timespan || '',
        'Current Value': row.current !== undefined ? row.current : 0, // Default to 0 if missing
        'Previous Value': row.previous !== undefined ? row.previous : 0, // Default to 0 if missing
        'Growth Value': row.growth !== undefined ? row.growth : '0%', // Default to '0%'
      }))

      // Convert formatted data to CSV format
      const csv = Papa.unparse(formattedData, { header: true })

      // Create a Blob and trigger CSV download
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Team Growth Report.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    return (
      <MDBModal
        size="xl"
        isOpen={isOpen}
        toggle={toggle}
        className="exportGrowthReportModal"
      >
        <MDBModalHeader toggle={toggle}>
          Export Team Growth Report
        </MDBModalHeader>
        <MDBModalBody className={isLoading ? 'is-loading' : ''}>
          {isLoading ? (
            <div className="loading-wrapper">
              <LoadingSpinner size="md" isActive={true} />
            </div>
          ) : (
            <>
              <MDBRow>
                <MDBContainer fluid>
                  <MDBCol size="12">
                    <ul className="source-buttons">
                      {[
                        'points',
                        'av',
                        'aca_lives',
                        'enrolled',
                        'ma_lives',
                        'life_av',
                      ].map((metric) => (
                        <li key={metric}>
                          <MDBBtn
                            disabled={fetching || exportmetric === metric}
                            onClick={() => {
                              setExportMetric(metric)
                              buildTableRecords(metric)
                            }}
                            color={
                              exportmetric !== metric ? 'indigo' : 'blue-grey'
                            }
                          >
                            <MDBIcon icon="check" /> &nbsp;View{' '}
                            {metric.replace('_', ' ')}
                          </MDBBtn>
                        </li>
                      ))}
                    </ul>
                  </MDBCol>
                </MDBContainer>
              </MDBRow>
              <hr />
              <MDBRow>
                <MDBCol size="12">
                  <MDBContainer fluid className="exportGrowthReportModal">
                    <MDBRow className="widget-date-selector">
                      <MDBCol size="12" sm="12" md="2" className="wds-input">
                        <UIDatePickerInput
                          label="Report Date"
                          name="export_report_date"
                          id="export_dpw_report_date"
                          showYearDropdown
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown
                          dateFormat="MM/dd/yyyy"
                          maxDate={new Date()}
                          selected={exportReportDate}
                          onChange={(date) =>
                            onExportDateChange(date?.target?.value)
                          }
                        />
                      </MDBCol>
                      <MDBCol size="12" sm="12" md="5" className="wds-preset">
                        <MDBBtnGroup>
                          <MDBBtn
                            disabled={fetching}
                            onClick={() =>
                              onExportDateChange(
                                DateManager.getDayInPast(
                                  'Sun',
                                  exportReportDate
                                )
                              )
                            }
                            color="indigo"
                          >
                            <MDBIcon icon="arrow-left" /> &nbsp;Previous Week
                          </MDBBtn>
                          <MDBBtn
                            disabled={fetching}
                            onClick={() => onExportDateChange(new Date())}
                            color="indigo"
                          >
                            Current Week
                          </MDBBtn>
                          <MDBBtn
                            disabled={fetching || !canAdvanceExportDate()}
                            onClick={() =>
                              onExportDateChange(
                                DateManager.getDayInPast(
                                  'Sun',
                                  DateManager.addWeeks(exportReportDate, 2)
                                )
                              )
                            }
                            color="indigo"
                          >
                            Next Week &nbsp; <MDBIcon icon="arrow-right" />
                          </MDBBtn>
                        </MDBBtnGroup>
                      </MDBCol>
                      <MDBCol size="12" sm="12" md="3" className="wds-preset">
                        <MDBCardHeader
                          color="primary-color"
                          className="prev-year"
                        >
                          Previous Year{' '}
                          <span className="ml-3">
                            {fetching ? (
                              <i className="fa fa-spin fa-spinner"></i>
                            ) : (
                              renderPrevYearDropDown()
                            )}
                          </span>
                        </MDBCardHeader>
                      </MDBCol>
                      <MDBCol size="12" sm="12" md="2" className="wds-preset">
                        <MDBBtn
                          color="success"
                          className="paddingSkip"
                          size="md"
                          onClick={exportToCSV}
                        >
                          <MDBIcon icon="file-csv" /> Export CSV
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </MDBCol>
              </MDBRow>
              <hr />
              <div>
                <MDBDataTable
                  paging
                  striped
                  order={['id', 'asc']}
                  bordered
                  data={exportData}
                />
              </div>
              <MDBBtn color="warning" size="sm" block onClick={toggle}>
                Close
              </MDBBtn>
            </>
          )}
        </MDBModalBody>
      </MDBModal>
    )
  }
)

export default ExportGrowthReportModal

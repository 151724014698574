export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const subNavOpts = {
  'my-production': {
    'production-details': 'individualDetails',
    'production-by-day': 'individualDay',
    'production-by-carrier': 'individualByCarrier',
    'production-by-lead-source': 'individualByAgencyLeadsource',
    'production-by-carrier-lead-source': 'individualByAgencyLeadsourceCarrier',
    'production-statistics': 'individualProductionStatistics',
    'production-by-lead-source-aggregate':
      'individualProductionLeadsourceAggregate',
  },
  'my-wallet': {
    'individual-transaction-history': 'individualTransactionHistory',
  },
  'kpi-report': {
    'kpi-performance': 'agentKpiPerformance',
  },
  'downline-production': {
    'production-details': 'downlineDetails',
    'production-by-day': 'downlineDay',
    'production-by-carrier': 'downlineByCarrier',
    'production-by-lead-source': 'downlineByAgencyLeadsource',
    'production-by-carrier-lead-source': 'downlineByAgencyLeadsourceCarrier',
    'agent-statistics': 'downlineAgentStatistics',
    'leader-statistics': 'downlineManagerStatistics',
    'downline-marketing-commitment': 'downlineMarketingCommitments',
    'downline-transaction-history': 'downlineTransactionHistory',
  },
  'division-production': {
    'production-details': 'divisionProductionDetails',
    'production-by-day': 'divisionProductionDay',
    'production-by-carrier': 'divisionProductionByCarrier',
    'production-by-lead-source': 'divisionProductionByAgencyLeadsource',
    'production-by-carrier-lead-source':
      'divisionProductionByAgencyLeadsourceCarrier',
    'agent-statistics': 'divisionAgentStatistics',
    'leader-statistics': 'divisionManagerStatistics',
    'production-by-lead-source-aggregate':
      'divisionProductionLeadsourceAggregate',
  },
  'ba-production': {
    'production-details': 'baTeamProductionDetails',
    'production-by-day': 'baTeamProductionDay',
    'production-by-carrier': 'baTeamProductionByCarrier',
    'production-by-lead-source': 'baTeamProductionByAgencyLeadsource',
    'production-by-carrier-lead-source':
      'baTeamProductionByAgencyLeadsourceCarrier',
    'csr-statistics': 'baTeamAgentStatistics',
    'production-by-lead-source-aggregate':
      'baTeamProductionLeadsourceAggregate',
  },
  'agency-production': {
    'production-by-lead-source': 'agencyProductionLeadsource',
    'production-by-carrier-lead-source': 'agencyProductionLeadsourceCarrier',
    'production-by-carrier': 'agencyProductionByCarrier',
    'production-by-lead-source-aggregate':
      'agencyProductionLeadsourceAggregate',
    'recruiting-lead-sources': 'agencyRecruitingLeadSources',
  },
  'agency-wallets': {
    'agency-transaction-history': 'agencyTransactionHistory',
    // 'agency-transaction-history-all-time': 'agencyTransactionHistoryAllTime',
    'agency-ledger-balance': 'agencyLedgerBalance',
    // 'agency-ledger-balance-all-time': 'agencyLedgerBalanceAllTime',
    'agency-auto-awarded-transactions': 'agencyAutoAwardTransactionHistory',
    'av-award-outcomes': 'avAwardOutcome',
  },
  referrals: {
    'my-recruiting-lead-sources': 'individualRecruitingLeadSources',
    'referral-production-detail': 'getReferralProductionDetail',
    'referral-production-total': 'getReferralProductionTotal',
    'referral-payments': 'getReferralPayments',
    'referral-partner-w9': 'getReferralPartnerW9',
  },
  'district-totals': 'districtTotals',
  'region-totals': 'regionTotals',
  'division-totals': 'divisionTotals',
  'career-totals': 'careerTotals',
  contracting: {
    'downline-contracting': 'downlineContracting',
  },
  leaders: {
    'kpi-performance': 'leaderKpiPerformance',
    'recruiting-lead-sources': 'recruitingLeadSources',
  },
  'agent-ledgers': {
    'agent-ledger-detail': 'agentLedgerDetail',
  },
  'broker-support': {
    'agent-npn-list': 'agentNPNList',
    'nsm-weekly': 'internalNsmWeekly',
    'subcription-payments-by-product': 'stripeSubPaymentsByProduct',
    'failed-payments': 'stripeFailedPayments',
    'refund-payments': 'stripeRefundPayments',
  },
}

export const tableColumns = {
  'my-production': {
    'production-details-bst': [
      {
        label: 'Agent',
        field: 'agent',
        sort_col: 'av_user',
      },
      {
        label: 'Upline',
        field: 'upline',
        sort_col: 'av_upline',
      },
      {
        label: 'Submission Date',
        field: 'submission_date',
        sort_col: 'av_date',
      },
      {
        label: 'Effective Date',
        field: 'effective_date',
        sort_col: 'av_eff_date',
      },
      {
        label: 'Client',
        field: 'client',
        sort_col: 'av_client',
      },
      {
        label: 'State',
        field: 'state',
        sort_col: 'av_state',
      },
      {
        label: 'Carrier',
        field: 'carrier',
        sort_col: 'av_carrier',
      },
      {
        label: 'Policy # or D.O.B.',
        field: 'app',
        sort_col: 'av_appid',
      },
      {
        label: 'App Status',
        field: 'status',
        sort_col: 'av_status',
      },
      {
        label: 'Premium',
        field: 'prem',
        sort_col: 'av_premium',
      },
      {
        label: 'Annualized Volume (AV)',
        field: 'av',
        sort_col: 'av_premium_total',
      },
      {
        label: 'Points',
        field: 'av_points',
        sort_col: 'av_points',
      },
      {
        label: 'ACA Lives',
        field: 'lives',
        sort_col: 'lives',
      },
      {
        label: 'Coverage',
        field: 'cov',
        sort_col: 'av_coverage',
      },
      {
        label: 'Comments',
        field: 'comments',
        sort_col: 'av_comments',
      },
      {
        label: 'CSR',
        field: 'csr',
        sort_col: 'av_sub_agent',
      },
      {
        label: 'Product',
        field: 'product',
        sort_col: 'av_product',
      },
      {
        label: 'Lead Source',
        field: 'lead_source',
        sort_col: 'av_leadsource',
      },
      {
        label: 'Referral Partner',
        field: 'ref_partner',
        sort_col: 'refs.name',
      },
      {
        label: 'Referral Commission',
        field: 'ref_comm',
        sort_col: 'avrefs.amt_paid',
      },
      {
        label: 'Commission Paid',
        field: 'comm_paid',
        sort_col: 'comm_paid',
      },
      {
        label: 'Commission Charged Back',
        field: 'comm_is_chrg_bk',
        sort_col: 'comm_is_chrg_bk',
      },
      {
        label: 'Edit',
        field: 'edit',
      },
    ],
    'production-details': [
      {
        label: 'Agent',
        field: 'agent',
        sort_col: 'av_user',
      },
      {
        label: 'Upline',
        field: 'upline',
        sort_col: 'av_upline',
      },
      {
        label: 'Submission Date',
        field: 'submission_date',
        sort_col: 'av_date',
      },
      {
        label: 'Effective Date',
        field: 'effective_date',
        sort_col: 'av_eff_date',
      },
      {
        label: 'Client',
        field: 'client',
        sort_col: 'av_client',
      },
      {
        label: 'State',
        field: 'state',
        sort_col: 'av_state',
      },
      {
        label: 'Carrier',
        field: 'carrier',
        sort_col: 'av_carrier',
      },
      {
        label: 'Policy # or D.O.B.',
        field: 'app',
        sort_col: 'av_appid',
      },
      {
        label: 'App Status',
        field: 'status',
        sort_col: 'av_status',
      },
      {
        label: 'Premium',
        field: 'prem',
        sort_col: 'av_premium',
      },
      {
        label: 'Annualized Volume (AV)',
        field: 'av',
        sort_col: 'av_premium_total',
      },
      {
        label: 'Points',
        field: 'av_points',
        sort_col: 'av_points',
      },
      {
        label: 'ACA Lives',
        field: 'lives',
        sort_col: 'lives',
      },
      {
        label: 'Coverage',
        field: 'cov',
        sort_col: 'av_coverage',
      },
      {
        label: 'Comments',
        field: 'comments',
        sort_col: 'av_comments',
      },
      {
        label: 'CSR',
        field: 'csr',
        sort_col: 'av_sub_agent',
      },
      {
        label: 'Product',
        field: 'product',
        sort_col: 'av_product',
      },
      {
        label: 'Lead Source',
        field: 'lead_source',
        sort_col: 'av_leadsource',
      },
      {
        label: 'Referral Partner',
        field: 'ref_partner',
        sort_col: 'refs.name',
      },
      {
        label: 'Referral Commission',
        field: 'ref_comm',
        sort_col: 'refs.comm',
      },
      {
        label: 'Edit',
        field: 'edit',
      },
    ],
    'production-by-day': [
      {
        label: 'Agent',
        field: 'agent',
      },
      {
        label: 'Upline',
        field: 'upline',
      },
      {
        label: 'Monday',
        field: 'mon',
      },
      {
        label: 'Tuesday',
        field: 'tues',
      },
      {
        label: 'Wednesday',
        field: 'wed',
      },
      {
        label: 'Thursday',
        field: 'thurs',
      },
      {
        label: 'Friday',
        field: 'fri',
      },
      {
        label: 'Saturday',
        field: 'sat',
      },
      {
        label: 'Sunday',
        field: 'sun',
      },
      {
        label: 'Total',
        field: 'total',
      },
    ],
    'production-by-carrier': [
      {
        label: 'Agent',
        field: 'av_user',
      },
      {
        label: 'Upline',
        field: 'av_upline',
      },
    ],
    'production-by-lead-source': [
      {
        label: 'Agent',
        field: 'av_user',
      },
      {
        label: 'Upline',
        field: 'av_upline',
      },
    ],
    'production-by-carrier-lead-source': [
      {
        label: 'Lead Source',
        field: 'av_leadsource',
      },
    ],
    'production-statistics': [
      {
        label: 'Agent',
        field: 'agent',
      },
      {
        label: 'Clients',
        field: 'clients',
      },
      {
        label: 'Apps',
        field: 'apps',
      },
      {
        label: 'Issued',
        field: 'issued',
      },
      {
        label: 'Transferred',
        field: 'transferred',
      },
      {
        label: 'Declined',
        field: 'declined',
      },
      {
        label: 'Withdrawn',
        field: 'withdrawn',
      },
      {
        label: 'Canceled',
        field: 'canceled',
      },
      {
        label: 'Apps Per Client',
        field: 'apps_per_client',
      },
      {
        label: 'Issued Apps (%.)',
        field: 'issued_apps',
      },
      {
        label: 'AV Per App',
        field: 'av_per_app',
      },
      {
        label: 'AV Per Client',
        field: 'av_per_client',
      },
      {
        label: 'Total Annualized Volume (AV)',
        field: 'total_av',
      },
      {
        label: 'ACA Lives Per App',
        field: 'lives_per_app',
      },
      {
        label: 'ACA Lives Per Client',
        field: 'lives_per_client',
      },
      {
        label: 'Total ACA Lives',
        field: 'total_lives',
      },
      {
        label: 'Points Per App',
        field: 'points_per_app',
      },
      {
        label: 'Points Per Client',
        field: 'points_per_client',
      },
      {
        label: 'Points Total',
        field: 'total_points',
      },
    ],
    'production-by-lead-source-aggregate': [
      {
        label: 'Lead Source',
        field: 'av_leadsource',
        sort_col: 'av_leadsource',
      },
      {
        label: 'Med Advantage Lives (MA)',
        field: 'med_adv',
        sort_col: 'med_adv',
      },
      {
        label: 'ACA Lives',
        field: 'lives',
        sort_col: 'lives',
      },
      {
        label: 'Points',
        field: 'points',
        sort_col: 'av_points',
      },
      {
        label: 'Annualized Volume (AV)',
        field: 'av_premium_total',
        sort_col: 'av_premium_total',
      },
    ],
  },
  'my-wallet': {
    'individual-transaction-history': [
      { label: 'Transaction ID', field: 'transaction_id' },
      { label: 'Type', field: 'type' },
      { label: 'Purpose', field: 'purpose' },
      { label: 'Credit (Deposit)', field: 'credit' },
      { label: 'Debit (Withdrawl)', field: 'debit' },
      { label: 'Transaction Date', field: 'transact_at' },
      { label: 'Transaction Status', field: 'trans_state' },
      { label: 'Category', field: 'category' },
      { label: 'Carrier', field: 'carrier_name' },
      { label: 'FMO', field: 'fmo_name' },
      { label: 'Donating Agent', field: 'doner_name' },
      { label: 'Lead Vendor', field: 'vendor_name' },
      { label: 'Has Expired?', field: 'is_expire' },
    ],
  },
  'kpi-report': {
    'kpi-performance': [
      {
        label: 'Day of Week',
        field: 'day_of_week',
      },
      {
        label: 'Calls',
        field: 'calls',
      },
      {
        label: 'Events Attended',
        field: 'events_attended',
      },
      {
        label: 'Spoken To',
        field: 'spoken_to',
      },
      {
        label: 'Fact Finders',
        field: 'fact_finders',
      },
      {
        label: 'Appts Set',
        field: 'appts_set',
      },
      {
        label: 'Presentations Offered',
        field: 'presentations_offered',
      },
      {
        label: 'Total Health AV',
        field: 'total_health_av',
      },
      {
        label: 'Total ACA Lives AV',
        field: 'total_lives_av',
      },
      {
        label: 'Total Points',
        field: 'total_points',
      },
      {
        label: 'Referrals Requested',
        field: 'referrals_requested',
      },
      {
        label: 'Life Quotes',
        field: 'life_quotes',
      },
      {
        label: 'Total Clients',
        field: 'total_clients',
      },
      {
        label: 'Total Apps',
        field: 'total_apps',
      },
      {
        label: 'Total ACA Lives',
        field: 'total_lives',
      },
      {
        label: 'Total Hours Worked',
        field: 'total_hours_works',
      },
      {
        label: '% Call to Events Attended',
        field: 'calls_to_events_attended',
      },
      {
        label: '% Call to Spoken to',
        field: 'calls_to_spoken_to',
      },
      {
        label: '% Calls to Fact Finders',
        field: 'calls_to_fact_finders',
      },
      {
        label: '% Calls to Appts Set',
        field: 'calls_to_appts_set',
      },
      {
        label: '% Calls to Presentations Offered',
        field: 'calls_to_presentations_offered',
      },
      {
        label: '% Calls to Total Clients',
        field: 'calls_to_total_clients',
      },
      {
        label: '% Calls to Total Apps',
        field: 'calls_to_total_apps',
      },
      {
        label: 'Invested',
        field: 'invested',
      },
    ],
  },
  'downline-production': {
    'production-details-bst': [
      {
        label: 'Agent',
        field: 'agent',
        sort_col: 'av_user',
      },
      {
        label: 'Upline',
        field: 'upline',
        sort_col: 'av_upline',
      },
      {
        label: 'Submission Date',
        field: 'submission_date',
        sort_col: 'av_date',
      },
      {
        label: 'Effective Date',
        field: 'effective_date',
        sort_col: 'av_eff_date',
      },
      {
        label: 'Client',
        field: 'client',
        sort_col: 'av_client',
      },
      {
        label: 'State',
        field: 'state',
        sort_col: 'av_state',
      },
      {
        label: 'Carrier',
        field: 'carrier',
        sort_col: 'av_carrier',
      },
      {
        label: 'Policy # or D.O.B.',
        field: 'app',
        sort_col: 'av_appid',
      },
      {
        label: 'Premium',
        field: 'prem',
        sort_col: 'av_premium',
      },
      {
        label: 'Status / Disposition',
        field: 'status',
        sort_col: 'av_status',
      },
      {
        label: 'Annualized Volume (AV)',
        field: 'av',
        sort_col: 'av_premium_total',
      },
      {
        label: 'Points',
        field: 'points',
        sort_col: 'av_points',
      },
      {
        label: 'ACA Lives',
        field: 'lives',
        sort_col: 'lives',
      },
      {
        label: 'Coverage',
        field: 'cov',
        sort_col: 'av_coverage',
      },
      {
        label: 'Comments',
        field: 'comments',
        sort_col: 'av_comments',
      },
      {
        label: 'CSR',
        field: 'csr',
        sort_col: 'av_sub_agent',
      },
      {
        label: 'Product',
        field: 'product',
        sort_col: 'av_product',
      },
      {
        label: 'Lead Source',
        field: 'lead_source',
        sort_col: 'av_leadsource',
      },
      {
        label: 'Referral Partner',
        field: 'ref_partner',
        sort_col: 'refs.name',
      },
      {
        label: 'Referral Commission',
        field: 'ref_comm',
        sort_col: 'avrefs.amt_paid',
      },
      {
        label: 'Commission Paid',
        field: 'comm_paid',
        sort_col: 'comm_paid',
      },
      {
        label: 'Commission Charged Back',
        field: 'comm_is_chrg_bk',
        sort_col: 'comm_is_chrg_bk',
      },
      {
        label: 'Edit',
        field: 'edit',
      },
    ],
    'production-details': [
      {
        label: 'Agent',
        field: 'agent',
        sort_col: 'av_user',
      },
      {
        label: 'Upline',
        field: 'upline',
        sort_col: 'av_upline',
      },
      {
        label: 'Submission Date',
        field: 'submission_date',
        sort_col: 'av_date',
      },
      {
        label: 'Effective Date',
        field: 'effective_date',
        sort_col: 'av_eff_date',
      },
      {
        label: 'Client',
        field: 'client',
        sort_col: 'av_client',
      },
      {
        label: 'State',
        field: 'state',
        sort_col: 'av_state',
      },
      {
        label: 'Carrier',
        field: 'carrier',
        sort_col: 'av_carrier',
      },
      {
        label: 'Policy # or D.O.B.',
        field: 'app',
        sort_col: 'av_appid',
      },
      {
        label: 'Premium',
        field: 'prem',
        sort_col: 'av_premium',
      },
      {
        label: 'Status / Disposition',
        field: 'status',
        sort_col: 'av_status',
      },
      {
        label: 'Annualized Volume (AV)',
        field: 'av',
        sort_col: 'av_premium_total',
      },
      {
        label: 'Points',
        field: 'points',
        sort_col: 'av_points',
      },
      {
        label: 'ACA Lives',
        field: 'lives',
        sort_col: 'lives',
      },
      {
        label: 'Coverage',
        field: 'cov',
        sort_col: 'av_coverage',
      },
      {
        label: 'Comments',
        field: 'comments',
        sort_col: 'av_comments',
      },
      {
        label: 'CSR',
        field: 'csr',
        sort_col: 'av_sub_agent',
      },
      {
        label: 'Product',
        field: 'product',
        sort_col: 'av_product',
      },
      {
        label: 'Lead Source',
        field: 'lead_source',
        sort_col: 'av_leadsource',
      },
      {
        label: 'Referral Partner',
        field: 'ref_partner',
        sort_col: 'refs.name',
      },
      {
        label: 'Referral Commission',
        field: 'ref_comm',
        sort_col: 'avrefs.amt_paid',
      },
      {
        label: 'Commission Paid',
        field: 'comm_paid',
        sort_col: 'comm_paid',
      },
      {
        label: 'Commission Charged Back',
        field: 'comm_is_chrg_bk',
        sort_col: 'comm_is_chrg_bk',
      },
      {
        label: 'Edit',
        field: 'edit',
      },
    ],
    'production-by-day': [
      {
        label: 'Agent',
        field: 'agent',
        sort_col: 'av_user',
      },
      {
        label: 'Upline',
        field: 'upline',
        sort_col: 'av_upline',
      },
      {
        label: 'Monday',
        field: 'mon',
        sort_col: 'monday',
      },
      {
        label: 'Tuesday',
        field: 'tues',
        sort_col: 'tuesday',
      },
      {
        label: 'Wednesday',
        field: 'wed',
        sort_col: 'wednesday',
      },
      {
        label: 'Thursday',
        field: 'thurs',
        sort_col: 'thursday',
      },
      {
        label: 'Friday',
        field: 'fri',
        sort_col: 'friday',
      },
      {
        label: 'Saturday',
        field: 'sat',
        sort_col: 'saturday',
      },
      {
        label: 'Sunday',
        field: 'sun',
        sort_col: 'sunday',
      },
      {
        label: 'Total',
        field: 'total',
        sort_col: 'total',
      },
    ],
    'production-by-carrier': [
      {
        label: 'Agent',
        field: 'av_user',
        sort_col: 'av_user',
      },
      {
        label: 'Upline',
        field: 'av_upline',
        sort_col: 'av_upline',
      },
    ],
    'production-by-lead-source': [
      {
        label: 'Agent',
        field: 'av_user',
        sort_col: 'av_user',
      },
      {
        label: 'Upline',
        field: 'av_upline',
        sort_col: 'av_upline',
      },
    ],
    'production-by-carrier-lead-source': [
      {
        label: 'Lead Source',
        field: 'av_leadsource',
        sort_col: 'av_leadsource',
      },
    ],
    'agent-statistics': [
      {
        label: 'Agent',
        field: 'agent',
        sort_col: 'av_user',
      },
      {
        label: 'Clients',
        field: 'clients',
        sort_col: 'total_clients',
      },
      {
        label: 'Apps',
        field: 'apps',
        sort_col: 'total_apps',
      },
      {
        label: 'Issued',
        field: 'issued',
        sort_col: 'issued_count',
      },
      {
        label: 'Transferred',
        field: 'transferred',
        sort_col: 'transferred_count',
      },
      {
        label: 'Declined',
        field: 'declined',
        sort_col: 'declined_count',
      },
      {
        label: 'Withdrawn',
        field: 'withdrawn',
        sort_col: 'withdrawn_count',
      },
      {
        label: 'Canceled',
        field: 'canceled',
        sort_col: 'cancelled_count',
      },
      {
        label: 'Apps Per Client',
        field: 'apps_per_client',
        sort_col: 'apps_per_client',
      },
      {
        label: 'Issued Apps (%.)',
        field: 'issued_apps',
        sort_col: 'issued_apps_percent',
      },
      {
        label: 'AV Per App',
        field: 'av_per_app',
        sort_col: 'av_per_app',
      },
      {
        label: 'AV Per Client',
        field: 'av_per_client',
        sort_col: 'av_per_client',
      },
      {
        label: 'Total Annualized Volume (AV)',
        field: 'total_av',
        sort_col: 'av_total',
      },
      {
        label: 'ACA Lives Per App',
        field: 'lives_per_app',
        sort_col: 'lives_per_app',
      },
      {
        label: 'ACA Lives Per Client',
        field: 'lives_per_client',
        sort_col: 'lives_per_client',
      },
      {
        label: 'Total ACA Lives',
        field: 'total_lives',
        sort_col: 'lives_total',
      },
      {
        label: 'Points Per App',
        field: 'points_per_app',
        sort_col: 'points_per_app',
      },
      {
        label: 'Points Per Client',
        field: 'points_per_client',
        sort_col: 'points_per_client',
      },
      {
        label: 'Points Total',
        field: 'total_points',
        sort_col: 'points_total',
      },
    ],
    'leader-statistics': [
      {
        label: 'Leader',
        field: 'av_user',
        sort_col: 'av_user',
      },
      {
        label: 'Agents with AV',
        field: 'agents_with_av',
      },
      {
        label: 'New Agents',
        field: 'new_agents',
      },
      {
        label: 'Agent Conversions',
        field: 'agent_conversions',
      },
      {
        label: 'Conversion Ratio',
        field: 'conversion_ratio',
      },
      {
        label: 'Signature Agent Completes',
        field: 'completed_agents',
      },
      {
        label: 'Total Agents',
        field: 'total_agents',
      },
      {
        label: 'Total Apps',
        field: 'total_apps',
      },
      {
        label: 'Total Clients',
        field: 'total_clients',
      },
      {
        label: 'Apps per Agent',
        field: 'apps_per_agent',
      },
      {
        label: 'Apps per Client',
        field: 'apps_per_client',
      },
      {
        label: 'Submitted',
        field: 'submitted_count',
      },
      {
        label: 'Issued',
        field: 'issued_count',
      },
      {
        label: 'Transferred',
        field: 'transferred_count',
      },
      {
        label: 'Declined',
        field: 'declined_count',
      },
      {
        label: 'Withdrawn',
        field: 'withdrawn_count',
      },
      {
        label: 'Cancelled',
        field: 'cancelled_count',
      },
      {
        label: 'AV Per Agent',
        field: 'av_per_agent',
      },
      {
        label: 'AV Per Client',
        field: 'av_per_client',
      },
      {
        label: 'AV Per App',
        field: 'av_per_app',
      },
      {
        label: 'Total Annualized Volume (AV)',
        field: 'av_total',
      },
      {
        label: 'Points Per Agent',
        field: 'points_per_agent',
      },
      {
        label: 'Points Per Client',
        field: 'points_per_client',
      },
      {
        label: 'Points Per App',
        field: 'points_per_app',
      },
      {
        label: 'Total Points',
        field: 'points_total',
      },
      {
        label: 'ACA Lives Per Agent',
        field: 'lives_per_agent',
      },
      {
        label: 'ACA Lives Per Client',
        field: 'lives_per_client',
      },
      {
        label: 'ACA Lives Per App',
        field: 'lives_per_app',
      },
      {
        label: 'Total ACA Lives',
        field: 'lives_total',
      },
    ],
    'downline-marketing-commitment': [
      {
        label: 'Agent',
        field: 'u_name',
        sort_col: 'u_name',
      },
      {
        label: 'Submitted Date',
        field: 'entry_date',
        sort_col: 'entry_date',
      },
      {
        label: 'Marketing Commitments',
        field: 'invested',
        sort_col: 'invested',
      },
    ],
    'downline-transaction-history': [
      {
        label: 'Transaction ID',
        field: 'transaction_id',
        sort_col: 'transaction_id',
      },
      { label: 'Agent Name', field: 'agent_name', sort_col: 'agent_name' },
      { label: 'Type', field: 'type', sort_col: 'type' },
      { label: 'Purpose', field: 'purpose', sort_col: 'purpose' },
      { label: 'Credit (Deposit)', field: 'credit', sort_col: 'credit' },
      { label: 'Debit (Withdrawl)', field: 'debit', sort_col: 'debit' },
      {
        label: 'Transaction Date',
        field: 'transact_at',
        sort_col: 'transact_at',
      },
      {
        label: 'Transaction Status',
        field: 'trans_state',
        sort_col: 'trans_state',
      },
      { label: 'Category', field: 'category', sort_col: 'category' },
      { label: 'Carrier', field: 'carrier_name' },
      { label: 'FMO', field: 'fmo_name' },
      { label: 'Donating Agent', field: 'doner_name' },
      { label: 'Lead Vendor', field: 'vendor_name' },
      { label: 'Has Expired?', field: 'is_expire' },
    ],
  },
  'division-production': {
    'production-details': [
      {
        label: 'Agent',
        field: 'agent',
        sort_col: 'av_user',
      },
      {
        label: 'Upline',
        field: 'upline',
        sort_col: 'av_upline',
      },
      {
        label: 'Submission Date',
        field: 'submission_date',
        sort_col: 'av_date',
      },
      {
        label: 'Effective Date',
        field: 'effective_date',
        sort_col: 'av_eff_date',
      },
      {
        label: 'Client',
        field: 'client',
        sort_col: 'av_client',
      },
      {
        label: 'State',
        field: 'state',
        sort_col: 'av_state',
      },
      {
        label: 'Carrier',
        field: 'carrier',
        sort_col: 'av_carrier',
      },
      {
        label: 'Policy # or D.O.B.',
        field: 'app',
        sort_col: 'av_appid',
      },
      {
        label: 'Premium',
        field: 'prem',
        sort_col: 'av_premium',
      },
      {
        label: 'Annualized Volume (AV)',
        field: 'av',
        sort_col: 'av_premium_total',
      },
      {
        label: 'Points',
        field: 'points',
        sort_col: 'av_points',
      },
      {
        label: 'ACA Lives',
        field: 'lives',
        sort_col: 'lives',
      },
      {
        label: 'Coverage',
        field: 'cov',
        sort_col: 'av_coverage',
      },
      {
        label: 'Comments',
        field: 'comments',
        sort_col: 'av_comments',
      },
      {
        label: 'CSR',
        field: 'csr',
        sort_col: 'av_sub_agent',
      },
      {
        label: 'Referral Partner',
        field: 'ref_partner',
        sort_col: 'refs.name',
      },
      {
        label: 'Edit',
        field: 'edit',
      },
    ],
    'production-by-day': [
      {
        label: 'Agent',
        field: 'agent',
        sort_col: 'av_user',
      },
      {
        label: 'Upline',
        field: 'upline',
        sort_col: 'av_upline',
      },
      {
        label: 'Monday',
        field: 'mon',
        sort_col: 'monday',
      },
      {
        label: 'Tuesday',
        field: 'tues',
        sort_col: 'tuesday',
      },
      {
        label: 'Wednesday',
        field: 'wed',
        sort_col: 'wednesday',
      },
      {
        label: 'Thursday',
        field: 'thurs',
        sort_col: 'thursday',
      },
      {
        label: 'Friday',
        field: 'fri',
        sort_col: 'friday',
      },
      {
        label: 'Saturday',
        field: 'sat',
        sort_col: 'saturday',
      },
      {
        label: 'Sunday',
        field: 'sun',
        sort_col: 'sunday',
      },
      {
        label: 'Total',
        field: 'total',
        sort_col: 'total',
      },
    ],
    'production-by-carrier': [
      {
        label: 'Agent',
        field: 'av_user',
      },
      {
        label: 'Upline',
        field: 'av_upline',
      },
    ],
    'production-by-lead-source': [
      {
        label: 'Agent',
        field: 'av_user',
      },
      {
        label: 'Upline',
        field: 'av_upline',
      },
    ],
    'production-by-carrier-lead-source': [
      {
        label: 'Lead Source',
        field: 'av_leadsource',
      },
    ],
    'agent-statistics': [
      {
        label: 'Agent',
        field: 'agent',
        sort_col: 'av_user',
      },
      {
        label: 'Clients',
        field: 'clients',
        sort_col: 'total_clients',
      },
      {
        label: 'Apps',
        field: 'apps',
        sort_col: 'total_apps',
      },
      {
        label: 'Issued',
        field: 'issued',
        sort_col: 'issued_count',
      },
      {
        label: 'Transferred',
        field: 'transferred',
        sort_col: 'transferred_count',
      },
      {
        label: 'Declined',
        field: 'declined',
        sort_col: 'declined_count',
      },
      {
        label: 'Withdrawn',
        field: 'withdrawn',
        sort_col: 'withdrawn_count',
      },
      {
        label: 'Canceled',
        field: 'canceled',
        sort_col: 'cancelled_count',
      },
      {
        label: 'Apps Per Client',
        field: 'apps_per_client',
        sort_col: 'apps_per_client',
      },
      {
        label: 'Issued Apps (%.)',
        field: 'issued_apps',
        sort_col: 'issued_apps_percent',
      },
      {
        label: 'AV Per App',
        field: 'av_per_app',
        sort_col: 'av_per_app',
      },
      {
        label: 'AV Per Client',
        field: 'av_per_client',
        sort_col: 'av_per_client',
      },
      {
        label: 'Total Annualized Volume (AV)',
        field: 'total_av',
        sort_col: 'av_total',
      },
      {
        label: 'Points Per App',
        field: 'points_per_app',
        sort_col: 'points_per_app',
      },
      {
        label: 'Points Per Client',
        field: 'points_per_client',
        sort_col: 'points_per_client',
      },
      {
        label: 'Points Total',
        field: 'total_points',
        sort_col: 'points_total',
      },
      {
        label: 'ACA Lives Per App',
        field: 'lives_per_app',
        sort_col: 'lives_per_app',
      },
      {
        label: 'ACA Lives Per Client',
        field: 'lives_per_client',
        sort_col: 'lives_per_client',
      },
      {
        label: 'Total ACA Lives',
        field: 'total_lives',
        sort_col: 'lives_total',
      },
    ],
    'leader-statistics': [
      {
        label: 'Leader',
        field: 'u_name',
      },
      {
        label: 'Agents with AV',
        field: 'agents_with_av',
      },
      {
        label: 'New Agents',
        field: 'new_agents',
      },
      {
        label: 'Agent Conversions',
        field: 'agent_conversions',
      },
      {
        label: 'Conversion Ratio',
        field: 'conversion_ratio',
      },
      {
        label: 'Signature Agent Completes',
        field: 'signature_agent_complete',
      },
      {
        label: 'Total Agents',
        field: 'total_agents',
      },
      {
        label: 'Total Apps',
        field: 'total_apps',
      },
      {
        label: 'Total Clients',
        field: 'total_clients',
      },
      {
        label: 'Apps per Agent',
        field: 'apps_per_agent',
      },
      {
        label: 'Apps per Client',
        field: 'apps_per_client',
      },
      {
        label: 'Submitted',
        field: 'submitted_count',
      },
      {
        label: 'Issued',
        field: 'issued_count',
      },
      {
        label: 'Transferred',
        field: 'transferred_count',
      },
      {
        label: 'Declined',
        field: 'declined_count',
      },
      {
        label: 'Withdrawn',
        field: 'withdrawn_count',
      },
      {
        label: 'Cancelled',
        field: 'cancelled_count',
      },
      {
        label: 'AV Per Agent',
        field: 'av_per_agent',
      },
      {
        label: 'AV Per Client',
        field: 'av_per_client',
      },
      {
        label: 'AV Per App',
        field: 'av_per_app',
      },
      {
        label: 'Total Annualized Volume (AV)',
        field: 'av_total',
      },
      {
        label: 'Points Per Agent',
        field: 'points_per_agent',
      },
      {
        label: 'Points Per Client',
        field: 'points_per_client',
      },
      {
        label: 'Points Per App',
        field: 'points_per_app',
      },
      {
        label: 'Total Points',
        field: 'points_total',
      },
      {
        label: 'ACA Lives Per Agent',
        field: 'lives_per_agent',
      },
      {
        label: 'ACA Lives Per Client',
        field: 'lives_per_client',
      },
      {
        label: 'ACA Lives Per App',
        field: 'lives_per_app',
      },
      {
        label: 'Total ACA Lives',
        field: 'lives_total',
      },
    ],
    'production-by-lead-source-aggregate': [
      {
        label: 'Lead Source',
        field: 'av_leadsource',
        sort_col: 'av_leadsource',
      },
      {
        label: 'Med Advantage Lives (MA)',
        field: 'med_adv',
        sort_col: 'med_adv',
      },
      {
        label: 'ACA Lives',
        field: 'lives',
        sort_col: 'lives',
      },
      {
        label: 'Points',
        field: 'points',
        sort_col: 'av_points',
      },
      {
        label: 'Annualized Volume (AV)',
        field: 'av_premium_total',
        sort_col: 'av_premium_total',
      },
    ],
  },
  'ba-production': {
    'production-details-bst': [
      {
        label: 'Agent',
        field: 'agent',
        sort_col: 'av_user',
      },
      {
        label: 'Upline',
        field: 'upline',
        sort_col: 'av_upline',
      },
      {
        label: 'Submission Date',
        field: 'submission_date',
        sort_col: 'av_date',
      },
      {
        label: 'Effective Date',
        field: 'effective_date',
        sort_col: 'av_eff_date',
      },
      {
        label: 'Client',
        field: 'client',
        sort_col: 'av_client',
      },
      {
        label: 'State',
        field: 'state',
        sort_col: 'av_state',
      },
      {
        label: 'Carrier',
        field: 'carrier',
        sort_col: 'av_carrier',
      },
      {
        label: 'Policy # or D.O.B.',
        field: 'app',
        sort_col: 'av_appid',
      },
      {
        label: 'Premium',
        field: 'prem',
        sort_col: 'av_premium',
      },
      {
        label: 'Annualized Volume (AV)',
        field: 'av',
        sort_col: 'av_premium_total',
      },
      {
        label: 'Points',
        field: 'points',
        sort_col: 'av_points',
      },
      {
        label: 'ACA Lives',
        field: 'lives',
        sort_col: 'lives',
      },
      {
        label: 'Coverage',
        field: 'cov',
        sort_col: 'av_coverage',
      },
      {
        label: 'Comments',
        field: 'comments',
        sort_col: 'av_comments',
      },
      {
        label: 'CSR',
        field: 'csr',
        sort_col: 'av_sub_agent',
      },
      {
        label: 'Referral Partner',
        field: 'ref_partner',
        sort_col: 'refs.name',
      },
      {
        label: 'Edit',
        field: 'edit',
      },
    ],
    'production-details': [
      {
        label: 'Agent',
        field: 'agent',
        sort_col: 'av_user',
      },
      {
        label: 'Upline',
        field: 'upline',
        sort_col: 'av_upline',
      },
      {
        label: 'Submission Date',
        field: 'submission_date',
        sort_col: 'av_date',
      },
      {
        label: 'Effective Date',
        field: 'effective_date',
        sort_col: 'av_eff_date',
      },
      {
        label: 'Client',
        field: 'client',
        sort_col: 'av_client',
      },
      {
        label: 'State',
        field: 'state',
        sort_col: 'av_state',
      },
      {
        label: 'Carrier',
        field: 'carrier',
        sort_col: 'av_carrier',
      },
      {
        label: 'Policy # or D.O.B.',
        field: 'app',
        sort_col: 'av_appid',
      },
      {
        label: 'Premium',
        field: 'prem',
        sort_col: 'av_premium',
      },
      {
        label: 'Annualized Volume (AV)',
        field: 'av',
        sort_col: 'av_premium_total',
      },
      {
        label: 'Points',
        field: 'points',
        sort_col: 'av_points',
      },
      {
        label: 'ACA Lives',
        field: 'lives',
        sort_col: 'lives',
      },
      {
        label: 'Coverage',
        field: 'cov',
        sort_col: 'av_coverage',
      },
      {
        label: 'Comments',
        field: 'comments',
        sort_col: 'av_comments',
      },
      {
        label: 'CSR',
        field: 'csr',
        sort_col: 'av_sub_agent',
      },
      {
        label: 'Referral Partner',
        field: 'ref_partner',
        sort_col: 'refs.name',
      },
      {
        label: 'Edit',
        field: 'edit',
      },
    ],
    'production-by-day': [
      {
        label: 'Agent',
        field: 'agent',
        sort_col: 'av_user',
      },
      {
        label: 'Upline',
        field: 'upline',
        sort_col: 'av_upline',
      },
      {
        label: 'Monday',
        field: 'mon',
        sort_col: 'monday',
      },
      {
        label: 'Tuesday',
        field: 'tues',
        sort_col: 'tuesday',
      },
      {
        label: 'Wednesday',
        field: 'wed',
        sort_col: 'wednesday',
      },
      {
        label: 'Thursday',
        field: 'thurs',
        sort_col: 'thursday',
      },
      {
        label: 'Friday',
        field: 'fri',
        sort_col: 'friday',
      },
      {
        label: 'Saturday',
        field: 'sat',
        sort_col: 'saturday',
      },
      {
        label: 'Sunday',
        field: 'sun',
        sort_col: 'sunday',
      },
      {
        label: 'Total',
        field: 'total',
        sort_col: 'total',
      },
    ],
    'production-by-carrier': [
      {
        label: 'Agent',
        field: 'av_user',
      },
      {
        label: 'Upline',
        field: 'av_upline',
      },
    ],
    'production-by-lead-source': [
      {
        label: 'Agent',
        field: 'av_user',
      },
      {
        label: 'Upline',
        field: 'av_upline',
      },
    ],
    'production-by-carrier-lead-source': [
      {
        label: 'Lead Source',
        field: 'av_leadsource',
      },
    ],
    'csr-statistics': [
      {
        label: 'Agent',
        field: 'agent',
        sort_col: 'av_user',
      },
      {
        label: 'Upline',
        field: 'upline',
        sort_col: 'av_upline',
      },
      {
        label: 'Clients',
        field: 'clients',
        sort_col: 'total_clients',
      },
      {
        label: 'Apps',
        field: 'apps',
        sort_col: 'total_apps',
      },
      {
        label: 'Issued',
        field: 'issued',
        sort_col: 'issued_count',
      },
      {
        label: 'Transferred',
        field: 'transferred',
        sort_col: 'transferred_count',
      },
      {
        label: 'Declined',
        field: 'declined',
        sort_col: 'declined_count',
      },
      {
        label: 'Withdrawn',
        field: 'withdrawn',
        sort_col: 'withdrawn_count',
      },
      {
        label: 'Canceled',
        field: 'canceled',
        sort_col: 'cancelled_count',
      },
      {
        label: 'Apps Per Client',
        field: 'apps_per_client',
        sort_col: 'apps_per_client',
      },
      {
        label: 'Issued Apps (%.)',
        field: 'issued_apps',
        sort_col: 'issued_apps_percent',
      },
      {
        label: 'AV Per App',
        field: 'av_per_app',
        sort_col: 'av_per_app',
      },
      {
        label: 'AV Per Client',
        field: 'av_per_client',
        sort_col: 'av_per_client',
      },
      {
        label: 'Total Annualized Volume (AV)',
        field: 'total_av',
        sort_col: 'av_total',
      },
      {
        label: 'Points Per App',
        field: 'points_per_app',
        sort_col: 'points_per_app',
      },
      {
        label: 'Points Per Client',
        field: 'points_per_client',
        sort_col: 'points_per_client',
      },
      {
        label: 'Points Total',
        field: 'total_points',
        sort_col: 'points_total',
      },
      {
        label: 'ACA Lives Per App',
        field: 'lives_per_app',
        sort_col: 'lives_per_app',
      },
      {
        label: 'ACA Lives Per Client',
        field: 'lives_per_client',
        sort_col: 'lives_per_client',
      },
      {
        label: 'Total ACA Lives',
        field: 'total_lives',
        sort_col: 'lives_total',
      },
    ],
    'production-by-lead-source-aggregate': [
      {
        label: 'Lead Source',
        field: 'av_leadsource',
        sort_col: 'av_leadsource',
      },
      {
        label: 'Med Advantage Lives (MA)',
        field: 'med_adv',
        sort_col: 'med_adv',
      },
      {
        label: 'ACA Lives',
        field: 'lives',
        sort_col: 'lives',
      },
      {
        label: 'Points',
        field: 'points',
        sort_col: 'av_points',
      },
      {
        label: 'Annualized Volume (AV)',
        field: 'av_premium_total',
        sort_col: 'av_premium_total',
      },
    ],
  },
  'agency-production': {
    'production-by-lead-source': [
      {
        label: 'Agent',
        field: 'av_user',
      },
      {
        label: 'Upline',
        field: 'av_upline',
      },
    ],
    'production-by-carrier-lead-source': [
      {
        label: 'Lead Source',
        field: 'av_leadsource',
      },
    ],
    'production-by-carrier': [
      {
        label: 'Agent',
        field: 'av_user',
      },
      {
        label: 'Upline',
        field: 'av_upline',
      },
    ],
    'production-by-lead-source-aggregate': [
      {
        label: 'Lead Source',
        field: 'av_leadsource',
        sort_col: 'av_leadsource',
      },
      {
        label: 'Med Advantage Lives (MA)',
        field: 'med_adv',
        sort_col: 'med_adv',
      },
      {
        label: 'ACA Lives',
        field: 'lives',
        sort_col: 'lives',
      },
      {
        label: 'Points',
        field: 'points',
        sort_col: 'av_points',
      },
      {
        label: 'Annualized Volume (AV)',
        field: 'av_premium_total',
        sort_col: 'av_premium_total',
      },
    ],
    'recruiting-lead-sources': [
      { label: 'Agent Name', field: 'agent_name', sort_col: 'agent_name' },
      { label: 'Enrolled Date', field: 'u_enrolled', sort_col: 'u_enrolled' },
      {
        label: 'Recruiting Lead Source',
        field: 'ls_name',
        sort_col: 'ls_name',
      },
      { label: 'Upline Name', field: 'upline_name', sort_col: 'upline_name' },
      {
        label: 'Recruiter Name',
        field: 'recruiter_name',
        sort_col: 'recruiter_name',
      },
      { label: 'Division', field: 'd_name', sort_col: 'd_name' },
      {
        label: 'Agent Usertype',
        field: 'displayname',
        sort_col: 'displayname',
      },
      {
        label: 'Has Completed Onboarding',
        field: 'has_onboarded',
        sort_col: 'has_onboarded',
      },
      { label: 'Agent ID', field: 'id', sort_col: 'id' },
    ],
  },
  'agency-wallets': {
    'agency-ledger-balance': [
      { label: 'Agent', field: 'agent_name', sort_col: 'agent_name' },
      {
        label: 'Current Wallet Balance',
        field: 'balance',
        sort_col: 'balance',
      },
      {
        label: 'Ending Wallet Balance (Period)',
        field: 'period_ending_balance',
        sort_col: 'period_ending_balance',
      },
      {
        label: 'Credits (Period)',
        field: 'period_credits',
        sort_col: 'period_credits',
      },
      {
        label: 'Debits (Period)',
        field: 'period_debits',
        sort_col: 'period_debits',
      },
      {
        label: 'Number of Transactions (Period)',
        field: 'period_transactions',
        sort_col: 'period_transactions',
      },
      { label: 'Ledger ID', field: 'id', sort_col: 'ledger_id' },
      {
        label: 'Date of Last Activity',
        field: 'recent_transaction',
        sort_col: 'recent_transaction',
      },
      {
        label: 'Date of Creation',
        field: 'created_at',
        sort_col: 'created_at',
      },
    ],
    'agency-ledger-balance-all-time': [
      { label: 'Agent', field: 'agent_name', sort_col: 'agent_name' },
      { label: 'Balance', field: 'balance', sort_col: 'balance' },
      { label: 'Ledger ID', field: 'id', sort_col: 'ledger_id' },
      {
        label: 'Date of Last Activity',
        field: 'recent_transaction',
        sort_col: 'recent_transaction',
      },
      {
        label: 'Date of Creation',
        field: 'created_at',
        sort_col: 'created_at',
      },
    ],
    'agency-transaction-history': [
      { label: 'Agent Name', field: 'agent_name', sort_col: 'agent_name' },
      { label: 'Type | Category', field: 'type_category' },
      // { label: 'Type', field: 'type', sort_col: 'type' },
      { label: 'Credit (Deposit)', field: 'credit', sort_col: 'credit' },
      { label: 'Debit (Withdrawl)', field: 'debit', sort_col: 'debit' },
      {
        label: 'Transaction Date',
        field: 'transact_at',
        sort_col: 'transact_at',
      },
      {
        label: 'Transaction Status',
        field: 'trans_state',
        sort_col: 'trans_state',
      },
      // { label: 'Category', field: 'category', sort_col: 'category' },
      // { label: 'Carrier', field: 'carrier_name', sort_col: 'carrier_name' },
      // { label: 'FMO', field: 'fmo_name', sort_col: 'fmo_name' },
      // { label: 'Donating Agent', field: 'doner_name', sort_col: 'doner_name' },
      {
        label: 'Carrier | FMO | Agent',
        field: 'carrier_fmo_doner',
        sort_col: 'carrier_fmo_doner',
      },
      { label: 'Lead Vendor', field: 'vendor_name', sort_col: 'vendor_name' },
      { label: 'Has Expired?', field: 'expiration', sort_col: 'expiration' },
      {
        label: 'Transaction ID',
        field: 'transaction_id',
        sort_col: 'transaction_id',
      },
      { label: 'Purpose', field: 'purpose', sort_col: 'purpose' },
    ],
    'agency-transaction-history-all-time': [
      { label: 'Agent Name', field: 'agent_name', sort_col: 'agent_name' },
      {
        label: 'Transaction ID',
        field: 'transaction_id',
        sort_col: 'transaction_id',
      },
      { label: 'Type', field: 'type', sort_col: 'type' },
      { label: 'Purpose', field: 'purpose', sort_col: 'purpose' },
      { label: 'Credit (Deposit)', field: 'credit', sort_col: 'credit' },
      { label: 'Debit (Withdrawl)', field: 'debit', sort_col: 'debit' },
      {
        label: 'Transaction Date',
        field: 'transact_at',
        sort_col: 'transact_at',
      },
      {
        label: 'Transaction Status',
        field: 'trans_state',
        sort_col: 'trans_state',
      },
      { label: 'Category', field: 'category', sort_col: 'category' },
      { label: 'Carrier', field: 'carrier_name', sort_col: 'carrier_name' },
      { label: 'FMO', field: 'fmo_name', sort_col: 'fmo_name' },
      { label: 'Donating Agent', field: 'doner_name', sort_col: 'doner_name' },
      { label: 'Lead Vendor', field: 'vendor_name', sort_col: 'vendor_name' },
      { label: 'Has Expired?', field: 'is_expire', sort_col: 'is_expire' },
    ],
    'agency-auto-awarded-transactions': [
      { label: 'Agent Name', field: 'agent_name', sort_col: 'agent_name' },
      {
        label: 'Transaction ID',
        field: 'transaction_id',
        sort_col: 'transaction_id',
      },
      { label: 'Purpose', field: 'purpose', sort_col: 'purpose' },
      { label: 'Credit (Deposit)', field: 'credit', sort_col: 'credit' },
      {
        label: 'Transaction Date',
        field: 'transact_at',
        sort_col: 'transact_at',
      },
      { label: 'Category', field: 'category', sort_col: 'category' },
      {
        label: 'Automated Award ID',
        field: 'event_log_id',
        sort_col: 'event_log_id',
      },
    ],
    'av-award-outcomes': [
      { label: 'Agent Name', field: 'agent_name', sort_col: 'agent_name' },
      { label: 'Client Name', field: 'av_client', sort_col: 'av_client' },
      { label: 'Outcome', field: 'outcome', sort_col: 'outcome' },
      {
        label: 'Cummulative Points',
        field: 'cumm_points',
        sort_col: 'cumm_points',
      },
      { label: 'Award Level', field: 'award_level', sort_col: 'award_level' },
      { label: 'AV Date', field: 'av_date', sort_col: 'av_date' },
      { label: 'Points', field: 'av_points', sort_col: 'av_points' },
      { label: 'Policy # or DOB', field: 'av_appid', sort_col: 'av_appid' },
      { label: 'AV ID', field: 'av_id', sort_col: 'av_id' },
      { label: 'CSR Name', field: 'csr_name', sort_col: 'csr_name' },
      { label: 'View', field: 'av_link', sort_col: 'av_link' },
    ],
  },
  'career-totals': [
    {
      label: 'Career Agent',
      field: 'name',
      sort_col: 'name',
    },
    {
      label: 'Total Points',
      field: 'total_av_points_prev',
      sort_col: 'total_av_points_prev',
    },
    {
      label: 'Total Points',
      field: 'total_av_points_curr',
      sort_col: 'total_av_points_curr',
    },
    {
      label: 'Points Growth',
      field: 'total_av_points_growth',
      sort_col: 'total_av_points_growth',
    },
    {
      label: 'Total Annualized Volume (AV)',
      field: 'total_av_premium_prev',
      sort_col: 'total_av_premium_prev',
    },
    {
      label: 'Total Annualized Volume (AV)',
      field: 'total_av_premium_curr',
      sort_col: 'total_av_premium_curr',
    },
    {
      label: 'AV Growth',
      field: 'total_av_premium_growth',
      sort_col: 'total_av_premium_growth',
    },
    {
      label: 'Total ACA Lives',
      field: 'total_av_lives_prev',
      sort_col: 'total_av_lives_prev',
    },
    {
      label: 'Total ACA Lives',
      field: 'total_av_lives_curr',
      sort_col: 'total_av_lives_curr',
    },
    {
      label: 'ACA Lives Growth',
      field: 'total_av_lives_growth',
      sort_col: 'total_av_lives_growth',
    },
    {
      label: 'Total Med Advantage',
      field: 'total_med_adv_prev',
      sort_col: 'total_med_adv_prev',
    },
    {
      label: 'Total Med Advantage',
      field: 'total_med_adv_curr',
      sort_col: 'total_med_adv_curr',
    },
    {
      label: 'Med Advantage Growth',
      field: 'total_med_adv_growth',
      sort_col: 'total_med_adv_growth',
    },
    {
      label: 'View Details',
      field: 'view',
    },
  ],
  'district-totals': [
    {
      label: 'District Leader',
      field: 'name',
      sort_col: 'name',
    },
    {
      label: 'Total Points',
      field: 'total_av_points_prev',
      sort_col: 'total_av_points_prev',
    },
    {
      label: 'Total Points',
      field: 'total_av_points_curr',
      sort_col: 'total_av_points_curr',
    },
    {
      label: 'Points Growth',
      field: 'total_av_points_growth',
      sort_col: 'total_av_points_growth',
    },
    {
      label: 'Total Annualized Volume (AV)',
      field: 'total_av_premium_prev',
      sort_col: 'total_av_premium_prev',
    },
    {
      label: 'Total Annualized Volume (AV)',
      field: 'total_av_premium_curr',
      sort_col: 'total_av_premium_curr',
    },
    {
      label: 'AV Growth',
      field: 'total_av_premium_growth',
      sort_col: 'total_av_premium_growth',
    },
    {
      label: 'Total ACA Lives',
      field: 'total_av_lives_prev',
      sort_col: 'total_av_lives_prev',
    },
    {
      label: 'Total ACA Lives',
      field: 'total_av_lives_curr',
      sort_col: 'total_av_lives_curr',
    },
    {
      label: 'ACA Lives Growth',
      field: 'total_av_lives_growth',
      sort_col: 'total_av_lives_growth',
    },
    {
      label: 'Total Med Advantage',
      field: 'total_med_adv_prev',
      sort_col: 'total_med_adv_prev',
    },
    {
      label: 'Total Med Advantage',
      field: 'total_med_adv_curr',
      sort_col: 'total_med_adv_curr',
    },
    {
      label: 'Med Advantage Growth',
      field: 'total_med_adv_growth',
      sort_col: 'total_med_adv_growth',
    },
    {
      label: 'View Details',
      field: 'view',
    },
  ],
  'region-totals': [
    {
      label: 'Regional Leader',
      field: 'name',
      sort_col: 'name',
    },
    {
      label: 'Total Points',
      field: 'total_av_points_prev',
      sort_col: 'total_av_points_prev',
    },
    {
      label: 'Total Points',
      field: 'total_av_points_curr',
      sort_col: 'total_av_points_curr',
    },
    {
      label: 'Points Growth',
      field: 'total_av_points_growth',
      sort_col: 'total_av_points_growth',
    },
    {
      label: 'Total Annualized Volume (AV)',
      field: 'total_av_premium_prev',
      sort_col: 'total_av_premium_prev',
    },
    {
      label: 'Total Annualized Volume (AV)',
      field: 'total_av_premium_curr',
      sort_col: 'total_av_premium_curr',
    },
    {
      label: 'AV Growth',
      field: 'total_av_premium_growth',
      sort_col: 'total_av_premium_growth',
    },
    {
      label: 'Total ACA Lives',
      field: 'total_av_lives_prev',
      sort_col: 'total_av_lives_prev',
    },
    {
      label: 'Total ACA Lives',
      field: 'total_av_lives_curr',
      sort_col: 'total_av_lives_curr',
    },
    {
      label: 'ACA Lives Growth',
      field: 'total_av_lives_growth',
      sort_col: 'total_av_lives_growth',
    },
    {
      label: 'Total Med Advantage',
      field: 'total_med_adv_prev',
      sort_col: 'total_med_adv_prev',
    },
    {
      label: 'Total Med Advantage',
      field: 'total_med_adv_curr',
      sort_col: 'total_med_adv_curr',
    },
    {
      label: 'Med Advantage Growth',
      field: 'total_med_adv_growth',
      sort_col: 'total_med_adv_growth',
    },
    {
      label: 'View Details',
      field: 'view',
    },
  ],
  'division-totals': [
    {
      label: 'Division',
      field: 'd_name',
      sort_col: 'd_name',
    },
    {
      label: 'Total Points',
      field: 'total_av_points_prev',
      sort_col: 'total_av_points_prev',
    },
    {
      label: 'Total Points',
      field: 'total_av_points_curr',
      sort_col: 'total_av_points_curr',
    },
    {
      label: 'Points Growth',
      field: 'total_av_points_growth',
      sort_col: 'total_av_points_growth',
    },
    {
      label: 'Total Annualized Volume (AV)',
      field: 'total_av_premium_prev',
      sort_col: 'total_av_premium_prev',
    },
    {
      label: 'Total Annualized Volume (AV)',
      field: 'total_av_premium_curr',
      sort_col: 'total_av_premium_curr',
    },
    {
      label: 'AV Growth',
      field: 'total_av_premium_growth',
      sort_col: 'total_av_premium_growth',
    },
    {
      label: 'Total ACA Lives',
      field: 'total_av_lives_prev',
      sort_col: 'total_av_lives_prev',
    },
    {
      label: 'Total ACA Lives',
      field: 'total_av_lives_curr',
      sort_col: 'total_av_lives_curr',
    },
    {
      label: 'ACA Lives Growth',
      field: 'total_av_lives_growth',
      sort_col: 'total_av_lives_growth',
    },
    {
      label: 'Total Med Advantage',
      field: 'total_med_adv_prev',
      sort_col: 'total_med_adv_prev',
    },
    {
      label: 'Total Med Advantage',
      field: 'total_med_adv_curr',
      sort_col: 'total_med_adv_curr',
    },
    {
      label: 'Med Advantage Growth',
      field: 'total_med_adv_growth',
      sort_col: 'total_med_adv_growth',
    },
    {
      label: 'View Details',
      field: 'view',
    },
  ],
  referrals: {
    'my-recruiting-lead-sources': [
      { label: 'Agent Name', field: 'agent_name', sort_col: 'agent_name' },
      { label: 'Enrolled Date', field: 'u_enrolled', sort_col: 'u_enrolled' },
      {
        label: 'Recruiting Lead Source',
        field: 'ls_name',
        sort_col: 'ls_name',
      },
      { label: 'Upline Name', field: 'upline_name', sort_col: 'upline_name' },
      { label: 'Division', field: 'd_name', sort_col: 'd_name' },
      {
        label: 'Agent Usertype',
        field: 'displayname',
        sort_col: 'displayname',
      },
      {
        label: 'Has Completed Onboarding',
        field: 'has_onboarded',
        sort_col: 'has_onboarded',
      },
      { label: 'Agent ID', field: 'id', sort_col: 'id' },
    ],
    'referral-production-detail': [
      {
        label: 'Referral Partner',
        field: 'referral_partner',
        sort_col: 'referral_partners.name',
      },
      {
        label: 'Date',
        field: 'date',
        sort_col: 'av_date',
      },
      {
        label: 'Client',
        field: 'client',
        sort_col: 'av_client',
      },
      {
        label: 'State',
        field: 'state',
        sort_col: 'av_state',
      },
      {
        label: 'Carrier',
        field: 'carrier',
        sort_col: 'av_carrier',
      },
      {
        label: 'Policy # Or D.O.B.',
        field: 'app',
        sort_col: 'av_appid',
      },
      {
        label: 'App Status',
        field: 'status',
        sort_col: 'av_status',
      },
      {
        label: 'Premium',
        field: 'prem',
        sort_col: 'av_premium',
      },
      {
        label: 'Annualized Volume (AV)',
        field: 'av',
        sort_col: 'av_premium_total',
      },
      {
        label: 'Points',
        field: 'points',
        sort_col: 'av_points',
      },
      {
        label: 'ACA Lives',
        field: 'lives',
        sort_col: 'lives',
      },
      {
        label: 'Coverage',
        field: 'cov',
        sort_col: 'av_coverage',
      },
      {
        label: 'Comments',
        field: 'comments',
        sort_col: 'av_comments',
      },
      {
        label: 'CSR',
        field: 'csr',
        sort_col: 'av_sub_agent',
      },
      {
        label: 'Edit',
        field: 'edit',
      },
    ],
    'referral-production-total': [
      {
        label: 'Referral Partner',
        field: 'referral_partner',
      },
      {
        label: 'Total Apps',
        field: 'apps',
      },
      {
        label: 'Total Clients',
        field: 'clients',
      },
      {
        label: 'Premium',
        field: 'av',
      },
      {
        label: 'Points',
        field: 'points',
      },
      {
        label: 'ACA Lives',
        field: 'lives',
      },
      {
        label: 'Is Licensed Agent?',
        field: 'is_licensed',
      },
      {
        label: 'Products Sold',
        field: 'products_sold',
      },
    ],
    'referral-payments': [
      {
        label: 'Referral Partner',
        field: 'referral_partner',
      },
      {
        label: 'Client',
        field: 'client',
      },
      {
        label: 'App Date',
        field: 'av_date',
      },
      {
        label: 'Paid Date',
        field: 'paid_date',
      },
      {
        label: 'Amount Total',
        field: 'amt_total',
      },
      {
        label: 'Amount Paid',
        field: 'amt_paid',
      },
      {
        label: 'Amount Balance',
        field: 'amt_balance',
      },
      {
        label: 'App Description',
        field: 'description',
      },
      {
        label: 'Referral Payment Notes',
        field: 'notes',
      },
      {
        label: 'Edit',
        field: 'edit',
      },
    ],
    'referral-partner-w9': [
      {
        label: 'Referral Partner',
        field: 'referral_partner',
      },
      {
        label: 'Most Recent W9',
        field: 'recent_w9',
      },
      {
        label: 'Amount Totals',
        field: 'amt_total',
      },
      {
        label: 'Withholding Payment?',
        field: 'withholding',
      },
    ],
  },
  contracting: {
    'downline-contracting': [
      {
        label: 'Agent Name',
        field: 'agent_name',
        sort_col: 'agent_name',
      },
      {
        label: 'Agent Id',
        field: 'id',
        sort_col: 'id',
      },
      {
        label: 'Upline Name',
        field: 'upline_name',
        sort_col: 'upline_name',
      },
      {
        label: 'Carrier',
        field: 'carrier_name',
        sort_col: 'carrier_name',
      },
      {
        label: 'Agent Writing Number',
        field: 'agent_code',
        sort_col: 'agent_code',
      },
      {
        label: 'Pwk Sent To Agent',
        field: 'pwk_sent_to_agent_at',
        sort_col: 'pwk_sent_to_agent_at',
      },
      {
        label: 'Pwk Signed By Agent',
        field: 'pwk_signed_at',
        sort_col: 'pwk_signed_at',
      },
      {
        label: 'Pwk Sent To Carrier',
        field: 'pwk_sent_to_carrier_at',
        sort_col: 'pwk_sent_to_carrier_at',
      },
      {
        label: 'Completed At',
        field: 'pwk_completed_at',
        sort_col: 'pwk_completed_at',
      },
    ],
  },
  leaders: {
    'kpi-performance': [
      {
        label: 'Agent Name',
        field: 'agent_name',
        sort_col: 'agent_name',
      },
      {
        label: 'Calls',
        field: 'calls',
        sort_col: 'calls',
      },
      {
        label: 'Events Attended',
        field: 'events_attended',
        sort_col: 'events_attended',
      },
      {
        label: 'Spoken To',
        field: 'spoken_to',
        sort_col: 'spoken_to',
      },
      {
        label: 'Fact Finders',
        field: 'fact_finders',
        sort_col: 'fact_finders',
      },
      {
        label: 'Appts Set',
        field: 'appts_set',
        sort_col: 'appts_set',
      },
      {
        label: 'Presentations Offered',
        field: 'presentations_offered',
        sort_col: 'presentations_offered',
      },
      {
        label: 'Total Health AV',
        field: 'total_health_av',
        sort_col: 'total_health_av',
      },
      {
        label: 'Total ACA Lives AV',
        field: 'total_lives_av',
        sort_col: 'total_lives_av',
      },
      {
        label: 'Total Points',
        field: 'total_points',
        sort_col: 'total_points',
      },
      {
        label: 'Referrals Requested',
        field: 'referrals_requested',
        sort_col: 'referrals_requested',
      },
      {
        label: 'Life Quotes',
        field: 'life_quotes',
        sort_col: 'life_quotes',
      },
      {
        label: 'Total Clients',
        field: 'total_clients',
        sort_col: 'total_clients',
      },
      {
        label: 'Total Apps',
        field: 'total_apps',
        sort_col: 'total_apps',
      },
      {
        label: 'Total ACA Lives',
        field: 'total_lives',
        sort_col: 'total_lives',
      },
      {
        label: 'Total Hours Worked',
        field: 'total_hours_works',
        sort_col: 'total_hours_works',
      },
      {
        label: 'Invested',
        field: 'invested',
        sort_col: 'invested',
      },
    ],
    'recruiting-lead-sources': [
      { label: 'Agent Name', field: 'agent_name', sort_col: 'agent_name' },
      { label: 'Enrolled Date', field: 'u_enrolled', sort_col: 'u_enrolled' },
      {
        label: 'Recruiting Lead Source',
        field: 'ls_name',
        sort_col: 'ls_name',
      },
      { label: 'Upline Name', field: 'upline_name', sort_col: 'upline_name' },
      {
        label: 'Recruiter Name',
        field: 'recruiter_name',
        sort_col: 'recruiter_name',
      },
      { label: 'Division', field: 'd_name', sort_col: 'd_name' },
      {
        label: 'Agent Usertype',
        field: 'displayname',
        sort_col: 'displayname',
      },
      {
        label: 'Has Completed Onboarding',
        field: 'has_onboarded',
        sort_col: 'has_onboarded',
      },
      { label: 'Agent ID', field: 'id', sort_col: 'id' },
    ],
  },
  'agent-ledgers': {
    'agent-ledger-detail': [
      { label: 'Category', field: 'category' },
      { label: 'Type', field: 'type' },
      { label: 'Purpose', field: 'purpose' },
      { label: 'Status', field: 'trans_state' },
      { label: 'Credit (Deposit)', field: 'credit' },
      { label: 'Debit (Withdrawl)', field: 'debit' },
      { label: 'Transaction Date', field: 'transact_at' },
      { label: 'Transaction ID', field: 'transaction_id' },
      { label: 'Carrier', field: 'carrier_name' },
      { label: 'FMO', field: 'fmo_name' },
      { label: 'Has Expired?', field: 'is_expire' },
    ],
  },
  'broker-support': {
    'agent-npn-list': [
      { label: 'Agent Name', field: 'agent_name', sort_col: 'agent_name' },
      { label: 'Upline Name', field: 'upline_name', sort_col: 'upline_name' },
      { label: 'NPN Number', field: 'npn', sort_col: 'npn' },
      { label: 'Email', field: 'email', sort_col: 'email' },
      { label: 'Usertype', field: 'usertype', sort_col: 'usertype' },
      {
        label: 'commission Level',
        field: 'commission_level',
        sort_col: 'commission_level',
      },
    ],
    'nsm-weekly': [
      { label: 'Rank', field: 'rank' },
      { label: 'Agent Name', field: 'agent_name' },
      { label: 'Team Name', field: 'team' },
      { label: 'Team Logo', field: 'div_logo' },
      { label: 'Milestone Level', field: 'pip_level' },
      { label: 'Milestone Award', field: 'pip_image' },
      { label: 'Points', field: 'points' },
      { label: 'AV', field: 'av' },
      { label: 'Paycheck', field: 'paycheck' },
      { label: 'ACA Lives', field: 'aca_lives' },
      { label: 'MA Lives', field: 'ma_lives' },
      { label: 'Headshot', field: 'headshot' },
    ],
    'subcription-payments-by-product': [
      {
        label: 'Agent Name',
        field: 'customer_name',
        sort_col: 'customer_name',
      },
      {
        label: 'Product Name',
        field: 'product_name',
        sort_col: 'product_name',
      },
      { label: 'Payment Date', field: 'created_at', sort_col: 'created_at' },
      {
        label: 'Total Revenue',
        field: 'total_revenue',
        sort_col: 'total_revenue',
      },
    ],
    'failed-payments': [
      { label: 'Agent Name', field: 'agent_name', sort_col: 'agent_name' },
      { label: 'Agent Email', field: 'agent_email', sort_col: 'agent_email' },
      {
        label: 'Payment Date',
        field: 'payment_date',
        sort_col: 'payment_date',
      },
      { label: 'Amount', field: 'amount', sort_col: 'amount' },
      { label: 'Charge ID', field: 'charge_id', sort_col: 'charge_id' },
      { label: 'Customer ID', field: 'customer_id', sort_col: 'customer_id' },
      {
        label: 'Failure Code',
        field: 'failure_code',
        sort_col: 'failure_code',
      },
      {
        label: 'Failure Message',
        field: 'failure_message',
        sort_col: 'failure_message',
      },
      {
        label: 'Outcome Reason',
        field: 'outcome_reason',
        sort_col: 'outcome_reason',
      },
      {
        label: 'Payment Intent',
        field: 'payment_intent',
        sort_col: 'payment_intent',
      },
      {
        label: 'Description',
        field: 'purchase_description',
        sort_col: 'purchase_description',
      },
    ],
    'refund-payments': [
      {
        label: 'Agent Name',
        field: 'customer_name',
        sort_col: 'customer_name',
      },
      {
        label: 'Amount',
        field: 'formatted_amount',
        sort_col: 'formatted_amount',
      },
      { label: 'Charge ID', field: 'charge_id', sort_col: 'charge_id' },
      { label: 'Refund ID', field: 'refund_id', sort_col: 'refund_id' },
      { label: 'Invoice ID', field: 'invoice_id', sort_col: 'invoice_id' },
      { label: 'Refunded At', field: 'refunded_at', sort_col: 'refunded_at' },
      { label: 'Description', field: 'description', sort_col: 'description' },
    ],
  },
}

export const reportingCategories = [
  {
    label: 'Personal Production',
    key: 'my-production',
    usertypeIds: null,
  },
  {
    label: 'Agent Wallet',
    key: 'my-wallet',
    usertypeIds: null,
  },
  {
    label: 'KPI Report',
    key: 'kpi-report',
    usertypeIds: null,
  },
  {
    label: 'Downline Production',
    key: 'downline-production',
    usertypeIds: [
      'system-admin',
      'recruiter-group',
      'agency-owner',
      'internal-admin',
      'division-admin',
      'region-admin',
      'district-admin',
    ],
  },
  {
    label: 'Division Production',
    key: 'division-production',
    usertypeIds: [
      'system-admin',
      'division-group',
      'agency-owner',
      'internal-admin',
    ],
  },
  {
    label: 'BA Production',
    key: 'ba-production',
    usertypeIds: [
      'system-admin',
      'division-group',
      'agency-owner',
      'internal-admin',
    ],
  },
  {
    label: 'Agency Production',
    key: 'agency-production',
    usertypeIds: [
      'system-admin',
      'internal-admin',
      'agency-owner',
      'internal-admin',
    ],
  },
  {
    label: 'Agency Wallets',
    key: 'agency-wallets',
    usertypeIds: [
      'system-admin',
      'internal-admin',
      'agency-owner',
      'internal-admin',
    ],
  },
  {
    label: 'Totals -- Career',
    key: 'career-totals',
    usertypeIds: [
      'system-admin',
      'internal-admin',
      'internal-staff',
      'agency-owner',
      'divisional-leader',
      'regional-leader',
      'district-leader',
      'division-admin',
      'region-admin',
      'district-admin',
      'career-agent',
    ],
  },
  {
    label: 'Totals -- District',
    key: 'district-totals',
    usertypeIds: [
      'system-admin',
      'internal-admin',
      'internal-staff',
      'agency-owner',
      'divisional-leader',
      'regional-leader',
      'district-leader',
      'division-admin',
      'region-admin',
      'district-admin',
    ],
  },
  {
    label: 'Totals -- Region',
    key: 'region-totals',
    usertypeIds: [
      'system-admin',
      'internal-admin',
      'internal-staff',
      'agency-owner',
      'divisional-leader',
      'regional-leader',
      'division-admin',
      'region-admin',
    ],
  },
  {
    label: 'Totals -- Division',
    key: 'division-totals',
    usertypeIds: [
      'system-admin',
      'internal-admin',
      'internal-staff',
      'agency-owner',
      'divisional-leader',
      'division-admin',
    ],
  },
  {
    label: 'Referrals',
    key: 'referrals',
    usertypeIds: null,
  },
  {
    label: 'Contracting',
    key: 'contracting',
    usertypeIds: [
      'agency-owner',
      'divisional-leader',
      'regional-leader',
      'district-leader',
      'internal-admin',
      'system-admin',
      'division-admin',
      'region-admin',
      'district-admin',
    ],
  },
  {
    label: 'Leaders',
    key: 'leaders',
    usertypeIds: [
      'agency-owner',
      'divisional-leader',
      'regional-leader',
      'district-leader',
      'internal-admin',
      'system-admin',
      'division-admin',
      'region-admin',
      'district-admin',
    ],
  },
  {
    label: 'NSM Report',
    key: 'internal',
    usertypeIds: [
      'system-admin',
      'internal-admin',
      'internal-staff',
      'agency-owner',
    ],
  },
  {
    label: 'Broker Support',
    key: 'broker-support',
    usertypeIds: [
      'system-admin',
      'internal-admin',
      'internal-staff',
      'agency-owner',
    ],
  },
]

export const reportCardFields = {
  totalPoints: 'Total Points',
  enrolledAgents: 'Enrolled Agents',
  sigAgentsCompleted: 'Sig Agents Completed',
  sigAgentsConversion: 'Sig Agents Conversion',
  pendingAgents: 'Pending Agents',
  signatureAgents: 'Signature Agents',
  activeAgents: 'Active Agents',
  writingAgents: 'Writing Agents',
}

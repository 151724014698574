import { Extension } from '@tiptap/core'

const FontSizeExtension = Extension.create({
  name: 'fontSize',

  addGlobalAttributes() {
    return [
      {
        types: ['paragraph', 'heading'], // Apply font size to paragraphs & headings
        attributes: {
          fontSize: {
            default: null,
            renderHTML: (attributes) => {
              if (!attributes.fontSize) return {} // ✅ Fix: Return empty object if null
              return {
                style: `font-size: ${attributes.fontSize}px !important;`,
              }
            },
            parseHTML: (element) => {
              const fontSize = element.style.fontSize?.replace('px', '') || null
              return fontSize ? { fontSize: parseInt(fontSize, 10) } : null // ✅ Fix: Return null properly
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setFontSize:
        (size) =>
        ({ commands, editor }) => {
          const { state } = editor
          const nodeType = state.selection.$anchor.parent.type.name

          if (nodeType === 'paragraph' || nodeType === 'heading') {
            return commands.updateAttributes(nodeType, {
              fontSize: size || null,
            }) // ✅ Fix: Store null when no font size is set
          }
          return false
        },
    }
  },
})

export default FontSizeExtension

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import ContentHtml from './../../content/ContentHtml/ContentHtml.component'
import ElectiveCarrierToggle from './../ElectiveCarrierToggle/ElectiveCarrierToggle.component'
import UserProfileService from './../../../shared/services/UserProfile.service'
import UserService from '../../../shared/services/User.service'
import ACMStore from './../AgentContractManager.store'

import './ElectiveCartList.scss'

const ElectiveCartList = ({
  ElectiveCartItems,
  activeSpecs,
  onSelectCartItem,
  isSelectable,
  minLimit,
  maxLimit,
  requiredItems,
}) => {
  const [uplines, setUplines] = useState([])

  useEffect(() => {
    getUplines()
  }, [])

  const getUplines = async () => {
    const upline_agents = await UserService.getUpline(
      UserProfileService.getUserId()
    )
    setUplines(upline_agents.map((upline) => `${upline.id}`))
  }

  const CartMeta_ExtCarriers = (ACMStore?.CartMetas || [])
      ?.filter(
        (CartMeta) => CartMeta.get('meta_key') === 'cart-items---ext-carriers'
      )
      .shift(),
    getCarrierByCartItem = (CartItem) => {
      const matchingCarrier = (ACMStore?.Carriers || [])
        .filter(
          (Carrier) =>
            parseInt(Carrier.id()) === parseInt(CartItem.get('carrier_id'))
        )
        .shift()

      if (!matchingCarrier) return

      if (parseInt(matchingCarrier.get('c_active')) !== 1) return

      if (parseInt(matchingCarrier.get('c_active')) === 1) {
        if (matchingCarrier.get('visibility')?.onboarding?.enable) {
          const usertype_id = parseInt(
            UserProfileService.getCurrentUserTypeId()
          )
          if (
            [225].includes(usertype_id) &&
            !matchingCarrier.get('visibility')?.onboarding?.csr_agent
          )
            return false
          if (
            [111, 133].includes(usertype_id) &&
            !matchingCarrier.get('visibility')?.onboarding?.affiliate_agent
          )
            return false
          if (
            [223, 224].includes(usertype_id) &&
            !matchingCarrier.get('visibility')?.onboarding?.associate_agent
          )
            return false
          if (
            [
              91, 92, 149, 37, 35, 38, 90, 93, 130, 129, 226, 227, 228, 219, 36,
              222,
            ].includes(usertype_id) &&
            !matchingCarrier.get('visibility')?.onboarding?.sig_agent
          )
            return false
        } else return false

        if (
          (matchingCarrier.get('disabled_uplines') || []).includes(
            UserProfileService.get('u_upline_id')
          )
        )
          return false
      }

      if (
        matchingCarrier.get('visibility')?.onboarding?.upline_ids?.length > 0
      ) {
        const disabled_carrier = matchingCarrier
          .get('visibility')
          .onboarding.upline_ids.some((upline_id) =>
            uplines.includes(upline_id)
          )
        if (disabled_carrier) return false
      }

      return matchingCarrier
    }

  let extCarrierIds = {}
  if (CartMeta_ExtCarriers?.get('meta_value')) {
    try {
      extCarrierIds = JSON.parse(CartMeta_ExtCarriers?.get('meta_value'))
    } catch (ex) {
      extCarrierIds = undefined
    }
  }

  const isExternalCarrier = (carrierId) =>
      extCarrierIds &&
      Array.isArray(extCarrierIds) &&
      carrierId &&
      extCarrierIds.filter((ext) => `${ext?.replacement_id}` === `${carrierId}`)
        .length > 0,
    renderCartItem = (CartItem) => {
      const Carrier = getCarrierByCartItem(CartItem),
        renderIdentity = () => Carrier?.get('c_name'),
        isExternal = isExternalCarrier(CartItem.get('carrier_id'))

      return (
        <>
          {Carrier ? (
            <li
              key={`elective-cart-list-item-carrier-${CartItem.get(
                'carrier_id'
              )}`}
              className={
                (isSelectable ? 'is-selectable-carrier ' : '') +
                (isExternal ? 'is-include-carrier ' : '')
              }
            >
              <div className="cart-item cart-item-profit">
                {Carrier?.renderPriorityIndicator(
                  UserProfileService.get('usertype_id')
                ) || 'X'}
              </div>
              <div className="cart-item cart-item-carrier">
                {renderIdentity()}
              </div>
              <div className="cart-item cart-item-description">
                {Carrier?.get('c_description')}
              </div>
              <div className="cart-item cart-item-include">
                <ElectiveCarrierToggle
                  carrier_id={CartItem.get('carrier_id')}
                  disabled={isExternal}
                  isExternal={isExternal}
                  onToggle={(activate) => {
                    onSelectCartItem({ [CartItem.get('carrier_id')]: activate })
                  }}
                />
              </div>
            </li>
          ) : null}
        </>
      )
    }

  const renderCartItemList = () => {
    return (
      <ul className={'contract-cart-item-list show-include '}>
        <li>
          <div className="cart-item">
            <MDBRow>
              <MDBCol size="12" sm="8">
                USABG Preferred Carriers
                <small>
                  These carriers are highly preferred and would be high quality
                  additions to your product offering.
                </small>
              </MDBCol>
              <MDBCol size="12" sm="4">
                <div
                  className={`required-items ${
                    requiredItems < 1 ? 'is-ready' : ''
                  }`}
                >
                  {requiredItems > 0 ? (
                    <>
                      Select <span>{requiredItems}</span> more contracts.
                    </>
                  ) : (
                    <>
                      Minimum Contracts Selected&nbsp;
                      <MDBIcon icon="check" />
                    </>
                  )}
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        </li>
        <li>
          <div className="cart-item cart-item-profit">Profit:</div>
          <div className="cart-item cart-item-carrier">Carrier:</div>
          <div className="cart-item cart-item-description">Description:</div>
          <div className={'cart-item cart-item-include '}>
            <div className="include-carrier">
              Include
              <br />
              Carrier?
            </div>
          </div>
        </li>
        {(ElectiveCartItems || []).map(renderCartItem)}
      </ul>
    )
  }

  return (
    <MDBContainer id="ElectiveCartListComponent" fluid>
      <MDBRow>
        <MDBCol size="12">
          <ContentHtml contentSlug="elective-cart-list-how-to" />
        </MDBCol>
        <MDBCol size="12">{renderCartItemList()}</MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(ElectiveCartList)

import React, { Component, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { VersionIndicator } from './../../core/versioning'
import AdminNavLinks from './AdminNavLinks.component'
import AgentNavLinks from './AgentNavLinks.component'
import LoadingSpinner from './../shared/LoadingSpinner.component'
import StorageService from './../../shared/services/Storage.service'
import DashboardHeader from './DashboardHeader.component'
import LoadingService from './../../shared/services/Loading.service'
import { Subscription } from 'rxjs'
import { MDBSideNavNav, MDBSideNav, MDBBtn } from 'mdbreact'
import { toast } from 'react-toastify'
import AdminApi from '../../api/admin-api/admin-api'
import AuthService from './../../shared/services/Auth.service'
import UserProfileService from './../../shared/services/UserProfile.service'
import appConstants from './../../constants/appConstants'
import VideoPlayerModal from './../VideoPlayerModal/VideoPlayerModal.component'
import { Container as PromiseModalContainer } from 'react-modal-promise'
import DashboardOverlay from './DashboardOverlay.component'
import UpdatePaymentMethodModal from '../modals/UpdatePaymentMethodModal/UpdatePaymentMethodModal.component'

import './DashboardLayout.scss'

const ENABLE_LOADING_SERVICE = false

const UnassumeUserIdentityButton = ({ adminName, onLogout }) => {
  const [isUnassuming, setIsUnassuming] = useState(false)

  const unassume = async () => {
    setIsUnassuming(true)

    let path, error
    try {
      path = await AuthService.unassumeUser()
    } catch (ex) {
      error = `${ex}`
    }

    setIsUnassuming(false)
    onLogout(path, error)
  }

  const renderButton = () => {
    if (UserProfileService.isAssumed() || isUnassuming)
      return (
        <div className="unassume-user-identity-btn pb-5">
          <MDBBtn
            gradient="aqua"
            title={`Back to ${adminName}`}
            className="text-truncate btn-block"
            disabled={isUnassuming}
            onClick={unassume}
            sm="true"
          >
            {isUnassuming ? (
              <>
                <i className="fa fa-spin fa-spinner"></i>&nbsp;Logging Out...
              </>
            ) : (
              <>Back to {adminName}</>
            )}
          </MDBBtn>
        </div>
      )
    return <></>
  }

  return <>{renderButton()}</>
}

class DashboardLayout extends Component {
  _adminName = ''

  state = {
    showSidebarNav: false,
    isLoadingActive: false,
    userData: {},
    videoUrl: null,
    videoTitle: null,
    location: {},
  }

  __subscriptions$ = new Subscription()

  componentDidMount() {
    if (typeof window !== 'undefined')
      if (!StorageService.get('user_fb')) return this._goTo('/')

    let userDetails = UserProfileService.getUserDetails(true)
    userDetails =
      userDetails &&
      [userDetails?.u_fname, userDetails?.u_lname].join(' ').trim()
    this._adminName = userDetails ? userDetails : 'My Account'

    if (ENABLE_LOADING_SERVICE)
      this.__subscriptions$.add(
        LoadingService.getActive().subscribe((isActive) =>
          this._setLoadingActive(isActive)
        )
      )
    this._fetchUserRoles()
  }

  componentDidUpdate() {
    if (
      this.state.location?.pathname !== this.props.location.pathname ||
      this.state.location?.search !== this.props.location.search
    )
      this.setState((prevState) => ({
        ...prevState,
        location: this.props.location,
      }))
  }

  componentWillUnmount() {
    this.__subscriptions$.unsubscribe()
  }

  _goTo = (location, opt) => {
    if (location && typeof location === 'object' && location?.url)
      location = location?.url

    if (location && typeof location === 'string') {
      if (/^(http:|https:)/.test(location)) {
        window.open(location)
        return
      }

      if (ENABLE_LOADING_SERVICE) {
        LoadingService.setLabel(opt && opt.name)
        LoadingService.on()
      }

      if (
        this.props.location.pathname.includes('/reporting/') &&
        location.includes('/reporting/')
      ) {
        this.props.history.push('/dashboard')
        setTimeout(() => {
          this.props.history.push(location)
        }, 0)
      } else this.props.history.push(location)
    }
  }

  _setLoadingActive = (isLoadingActive) => {
    if (isLoadingActive !== this.state.isLoadingActive)
      this.setState({ isLoadingActive })
  }

  _toggleNav = () =>
    this.setState({ showSidebarNav: !this.state.showSidebarNav })

  _toggleVideoPlayer = (videoUrl, videoTitle) =>
    this.setState({ videoUrl, videoTitle })

  _fetchUserRoles = async () => {
    let userDetails = await UserProfileService.getUserDetailsFromAPI()

    if (
      parseInt(userDetails.u_active) !== 1 &&
      !UserProfileService.isAssumed()
    ) {
      await AuthService.logout()
      toast.success('Logout Successful')
      setTimeout(() => {
        window.location.href = '/login'
      }, 1000)
    }

    AdminApi.fetchUserDetailsAuth()
      .then((result) => {
        let userData =
          result && result.data && result.data.data ? result.data.data : false
        if (userData) {
          userData.usertype_id = parseInt(userData.usertype_id)
          this.setState({ userData })
        }
      })
      .catch((error) => {
        toast.error(error.message, { position: toast.POSITION.TOP_RIGHT })
      })
  }

  _handleLogout = (path, error) => {
    if (path && path === this.props.location.pathname) window.location.reload()
    else if (path) this.props.history.push(path)
    else {
      toast.error(
        "Oh no!  We were unable to you out of the agent's account.  Logging out entirely.",
        { position: toast.POSITION.TOP_RIGHT }
      )
      AuthService.logout()
      this.props.history.push('/login')
    }
  }

  renderCtaBtn = () => {
    if (parseInt(UserProfileService.get('has_onboarded')) !== 1)
      return (
        <li style={{ padding: '15px 5px 5px 5px' }}>
          <MDBBtn
            size="lg"
            block
            color="danger"
            onClick={() => this._goTo('/welcome')}
          >
            COMPLETE MY
            <br />
            AGENT ONBOARDING
          </MDBBtn>
        </li>
      )
    if (UserProfileService.isA(['affiliate-group', 'associate-group']))
      return (
        <li style={{ padding: '15px 5px 5px 5px' }}>
          <MDBBtn
            className="pl-2 pr-2 mx-auto"
            style={{ width: '100%' }}
            color="danger"
            onClick={() => this._goTo('/account-billinginfo')}
            size="lg"
          >
            Upgrade To Signature Agent
          </MDBBtn>
        </li>
      )
    return <></>
  }

  render() {
    toast.configure()

    return (
      <div id="DashboardLayoutComponent" className="indigo-skin">
        <UpdatePaymentMethodModal />

        <VideoPlayerModal
          open={!!this.state.videoTitle}
          onClose={() => this._toggleVideoPlayer()}
          videoUrl={(this.state.videoUrl || '').replace(/(<([^>]+)>)/gi, '')}
          videoTitle={this.state.videoTitle}
        />

        <MDBSideNav
          triggerOpening={this.state.showSidebarNav}
          breakWidth={1299}
          mask="strong"
          fixed
        >
          <li>
            <img
              alt="USABG Welcome"
              src={appConstants.logos.master.sm}
              className="mx-auto d-block img-fluid cursor-pointer"
              onClick={() =>
                this._toggleVideoPlayer(
                  'https://player.vimeo.com/video/600209729?h=46f96bfa6a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
                  'A Word From The Owners'
                )
              }
            />
          </li>
          {this.renderCtaBtn()}

          <li className="agent-nav-links-link">
            <AgentNavLinks goTo={this._goTo} />
          </li>
          <AdminNavLinks goTo={this._goTo} />
          {/*<MDBSideNavNav className='pb-5'>*/}
          <MDBSideNavNav className="unassume-user-link">
            <UnassumeUserIdentityButton
              adminName={this._adminName}
              onLogout={this._handleLogout}
            />
          </MDBSideNavNav>
          <VersionIndicator />
        </MDBSideNav>

        {/*<DashboardHeader goTo={this._goTo} userData={this.state.userData} profileImage={this.state.userData.u_picture && this.state.imageURLs.prefix && this.state.imageURLs.suffix ? this.state.imageURLs.prefix+this.props.userData.u_picture+this.state.imageURLs.suffix : null} />*/}
        <div id="main-content">
          <DashboardHeader
            toggleNav={this._toggleNav}
            goTo={this._goTo}
            userData={this.state.userData}
            urlParams={this.props.match.params}
            location={this.state.location}
          />
          <div
            id="page-content"
            className={this.state.isLoadingActive ? 'is-loading' : ''}
          >
            {ENABLE_LOADING_SERVICE && <LoadingSpinner size="md" />}
            {this.props.children}
            <DashboardOverlay />
          </div>
        </div>

        <PromiseModalContainer />
      </div>
    )
  }
}

export default withRouter(DashboardLayout)
